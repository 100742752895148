<template>
  <div>
    <button class="mt-3 btn btn-success" @click="showAddModal = true">
      افزودن وضعیت جسمانی جدید
    </button>
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>نام وضعیت</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr
          v-for="phisicalType in PhisicalTypes"
          :key="phisicalType.PhisicalTypeId"
        >
          <td>{{ phisicalType.PhisicalTypeName }}</td>
          <td>
            <i
              class="zmdi zmdi-edit text-warning"
              @click="showGetEditModal(phisicalType.PhisicalTypeId)"
            ></i>
            <i
              :class="
                'fa ' +
                (phisicalType.Status == true
                  ? 'fa-toggle-on'
                  : 'fa-toggle-off') +
                ' text-primary m-1'
              "
              @click="
                setStatus(phisicalType.PhisicalTypeId, phisicalType.Status)
              "
            ></i>
          </td>
        </tr>
      </template>
    </data-table>
    <modal
      id="add-phisicaltype-modal"
      title="افزودن وضعیت جسمانی جدید"
      :showModal="showAddModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام وضعیت"
          v-model="PhisicalType.PhisicalTypeName"
        />
        <button
          class="btn btn-success m-1 float-end"
          @click="addPhisicalType()"
        >
          افزودن وضعیت جدید
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showAddModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
    <modal
      id="edit-phisicaltype-modal"
      title="ویرایش وضعیت جسمانی سکونت"
      :showModal="showEditModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام وضعیت"
          v-model="EditPhisicalType.PhisicalTypeName"
        />
        <button
          class="btn btn-warning m-1 float-end"
          @click="editPhisicalType()"
        >
          ویرایش وضعیت
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showEditModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
import Modal from "../../../Modal/Modal.vue";
export default {
  components: { DataTable, Modal },
  data() {
    return {
      showAddModal: false,
      showEditModal: false,
      PhisicalType: {
        PhisicalTypeName: "",
      },
      EditPhisicalType: {
        PhisicalTypeID: 0,
        PhisicalTypeName: "",
      },
    };
  },
  methods: {
    addPhisicalType() {
      this.$http
        .post("dashboard/phisicalType", this.PhisicalType)
        .then(() => {
          this.showAddModal = false;
          this.$store.dispatch("GetPhisicalTypesResponse");
          this.PhisicalType.PhisicalTypeName = "";
        })
        .catch((err) => console.log(err));
    },
    showGetEditModal(id) {
      this.showEditModal = true;
      this.EditPhisicalType.PhisicalTypeID = id;
      this.$http
        .get("dashboard/phisicalType/" + this.EditPhisicalType.PhisicalTypeID)
        .then((response) => {
          this.EditPhisicalType.PhisicalTypeName =
            response.data.PhisicalTypeName;
        })
        .catch((err) => console.log(err));
    },
    editPhisicalType() {
      this.$http
        .put("dashboard/phisicalType", {
          PhisicalTypeId: this.EditPhisicalType.PhisicalTypeID,
          PhisicalTypeName: this.EditPhisicalType.PhisicalTypeName,
        })
        .then(() => {
          this.showEditModal = false;
          this.$store.dispatch("GetPhisicalTypesResponse");
          this.EditPhisicalType.PhisicalTypeName = "";
        })
        .catch((err) => console.log(err));
    },
    setStatus(id, status) {
      let message = "";
      if (status == true) {
        message = "آیا از غیرفعالسازی این وضعیت مطمئن هستید؟";
      } else {
        message = "آیا از فعالسازی این وضعیت مطمئن هستید؟";
      }
      if (confirm(message)) {
        this.$http
          .delete("dashboard/phisicalType/" + id)
          .then(() => {
            this.$store.dispatch("GetPhisicalTypesResponse");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    PhisicalTypes() {
      return this.$store.getters.getAllPhisicalTypes.phisicalTypes;
    },
  },
  created() {
    this.$store.dispatch("GetPhisicalTypesResponse");
    this.$store.dispatch("AuthPhisicalTypeDef", true);
    this.$store.dispatch("OpenCloseSetMenu");
  },
  updated() {
    this.$store.dispatch("AuthPhisicalTypeDef", true);
  },
};
</script>
