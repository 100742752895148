import Vue from "vue";
import { io } from "socket.io-client";
import { v4 } from "uuid";

// import qs from "qs";

import { router } from "../../router/vueRouter";

const state = {
  FullName: "",
  IsAuthenticated: false,
  token: "",
  profileAccount: {},
  changeInfoData: {
    UserName: "",
    FullName: "",
    Email: "",
    Phone: "",
  },
  loginRegisterNumber: 0,
  aboutUs: "",
  contactUs: "",
};

const getters = {
  GetFullName(state) {
    return state.FullName;
  },
  GetIsAuthenticated(state) {
    return state.IsAuthenticated;
  },
  GetToken(state) {
    return state.token;
  },
  GetProfile(state) {
    return state.profileAccount;
  },
  GetChangeInfoData(state) {
    return state.changeInfoData;
  },
  GetLoginRegisterNumber(state) {
    return state.loginRegisterNumber;
  },
  AboutUs(state) {
    return state.aboutUs;
  },
  ContactUs(state) {
    return state.contactUs;
  },
  Socket() {
    // return io("http://localhost:3000");
    return io("https://api.labkhandmahdavi.ir/");
  },
};

const mutations = {
  SetFullName(state, FullName) {
    state.FullName = FullName;
  },
  SetIsAuthenticated(state, IsAuth) {
    state.IsAuthenticated = IsAuth;
  },
  SetToken(state, token) {
    state.token = token;
  },
  SetProfile(state, profileAccount) {
    state.profileAccount = profileAccount;
  },
  SetChangeInfoData(state, changeInfoData) {
    state.changeInfoData = changeInfoData;
  },
  setLoginRegisterNumber(state, loginRegisterNumber) {
    state.loginRegisterNumber = loginRegisterNumber;
  },
  setAboutUs(state, aboutUs) {
    state.aboutUs = aboutUs;
  },
  setContactUs(state, contactUs) {
    state.contactUs = contactUs;
  },
};

const actions = {
  SetFullName(context) {
    Vue.http
      .get("setAuth")
      .then((response) => {
        context.commit("SetIsAuthenticated", response.body.isAuth);
        context.commit("SetFullName", response.body.FullName);
        context.commit(
          "SetToken",
          Vue.cookie.get("LabkhandAuthenticationToken")
        );
      })
      .catch(() => {
        context.commit("SetIsAuthenticated", false);
        context.commit("SetFullName", "");
        context.commit("SetToken", "");
      });
  },
  Login(context, loginData) {
    Vue.http
      .post("users/login", loginData)
      .then((response) => {
        if (response.body.token != "") {
          context.commit("SetToken", response.body.token);
          Vue.cookie.set("LabkhandAuthenticationToken", response.body.token, 1);
          if (response.body.UserGroupId == 1) {
            router.push("/admin");
          } else {
            router.push("/");
          }
        }
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  Register(context, registerData) {
    Vue.http
      .post("users/register", registerData)
      .then(() => {
        router.push("/login");
        alert("ثبت نام با موفقیت انجام شد!");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  LogOut(context) {
    if (getters.GetToken != "") {
      Vue.cookie.delete("LabkhandAuthenticationToken");
    }
    context.commit("SetToken", "");
    context.commit("SetIsAuthenticated", false);
    context.commit("SetFullName", "");
  },
  GetProfile(context) {
    Vue.http
      .get("users/profile")
      .then((response) => {
        context.commit("SetProfile", response.body);
      })
      .catch((err) => {
        if (err.status == 500) {
          //JWT Malformed
        }
      });
  },
  GetChangeInfoData(context) {
    Vue.http
      .get("users/changeInfo")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("SetChangeInfoData", data);
      });
  },
  ChangeInfo(context, changeInfoData) {
    Vue.http.put("users/changeInfo", changeInfoData).then((response) => {
      if (response.status == 200) {
        if (response.body.UserGroupId == 0) {
          router.push("/profile");
          context.dispatch("GetProfile");
        } else {
          router.push("/admin");
        }
        context.dispatch("SetFullName");
      }
    });
  },
  ChangePassword(context, changePasswordData) {
    Vue.http
      .put("users/changePassword", changePasswordData)
      .then(() => {
        context.dispatch("LogOut");
        context.dispatch("goToLoginRegister", 0);
      })
      .catch((err) => {
        if (err.status == 422) {
          alert("رمز عبور فعلی صحیح نمی باشد...");
        }
        if (err.status == 421) {
          alert("رمز عبور با تکرار رمز عبور متفاوت است...");
        }
      });
  },
  ChangeProfile(context, imageForm) {
    Vue.http
      .post("users/changeProfile", imageForm)
      .then(() => {
        context.dispatch("GetProfile");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  goToLoginRegister(context, loginRegisterNum) {
    router.push("/login-register");
    context.commit("setLoginRegisterNumber", loginRegisterNum);
  },
  AboutUs(context) {
    Vue.http
      .get("users/aboutUs")
      .then((response) => {
        context.commit("setAboutUs", response.body.aboutUs);
      })
      .catch((err) => {
        if (err.status == 500) {
          //Error Code: 500
        }
      });
  },
  ContactUs(context) {
    Vue.http
      .get("users/contactUs")
      .then((response) => {
        context.commit("setContactUs", response.body.contactUs);
      })
      .catch((err) => {
        if (err.status == 500) {
          //Error Code: 500
        }
      });
  },
  AddVisitSite(context) {
    const id = v4();
    context.getters.Socket.emit("newUser", id);
    context.getters.Socket.on("onlineUsers", (data) => {
      context.commit("setOnlineUsers", data);
    });
    Vue.http
      .get("users/addVisit")
      .then(() => {})
      .catch((err) => {
        if (err.status == 500) {
          //Error Code: 500
          console.log(err.body.message);
        }
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
