<template>
  <div>
    <button class="mt-3 btn btn-success" @click="showAddModal = true">
      افزودن وضعیت بیماری جدید
    </button>
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>نام وضعیت</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr v-for="sickType in SickTypes" :key="sickType.SickTypeId">
          <td>{{ sickType.SickTypeName }}</td>
          <td>
            <i
              class="zmdi zmdi-edit text-warning m-1"
              @click="showGetEditModal(sickType.SickTypeId)"
            ></i>
            <i
              :class="
                'fa ' +
                (sickType.Status == true ? 'fa-toggle-on' : 'fa-toggle-off') +
                ' text-primary m-1'
              "
              @click="setStatus(sickType.SickTypeId, sickType.Status)"
            ></i>
          </td></tr
      ></template>
    </data-table>
    <modal
      id="add-sicktype-modal"
      title="افزودن وضعیت بیماری جدید"
      :showModal="showAddModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام وضعیت"
          v-model="SickType.SickTypeName"
        />
        <button class="btn btn-success m-1 float-end" @click="addSickType()">
          افزودن وضعیت جدید
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showAddModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
    <modal
      id="edit-sicktype-modal"
      title="ویرایش وضعیت بیماری سکونت"
      :showModal="showEditModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام وضعیت"
          v-model="EditSickType.SickTypeName"
        />
        <button class="btn btn-warning m-1 float-end" @click="editSickType()">
          ویرایش وضعیت
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showEditModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
import Modal from "../../../Modal/Modal.vue";
export default {
  components: { DataTable, Modal },
  data() {
    return {
      showAddModal: false,
      showEditModal: false,
      SickType: {
        SickTypeName: "",
      },
      EditSickType: {
        SickTypeID: 0,
        SickTypeName: "",
      },
    };
  },
  methods: {
    addSickType() {
      this.$http
        .post("dashboard/sickType", this.SickType)
        .then(() => {
          this.showAddModal = false;
          this.$store.dispatch("GetSickTypesResponse");
          this.SickType.SickTypeName = "";
        })
        .catch((err) => console.log(err));
    },
    showGetEditModal(id) {
      this.showEditModal = true;
      this.EditSickType.SickTypeID = id;
      this.$http
        .get("dashboard/sickType/" + this.EditSickType.SickTypeID)
        .then((response) => {
          this.EditSickType.SickTypeName = response.data.SickTypeName;
        })
        .catch((err) => console.log(err));
    },
    editSickType() {
      this.$http
        .put("dashboard/sickType", {
          SickTypeId: this.EditSickType.SickTypeID,
          SickTypeName: this.EditSickType.SickTypeName,
        })
        .then(() => {
          this.showEditModal = false;
          this.$store.dispatch("GetSickTypesResponse");
          this.EditSickType.SickTypeName = "";
        })
        .catch((err) => console.log(err));
    },
    setStatus(id, status) {
      let message = "";
      if (status == true) {
        message = "آیا از غیرفعالسازی این وضعیت مطمئن هستید؟";
      } else {
        message = "آیا از فعالسازی این وضعیت مطمئن هستید؟";
      }
      if (confirm(message)) {
        this.$http
          .delete("dashboard/sickType/" + id)
          .then(() => {
            this.$store.dispatch("GetSickTypesResponse");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    SickTypes() {
      return this.$store.getters.getAllSickTypes.sickTypes;
    },
  },
  created() {
    this.$store.dispatch("GetSickTypesResponse");
    this.$store.dispatch("AuthSickTypeDef", true);
    this.$store.dispatch("OpenCloseSetMenu");
  },
  updated() {
    this.$store.dispatch("AuthSickTypeDef", true);
  },
};
</script>
