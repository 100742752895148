<template>
  <div>
    <router-link to="/admin/addaid" class="mt-3 btn btn-success">
      افزودن بسته جدید
    </router-link>
    <input
      type="search"
      class="form-control mb-3 mt-1"
      placeholder="جست و جو..."
      v-model="aidSearchText"
    />
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>عنوان بسته ی حمایتی</th>
        <th>مبلغ</th>
        <th>تاریخ ثبت</th>
        <th>وضعیت</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr v-for="aid in Aids" :key="aid.AidID">
          <td>{{ aid.AidName }}</td>
          <td>{{ aid.CostMoney }}</td>
          <td>{{ aid.createdAt | moment("jYYYY/jMM/jDD") }}</td>
          <td>{{ aid.Status == true ? "فعال" : "غیر فعال" }}</td>
          <td>
            <router-link :to="'/admin/editaid/' + aid.AidID" class="m-1">
              <i class="zmdi zmdi-edit text-warning"></i>
            </router-link>
            <i
              :class="
                'fa ' +
                (aid.Status == true ? 'fa-toggle-on' : 'fa-toggle-off') +
                ' text-primary m-1'
              "
              @click="setStatus(aid.AidID, aid.Status)"
            ></i>
            <router-link
              :to="'/admin/aiddetail/' + aid.AidID"
              class="m-1"
              v-if="!$store.getters.GetAidDetailAuth"
            >
              <i class="zmdi zmdi-washing-machine text-purple"></i>
            </router-link>
          </td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
export default {
  data() {
    return {
      aidSearchText: "",
    };
  },
  components: {
    DataTable,
  },
  methods: {
    setStatus(id, status) {
      this.$store.dispatch("setStatus", {
        id,
        status,
      });
    },
  },
  computed: {
    Aids() {
      if (this.aidSearchText) {
        return this.$store.getters.GetAllAids.aids.filter((aid) => {
          return this.aidSearchText
            .toLowerCase()
            .split(" ")
            .every((v) => aid.AidName.toLowerCase().includes(v));
        });
      } else {
        return this.$store.getters.GetAllAids.aids;
      }
    },
  },
  created() {
    this.$store.dispatch("GetAllAidsResponse");
    this.$store.dispatch("AuthAid", true);
    this.$store.dispatch("AuthAidDetail", false);
    this.$store.dispatch("OpenCloseAidMenu");
  },
  updated() {
    this.$store.dispatch("AuthAid", true);
    this.$store.dispatch("AuthAidDetail", false);
  },
};
</script>
