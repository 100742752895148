<template>
  <div>
    <h3 class="mt-3">افزودن نیازمند جدید</h3>
    <input
      type="text"
      class="form-control mb-3"
      placeholder="نام"
      v-model="Name"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="نام خانوادگی"
      v-model="Family"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="کد ملی"
      v-model="NCode"
    />
    <select
      class="form-select mb-3"
      v-model="AddressTypeSelected"
      @change="AddressTypeID = AddressTypeSelected"
    >
      <option value="" selected>لطفا وضعیت سکونت را انتخاب کنید...</option>
      <option
        v-for="addressType in AddressTypes.filter(
          (addressType) => addressType.Status == true
        )"
        :key="addressType.AddressTypeId"
        :value="addressType.AddressTypeId"
      >
        {{ addressType.AddressTypeName }}
      </option>
    </select>

    <select
      class="form-select mb-3"
      v-model="MaritalTypeSelected"
      @change="MaritalTypeID = MaritalTypeSelected"
    >
      <option value="" selected>لطفا وضعیت تأهل را انتخاب کنید...</option>
      <option
        v-for="maritalType in MaritalTypes.filter(
          (maritalType) => maritalType.Status == true
        )"
        :key="maritalType.MaritalTypeId"
        :value="maritalType.MaritalTypeId"
      >
        {{ maritalType.MaritalTypeName }}
      </option>
    </select>
    <select
      class="form-select mb-3"
      v-model="PhisicalTypeSelected"
      @change="PhisicalTypeID = PhisicalTypeSelected"
    >
      <option value="" selected>لطفا وضعیت جسمانی را انتخاب کنید...</option>
      <option
        v-for="phisicalType in PhisicalTypes.filter(
          (phisicalType) => phisicalType.Status == true
        )"
        :key="phisicalType.PhisicalTypeId"
        :value="phisicalType.PhisicalTypeId"
      >
        {{ phisicalType.PhisicalTypeName }}
      </option>
    </select>
    <select
      class="form-select mb-3"
      v-model="SickTypeSelected"
      @change="SickTypeID = SickTypeSelected"
    >
      <option value="" selected>لطفا وضعیت بیماری را انتخاب کنید...</option>
      <option
        v-for="sickType in SickTypes.filter(
          (sicktype) => sicktype.Status == true
        )"
        :key="sickType.SickTypeId"
        :value="sickType.SickTypeId"
      >
        {{ sickType.SickTypeName }}
      </option>
    </select>
    <select
      class="form-select mb-3"
      v-model="WeakTypeSelected"
      @change="WeakTypeID = WeakTypeSelected"
    >
      <option value="" selected>لطفا وضعیت معلولیت را انتخاب کنید...</option>
      <option
        v-for="weakType in WeakTypes.filter(
          (weakType) => weakType.Status == true
        )"
        :key="weakType.WeakTypeId"
        :value="weakType.WeakTypeId"
      >
        {{ weakType.WeakTypeName }}
      </option>
    </select>

    <input
      type="text"
      class="form-control mb-3"
      placeholder="شماره تلفن"
      v-model="Tel"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="شماره موبایل"
      v-model="Mobile"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="آدرس"
      v-model="Address"
    />

    <button class="btn btn-success m-1 float-end" @click="AddNeedy()">
      افزودن نیازمند جدید
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      AddressTypeSelected: "",
      MaritalTypeSelected: "",
      PhisicalTypeSelected: "",
      SickTypeSelected: "",
      WeakTypeSelected: "",
      Name: "",
      Family: "",
      NCode: "",
      AddressTypeID: 0,
      MaritalTypeID: 0,
      PhisicalTypeID: 0,
      SickTypeID: 0,
      WeakTypeID: 0,
      Tel: "",
      Mobile: "",
      Address: "",
      AddressType: {
        AddressTypeName: "",
      },
      MaritalType: {
        MaritalName: "",
      },
      PhisicalType: {
        PhisicalTypeName: "",
      },
      SickType: {
        SickTypeName: "",
      },
      WeakType: {
        WeakTypeName: "",
      },
    };
  },
  methods: {
    addAddressType() {
      this.$http
        .post("dashboard/addressType", this.AddressType)
        .then(() => {
          this.$refs["add-addresstype-modal"].hide();
          this.$store.dispatch("GetAddressTypesResponse");
          this.AddressType.AddressTypeName = "";
        })
        .catch((err) => console.log(err));
    },
    addMaritalType() {
      this.$http
        .post("dashboard/maritalType", this.MaritalType)
        .then(() => {
          this.$refs["add-maritaltype-modal"].hide();
          this.$store.dispatch("GetMaritalTypesResponse");
          this.MaritalType.MaritalName = "";
        })
        .catch((err) => console.log(err));
    },
    addPhisicalType() {
      this.$http
        .post("dashboard/phisicalType", this.PhisicalType)
        .then(() => {
          this.$refs["add-phisicaltype-modal"].hide();
          this.$store.dispatch("GetPhisicalTypesResponse");
          this.PhisicalType.PhisicalTypeName = "";
        })
        .catch((err) => console.log(err));
    },
    addSickType() {
      this.$http
        .post("dashboard/sickType", this.SickType)
        .then(() => {
          this.$refs["add-sicktype-modal"].hide();
          this.$store.dispatch("GetSickTypesResponse");
          this.SickType.SickTypeName = "";
        })
        .catch((err) => console.log(err));
    },
    addWeakType() {
      this.$http
        .post("dashboard/weakType", this.WeakType)
        .then(() => {
          this.$refs["add-weaktype-modal"].hide();
          this.$store.dispatch("GetWeakTypesResponse");
          this.WeakType.WeakTypeName = "";
        })
        .catch((err) => console.log(err));
    },
    AddNeedy() {
      const needyData = {
        Name: this.Name,
        Family: this.Family,
        NCode: this.NCode,
        AddressTypeId: this.AddressTypeID,
        MaritalTypeId: this.MaritalTypeID,
        PhisicalTypeId: this.PhisicalTypeID,
        SickTypeId: this.SickTypeID,
        WeakTypeId: this.WeakTypeID,
        Tel: this.Tel,
        Mobile: this.Mobile,
        Address: this.Address,
      };
      this.$store.dispatch("AddNeedy", needyData);
    },
    cancel() {
      this.$router.push("/admin/needy");
    },
  },
  computed: {
    AddressTypes() {
      return this.$store.getters.getAllAddressTypes.addressTypes;
    },
    MaritalTypes() {
      return this.$store.getters.getAllMaritalTypes.maritalTypes;
    },
    PhisicalTypes() {
      return this.$store.getters.getAllPhisicalTypes.phisicalTypes;
    },
    SickTypes() {
      return this.$store.getters.getAllSickTypes.sickTypes;
    },
    WeakTypes() {
      return this.$store.getters.getAllWeakTypes.weakTypes;
    },
    Profile() {
      return this.$store.getters.GetProfile;
    },
  },
  created() {
    this.$store.dispatch("GetAddressTypesResponse");
    this.$store.dispatch("GetMaritalTypesResponse");
    this.$store.dispatch("GetPhisicalTypesResponse");
    this.$store.dispatch("GetSickTypesResponse");
    this.$store.dispatch("GetWeakTypesResponse");
    this.$store.dispatch("GetProfile");
    this.$store.dispatch("AuthNeedy", true);
  },
  updated() {
    this.$store.dispatch("GetProfile");
    this.$store.dispatch("AuthNeedy", true);
  },
};
</script>
