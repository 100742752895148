<template>
  <div>
    <h3 class="mt-3">ویرایش کالای حمایتی</h3>
    <input
      type="text"
      class="form-control mb-3"
      placeholder="عنوان کالا"
      v-model="ObjectName"
    />
    <select
      class="form-select mb-3"
      v-model="UnitTypeSelected"
      @change="UnitTypeID = UnitTypeSelected"
    >
      <option :value="!isUnitType ? UnitTypeSelected : '0'">
        لطفا وضعیت معلولیت را انتخاب کنید...
      </option>
      <option
        v-for="unitType in UnitTypes"
        :key="unitType.UnitTypeId"
        :value="unitType.UnitTypeId"
      >
        {{ unitType.UnitTypeName }}
      </option>
    </select>
    <input
      type="number"
      class="form-control mb-3"
      placeholder="مبلغ"
      v-model="CostMoney"
    />
    <button class="btn btn-warning m-1 float-end" @click="EditObject()">
      ویرایش کالا
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    EditObject() {
      const ObjectData = {
        ObjectId: this.$route.params.id,
        ObjectName: this.ObjectName,
        UnitTypeId: this.UnitTypeID,
        CostMoney: this.CostMoney,
      };
      this.$store.dispatch("EditObject", ObjectData);
    },
    cancel() {
      this.$router.push("/admin/object");
    },
  },
  computed: {
    UnitTypes() {
      return this.$store.getters.getAllUnitTypes.unitTypes.filter(
        (unittype) => unittype.Status == true
      );
    },
    isUnitType() {
      return this.$store.getters.getAllUnitTypes.unitTypes.find(
        (unittype) => unittype.UnitTypeId == this.UnitTypeSelected
      );
    },
    Object() {
      return this.$store.getters.GetObject;
    },
    ObjectName: {
      get() {
        return this.Object.ObjectName;
      },
      set(value) {
        this.Object.ObjectName = value;
      },
    },
    UnitTypeID: {
      get() {
        return this.Object.UnitTypeId;
      },
      set(value) {
        this.Object.UnitTypeId = value;
      },
    },
    UnitTypeSelected: {
      get() {
        return this.Object.UnitTypeId;
      },
      set(value) {
        this.Object.UnitTypeId = value;
      },
    },
    CostMoney: {
      get() {
        return this.Object.CostMoney;
      },
      set(value) {
        this.Object.CostMoney = value;
      },
    },
  },
  created() {
    this.$store.dispatch("GetUnitTypesResponse");
    this.$store.dispatch("GetObjectById", this.$route.params.id);
    this.$store.dispatch("AuthObject", true);
  },
  updated() {
    this.$store.dispatch("AuthObject", true);
  },
};
</script>
