import Vue from "vue";

const state = {
  sickTypes: [],
};

const getters = {
  getAllSickTypes(state) {
    return state.sickTypes;
  },
};

const mutations = {
  setSickTypes(state, sickTypes) {
    state.sickTypes = sickTypes;
  },
};

const actions = {
  GetSickTypesResponse(context) {
    Vue.http
      .get("dashboard/sickType")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setSickTypes", data);
      })
      .catch((err) => console.log(err));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
