<template>
  <div>
    <h3 class="mt-3">ویرایش گروه</h3>
    <input type="text" class="form-control" v-model="UserGroup.UserGroupName" />
    <button class="btn btn-warning m-1 float-end" @click="editUserGroup()">
      ویرایش گروه
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      CheckedRoles: [],
    };
  },
  computed: {
    Roles() {
      return this.$store.getters.getAllRoles;
    },
    UserGroup() {
      return this.$store.getters.GetGroup;
    },
    UserGroupName() {
      return this.UserGroup.UserGroupName;
    },
  },
  methods: {
    editUserGroup() {
      const editUserGroupData = {
        UserGroupId: this.UserGroup.UserGroupId,
        UserGroupName: this.UserGroupName,
      };
      this.$store.dispatch("EditUserGroup", editUserGroupData);
    },
    cancel() {
      this.$router.push("/admin/usergroup");
    },
  },
  created() {
    this.$store.dispatch("GetUserGroupById", this.$route.params.id);
    this.$store.dispatch("AuthUserDef", true);
  },
  updated() {
    this.$store.dispatch("AuthUserDef", true);
  },
};
</script>
