<template>
  <div>
    <h3 class="mt-3">ویرایش جزء</h3>
    <select
      class="form-select mb-3"
      v-model="ObjectSelected"
      @change="ObjectID = ObjectSelected"
    >
      <option v-for="obj in Objects" :key="obj.ObjectID" :value="obj.ObjectID">
        {{ obj.ObjectName }}
      </option>
    </select>
    <input
      type="number"
      class="form-control mb-3"
      placeholder="تعداد"
      v-model="Num"
    />
    <button class="btn btn-warning m-1 float-end" @click="EditAidDetails()">
      ویرایش جزء
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    EditAidDetails() {
      const aidDetailData = {
        AidDetailID: this.$route.params.id,
        AidId: this.AidDetail.AidID,
        ObjectID: this.ObjectID,
        Num: this.Num,
      };
      this.$store.dispatch("EditAidDetail", aidDetailData);
    },
    cancel() {
      this.$router.push("/admin/aiddetail/" + this.AidDetail.AidID);
    },
  },
  computed: {
    Objects() {
      return this.$store.getters.getAllObjects.objects;
    },
    AidDetail() {
      return this.$store.getters.GetAidDetail;
    },
    ObjectID: {
      get() {
        return this.AidDetail.ObjectID;
      },
      set(value) {
        this.AidDetail.ObjectID = value;
      },
    },
    ObjectSelected: {
      get() {
        return this.AidDetail.ObjectID;
      },
      set(value) {
        this.AidDetail.ObjectID = value;
      },
    },
    Num: {
      get() {
        return this.AidDetail.Num;
      },
      set(value) {
        this.AidDetail.Num = value;
      },
    },
  },
  created() {
    this.$store.dispatch("GetAidDetailById", this.$route.params.id);
    this.$store.dispatch("GetObjectsResponse");
    this.$store.dispatch("AuthAidDetail", true);
  },
  updated() {
    this.$store.dispatch("AuthAidDetail", true);
  },
};
</script>
