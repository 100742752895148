<template>
  <div>
    <div class="card-box" id="about-us-card">
      <h3>درباره ما</h3>
      <ckeditor
        class="form-control"
        :editor="editor"
        v-model="AboutUs"
        :config="editorConfig"
        tag-name="textarea"
      ></ckeditor>
      <button class="btn btn-warning w-100 mt-2 mx-1" @click="editAboutUs()">
        ویرایش
      </button>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: "fa",
      },
    };
  },
  computed: {
    AboutUs: {
      get() {
        if (this.$store.getters.GetAboutUsAuth) {
          return "";
        } else {
          return this.$store.getters.AboutUs;
        }
      },
      set(value) {
        this.$store.commit("setAboutUs", value);
      },
    },
  },
  methods: {
    editAboutUs() {
      this.$store.dispatch("EditAboutUs", this.AboutUs);
    },
  },
  created() {
    this.$store.dispatch("AboutUs");
    this.$store.dispatch("OpenCloseManagePagesMenu");
    this.$store.dispatch("AuthAboutUs", true);
  },
  updated() {
    this.$store.dispatch("AuthAboutUs", true);
  },
};
</script>

<style>
#about-us-card .ck-editor__editable {
  min-height: 85vh;
  font-size: 1.5rem;
}
</style>
