<template>
  <div class="login-register-page contaienr">
    <div class="card-box">
      <div class="row">
        <div class="login-register-form col-12 col-sm-6">
          <b-tabs class="login-register-tabs" card>
            <b-tab title="ورود" :active="LoginRegister == 0">
              <login></login>
            </b-tab>
            <b-tab title="ثبت نام" :active="LoginRegister == 1">
              <register></register>
            </b-tab>
          </b-tabs>
        </div>
        <div class="vector-image col-12 col-sm-6">
          <img src="./loginAndRegister.svg" alt="" />
        </div>
      </div>
      <router-link to="/" class="home-link">صفحه اصلی</router-link>
    </div>
  </div>
</template>

<script>
import login from "../login/Login.vue";
import register from "../register/Register.vue";
export default {
  components: {
    login,
    register,
  },
  computed: {
    LoginRegister() {
      return this.$store.getters.GetLoginRegisterNumber;
    },
  },
};
</script>

<style scoped>
@import url("loginAndRegister.css");
</style>
