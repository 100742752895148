import Vue from "vue";

const state = {
  phisicalTypes: [],
};

const getters = {
  getAllPhisicalTypes(state) {
    return state.phisicalTypes;
  },
};

const mutations = {
  setPhisicalTypes(state, phisicalTypes) {
    state.phisicalTypes = phisicalTypes;
  },
};

const actions = {
  GetPhisicalTypesResponse(context) {
    Vue.http
      .get("dashboard/phisicalType")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setPhisicalTypes", data);
      })
      .catch((err) => console.log(err));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
