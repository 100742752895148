<template>
  <div>
    <h3 class="mt-3">افزودن کالای حمایتی جدید</h3>
    <input
      type="text"
      class="form-control mb-3"
      placeholder="عنوان کالا"
      v-model="ObjectName"
    />
    <select
      class="form-select mb-3"
      v-model="UnitTypeSelected"
      @change="UnitTypeId = UnitTypeSelected"
    >
      <option value="" selected>لطفا واحد اندازه گیری را انتخاب کنید...</option>
      <option
        v-for="unitType in UnitTypes"
        :key="unitType.UnitTypeId"
        :value="unitType.UnitTypeId"
      >
        {{ unitType.UnitTypeName }}
      </option>
    </select>
    <input
      type="number"
      class="form-control mb-3"
      placeholder="مبلغ"
      v-model="CostMoney"
    />
    <button class="btn btn-success m-1 float-end" @click="AddObject()">
      افزودن کالا جدید
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
    <b-modal
      id="add-unittype-modal"
      title="افزودن واحد جدید"
      ref="add-unittype-modal"
      hide-footer
      hide-header-close
    >
      <input
        type="text"
        class="form-control"
        placeholder="نام واحد"
        v-model="UnitType.UnitTypeName"
      />
      <button class="btn btn-success m-1 float-end" @click="AddUnitType()">
        افزودن واحد جدید
      </button>
      <button class="btn btn-secondary m-1 float-end" @click="cancelModal()">
        انصراف
      </button>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      UnitTypeSelected: "",
      ObjectName: "",
      UnitTypeId: 0,
      CostMoney: "",
      UnitType: {
        UnitTypeName: "",
      },
    };
  },
  methods: {
    AddObject() {
      const ObjectData = {
        ObjectName: this.ObjectName,
        UnitTypeId: this.UnitTypeId,
        CostMoney: this.CostMoney,
      };
      this.$store.dispatch("AddObject", ObjectData);
    },
    AddUnitType() {
      this.$http
        .post("UnitTypeAdmin", this.UnitType)
        .then((response) => {
          if (response.data == "INVALID_UNIT_TYPE") {
            console.log("INVALID UNIT TYPE");
          } else {
            this.$store.dispatch("GetUnitTypesResponse");
          }
        })
        .catch((err) => console.log(err));
    },
    cancel() {
      this.$router.push("/admin/object");
    },
  },
  computed: {
    UnitTypes() {
      return this.$store.getters.getAllUnitTypes.unitTypes;
    },
    Profile() {
      return this.$store.getters.GetProfile;
    },
  },
  created() {
    this.$store.dispatch("GetUnitTypesResponse");
    this.$store.dispatch("GetProfile");
    this.$store.dispatch("AuthObject", true);
  },
  updated() {
    this.$store.dispatch("AuthObject", true);
  },
};
</script>
