<template>
  <div>
    <h3 class="mt-3">افزودن جزء جدید</h3>
    <select
      class="form-select mb-3"
      v-model="ObjectSelected"
      @change="ObjectID = ObjectSelected"
    >
      <option value="" selected>لطفا کالای حمایتی را انتخاب کنید...</option>
      <option v-for="obj in Objects" :key="obj.ObjectID" :value="obj.ObjectID">
        {{ obj.ObjectName }}
      </option>
    </select>

    <input
      type="number"
      class="form-control mb-3"
      placeholder="تعداد"
      v-model="Num"
    />
    <button class="btn btn-success m-1 float-end" @click="AddAidDetail()">
      افزودن جزء جدید
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ObjectSelected: "",
      ObjectID: 0,
      Num: "",
    };
  },
  methods: {
    AddAidDetail() {
      const detailData = {
        data: {
          AidID: this.$route.params.id,
          ObjectID: this.ObjectID,
          Num: this.Num,
        },
        aid: this.$route.params.id,
      };
      this.$store.dispatch("AddAidDetail", detailData);
    },
    cancel() {
      this.$router.push("/admin/aiddetail/" + this.$route.params.id);
    },
  },
  computed: {
    Objects() {
      return this.$store.getters.getAllObjects.objects;
    },
  },
  created() {
    this.$store.dispatch("GetObjectsResponse");
    this.$store.dispatch("AuthAidDetail", true);
  },
  updated() {
    this.$store.dispatch("AuthAidDetail", true);
  },
};
</script>
