<template>
  <div>
    <router-link to="/admin/addneedy" class="btn btn-success mt-4 mb-2"
      >افزودن نیازمند جدید</router-link
    >
    <input
      type="search"
      class="form-control mb-3"
      placeholder="جست و جو..."
      v-model="needySearchText"
    />
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>نام</th>
        <th>نام خانوادگی</th>
        <th>کد ملی</th>
        <th>شماره موبایل</th>
        <th>تاریخ ثبت</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr v-for="needy in Needies" :key="needy.NeedyID">
          <td>{{ needy.Name }}</td>
          <td>{{ needy.Family }}</td>
          <td>{{ needy.NCode }}</td>
          <td>{{ needy.Mobile }}</td>
          <td>{{ needy.createdAt | moment("jYYYY/jMM/jDD") }}</td>
          <td>
            <router-link :to="'/admin/editneedy/' + needy.NeedyID">
              <i class="zmdi zmdi-edit m-1 text-warning"></i>
            </router-link>
            <router-link
              :to="'/admin/needychild/' + needy.NeedyID"
              v-if="!$store.getters.GetNeedyChildAuth"
            >
              <i class="zmdi zmdi-male-female m-1 text-primary"></i>
            </router-link>
            <router-link
              :to="'/admin/helpRecord/' + needy.NeedyID"
              v-if="!$store.getters.GetHelpRecordAuth"
            >
              <i class="zmdi zmdi-card-giftcard m-1 text-pink"></i>
            </router-link>
          </td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
export default {
  components: { DataTable },
  data() {
    return {
      UID: 0,
      needySearchText: "",
    };
  },
  computed: {
    Needies() {
      if (this.needySearchText) {
        return this.$store.getters.GetAllNeedies.needies.filter((needy) => {
          return (
            this.needySearchText
              .toLowerCase()
              .split(" ")
              .every((v) => needy.Name.toLowerCase().includes(v)) ||
            this.needySearchText
              .toLowerCase()
              .split(" ")
              .every((v) => needy.Family.toLowerCase().includes(v))
          );
        });
      } else {
        return this.$store.getters.GetAllNeedies.needies;
      }
    },
  },
  created() {
    this.$store.dispatch("GetAllNeediesResponse");
    this.$store.dispatch("AuthNeedy", true);
    this.$store.dispatch("AuthNeedyChild", false);
    this.$store.dispatch("AuthHelpRecord", false);
    this.$store.dispatch("OpenCloseNeedyMenu");
  },
  updated() {
    this.$store.dispatch("AuthNeedy", true);
    this.$store.dispatch("AuthHelpRecord", false);
  },
};
</script>
