<template>
  <div>
    <h3 class="mt-3">ویرایش بسته حمایتی</h3>
    <div class="row">
      <div class="col-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">اطلاعات بسته</h4>
          <input
            type="text"
            class="form-control mb-2"
            placeholder="عنوان بسته"
            v-model="AidName"
          />
          <input
            type="number"
            class="form-control"
            placeholder="مبلغ"
            v-model="CostMoney"
          />
          <textarea
            class="form-control mb-3 mt-2"
            placeholder="توضیحات کمک"
            v-model="AidDesc"
          ></textarea>
        </div>
      </div>
      <div class="col-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">عکس بسته</h4>

          <div class="dropify-wrapper">
            <div class="dropify-message" v-if="AidImage == false">
              <span class="file-icon"></span>
              <p>عکس را انتخاب کنید</p>
              <p class="dropify-error">با پوزش فراوان، خطایی رخ داده</p>
            </div>
            <div class="dropify-loader"></div>
            <div class="dropify-errors-container"><ul></ul></div>
            <input
              type="file"
              class="dropify"
              @change="onFileChange($event)"
              name="picture"
              multiple
            />
            <button type="button" class="dropify-clear">پاک کردن</button>
            <img
              class="form-upload-profile"
              :src="(picture == 0 ? BaseAidImageAddress : '') + AidImage"
              alt=""
            />
            <div class="dropify-preview">
              <span class="dropify-render"> </span>
              <div class="dropify-infos">
                <div class="dropify-infos-inner">
                  <p class="dropify-filename">
                    <span class="file-icon"></span>
                    <span class="dropify-filename-inner"></span>
                  </p>
                  <p class="dropify-infos-message">
                    برای جایگزینی فایل را به اینجا بکشید یا کلیک کنید
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-warning m-1 float-end" @click="editAid()">
      ویرایش بسته
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picture: 0,
      UploadVisibility: false,
    };
  },
  methods: {
    editAid() {
      const aidData = new FormData();
      aidData.append("AidID", this.$route.params.id);
      aidData.append("AidName", this.AidName);
      aidData.append("CostMoney", this.CostMoney);
      aidData.append("AidDesc", this.AidDesc);
      if (this.picture != 0) {
        aidData.append("AidImage", this.picture, this.picture.name);
      } else {
        aidData.append("AidImage", null);
      }
      this.$store.dispatch("EditAid", aidData);
    },
    onFileChange(event) {
      this.UploadVisibility = true;
      var files = event.target.files || event.dataTransfer.files;
      this.picture = event.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      // const image = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        this.AidImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    cancel() {
      this.$router.push("/admin/aid");
    },
  },
  computed: {
    Aid() {
      return this.$store.getters.GetAid;
    },
    BaseAidImageAddress() {
      return this.$store.getters.GetBaseAidImageAddress;
    },
    AidName: {
      get() {
        return this.Aid.AidName;
      },
      set(value) {
        this.Aid.AidName = value;
      },
    },
    CostMoney: {
      get() {
        return this.Aid.CostMoney;
      },
      set(value) {
        this.Aid.CostMoney = value;
      },
    },
    AidDesc: {
      get() {
        return this.Aid.AidDesc;
      },
      set(value) {
        this.Aid.AidDesc = value;
      },
    },
    AidImage: {
      get() {
        return this.Aid.AidImage;
      },
      set(value) {
        this.Aid.AidImage = value;
      },
    },
    OldAidImage() {
      return this.$store.getters.GetOldAidImage;
    },
  },
  created() {
    this.$store.dispatch("GetAidById", this.$route.params.id);
    this.$store.dispatch("AuthAid", true);
  },
  updated() {
    this.$store.dispatch("AuthAid", true);
  },
};
</script>
