<template>
  <div>
    <h3 class="mt-3">ویرایش کاربر</h3>
    <div class="row">
      <div class="col-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">اطلاعات شخصی</h4>
          <input
            type="text"
            class="form-control mb-3"
            placeholder="نام کاربری"
            v-model="UserName"
          />
          <input
            type="text"
            class="form-control mb-3"
            placeholder="نام و نام خانوادگی"
            v-model="FullName"
          />
          <input
            type="email"
            class="form-control mb-3"
            placeholder="ایمیل"
            v-model="Email"
          />
          <input
            type="number"
            class="form-control mb-3"
            placeholder="شماره موبایل"
            v-model="Phone"
          />
          <select
            class="form-select mb-3"
            v-model="UserGroupSelected"
            @change="UserGroupID = UserGroupSelected"
          >
            <option
              v-for="group in UserGroups"
              :value="group.UserGroupId"
              :key="group.UserGroupId"
            >
              {{ group.UserGroupName }}
            </option>
          </select>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="IsActive"
              :checked="IsActive == true"
            />
            <label class="form-check-label"> وضعیت </label>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">عکس پروفایل</h4>

          <div class="dropify-wrapper">
            <div class="dropify-message" v-if="UserPicture == false">
              <span class="file-icon"></span>
              <p>فایل را به اینجا بکشید یا کلیک کنید</p>
              <p class="dropify-error">با پوزش فراوان، خطایی رخ داده</p>
            </div>
            <div class="dropify-loader"></div>
            <div class="dropify-errors-container"><ul></ul></div>
            <input
              type="file"
              class="dropify"
              @change="onFileChange($event)"
              name="picture"
              multiple
            />
            <button type="button" class="dropify-clear">پاک کردن</button>
            <img
              class="form-upload-profile"
              :src="(picture == 0 ? BaseUserPictureAddress : '') + UserPicture"
              alt=""
              v-if="UserPicture != false"
            />
            <img
              class="form-upload-profile"
              src="../../images/users/user.png"
              alt=""
              v-else
            />
            <div class="dropify-preview">
              <span class="dropify-render"> </span>
              <div class="dropify-infos">
                <div class="dropify-infos-inner">
                  <p class="dropify-filename">
                    <span class="file-icon"></span>
                    <span class="dropify-filename-inner"></span>
                  </p>
                  <p class="dropify-infos-message">
                    برای جایگزینی فایل را به اینجا بکشید یا کلیک کنید
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-warning m-1 float-end" @click="editUser()">
      ویرایش کاربر
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picture: 0,
    };
  },
  methods: {
    editUser() {
      const EditUserData = new FormData();
      EditUserData.append("UID", this.$route.params.id);
      EditUserData.append("UserName", this.UserName);
      EditUserData.append("FullName", this.FullName);
      EditUserData.append("Email", this.Email);
      EditUserData.append("Phone", this.Phone);
      EditUserData.append("UserGroupId", this.UserGroupID);
      EditUserData.append("IsActive", this.IsActive);
      if (this.picture != 0) {
        EditUserData.append("UserPicture", this.picture, this.picture.name);
      } else {
        EditUserData.append("UserPicture", null);
      }
      this.$store.dispatch("EditUser", EditUserData);
    },
    cancel() {
      this.$router.push("/admin/usergroup");
    },
    onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      this.picture = event.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      // const image = new Image();
      const reader = new FileReader();
      reader.onload = (e) => {
        this.UserPicture = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    UserGroups() {
      return this.$store.getters.getAllUserGroups.userGroups;
    },
    User() {
      return this.$store.getters.GetUser;
    },
    BaseUserPictureAddress() {
      return this.$store.getters.GetBaseUserPictureAddress;
    },
    UserName: {
      get() {
        return this.User.UserName;
      },
      set(value) {
        this.User.UserName = value;
      },
    },
    FullName: {
      get() {
        return this.User.FullName;
      },
      set(value) {
        this.User.FullName = value;
      },
    },
    Email: {
      get() {
        return this.User.Email;
      },
      set(value) {
        this.User.Email = value;
      },
    },
    Phone: {
      get() {
        return this.User.Phone;
      },
      set(value) {
        this.User.Phone = value;
      },
    },
    UserGroupID: {
      get() {
        return this.User.UserGroupId;
      },
      set(value) {
        this.User.UserGroupId = value;
      },
    },
    UserGroupSelected: {
      get() {
        return this.User.UserGroupId;
      },
      set(value) {
        this.User.UserGroupId = value;
      },
    },
    UserPicture: {
      get() {
        return this.User.UserPicture;
      },
      set(value) {
        this.User.UserPicture = value;
      },
    },
    OldUserPicture() {
      return this.$store.getters.GetOldImage;
    },
    IsActive: {
      get() {
        return this.User.IsActive;
      },
      set(value) {
        this.User.IsActive = value;
      },
    },
  },
  created() {
    this.$store.dispatch("GetAllUserGroupsResponse");
    this.$store.dispatch("GetUserById", this.$route.params.id);
    this.$store.dispatch("AuthUserDef", true);
  },
  updated() {
    this.$store.dispatch("AuthUserDef", true);
  },
};
</script>
