import Vue from "vue";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  EditAboutUs(context, aboutUs) {
    Vue.http
      .put("dashboard/aboutUs", { text: aboutUs })
      .then((response) => {
        if (response.status == 200) {
          alert(response.body.message);
        }
      })
      .catch((err) => {
        if (err.status == 422) {
          //Error Code : 422
        } else {
          //Error Code : 500
        }
      });
  },
  EditContactUs(context, contactUs) {
    Vue.http
      .put("dashboard/contactUs", { text: contactUs })
      .then((response) => {
        if (response.status == 200) {
          alert(response.body.message);
        }
      })
      .catch((err) => {
        if (err.status == 422) {
          //Error Code : 422
        } else {
          //Error Code : 500
        }
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
