import Vue from "vue";

const state = {
  addressTypes: [],
  gotAddressType: {},
};

const getters = {
  getAllAddressTypes(state) {
    return state.addressTypes;
  },
  GetAddressType(state) {
    return state.gotAddressType;
  },
};

const mutations = {
  setAddressTypes(state, addressTypes) {
    state.addressTypes = addressTypes;
  },
  setAddressType(state, gotAddressType) {
    state.gotAddressType = gotAddressType;
  },
};

const actions = {
  GetAddressTypesResponse(context) {
    Vue.http
      .get("dashboard/addressType")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setAddressTypes", data);
      })
      .catch((err) => console.log(err));
  },
  GetAddressTypeById(context, id) {
    Vue.http
      .get("dashboard/addressType/" + id)
      .then((response) => {
        context.commit("setAddressType", response.body);
      })
      .catch((err) => console.log(err));
  },
  AddAddressType(context, addressTypeData) {
    Vue.http
      .post("dashboard/addressType", addressTypeData)
      .then((response) => {
        if (response.data != "INVALID_ADDRESS_TYPE") {
          context.dispatch("GetAddressTypesResponse");
        }
      })
      .catch((err) => console.log(err));
  },
  EditAddressType(context, addressTypeData) {
    Vue.http
      .put("dashboard/addressType", addressTypeData)
      .then(() => {
        context.dispatch("GetAddressTypesResponse");
      })
      .catch((err) => console.log(err));
  },
  setAddressTypeStatus(context, statusData) {
    let message = "";
    if (statusData.status == true) {
      message = "آیا از غیرفعالسازی این وضعیت مطمئن هستید؟";
    } else {
      message = "آیا از فعالسازی این وضعیت مطمئن هستید؟";
    }
    if (confirm(message)) {
      Vue.http
        .delete("dashboard/addressType/" + statusData.id)
        .then(() => {
          context.dispatch("GetAddressTypesResponse");
        })
        .catch((err) => console.log(err));
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
