<template>
  <div class="widescreen fixed-left">
    <div id="wrapper">
      <admin-header></admin-header>
      <sidebar-menu></sidebar-menu>
      <div class="content-page">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      id="authorize-err-modal"
      title="محدودیت دسترسی"
      @hidden="onHidden()"
      :showModal="showAuthenticationModal"
    >
      <template v-slot:modalBody>
        <b-alert show variant="danger">
          دسترسی به این قسمت برای شما محدود است..
        </b-alert>
      </template>
    </modal>
  </div>
</template>

<script>
import AdminHeader from "./components/AdminHeader.vue";
import SideBarMenu from "./components/SidebarMenu.vue";
import Modal from "../Modal/Modal.vue";
export default {
  data() {
    return {
      showAuthenticationModal: false,
    };
  },
  methods: {
    showAuthModal() {
      if (this.showModal == true) {
        this.showAuthenticationModal = true;
      }
    },
    onHidden() {
      this.$store.commit("setShowModal", false);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.getIsLoading;
    },
    showModal() {
      return this.$store.getters.GetShowModal;
    },
    isAuth() {
      return this.$store.getters.GetIsAuthenticated;
    },
    Profile() {
      return this.$store.getters.GetProfile;
    },
  },
  components: {
    "admin-header": AdminHeader,
    "sidebar-menu": SideBarMenu,
    Modal,
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.showAuthModal();
      }
    },
  },
  created() {
    this.showAuthModal();
    this.$store.dispatch("SetFullName");
    if (this.Profile.UserGroupId == 0) {
      this.$router.push("/404");
    }
    this.$store.dispatch("GetProfile");
  },
  mounted() {
    if (this.Profile.UserGroupId == 0) {
      this.$router.push("/404");
    }
    this.showAuthModal();
  },
  updated() {
    if (this.Profile.UserGroupId == 0) {
      this.$router.push("/404");
    }
  },
};
</script>
<style>
@import url("./css/dashboard.css");
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
</style>
