import Vue from "vue";

import { router } from "../../router/vueRouter";

const state = {
  posts: [],
  gotPost: {},
  BasePostImageAddress: "https://api.labkhandmahdavi.ir/posts/",
  // BasePostImageAddress: "http://localhost:3000/posts/",
  UploadedPostImageName: "",
  oldPostImage: "",
};

const getters = {
  GetAllPosts(state) {
    return state.posts;
  },
  GetPost(state) {
    return state.gotPost;
  },
  GetOldPostImage(state) {
    return state.oldPostImage;
  },
  GetBasePostImageAddress(state) {
    return state.BasePostImageAddress;
  },
  GetUploadedPostImageName(state) {
    return state.UploadedPostImageName;
  },
};

const mutations = {
  setPosts(state, posts) {
    state.posts = posts;
  },
  setPost(state, post) {
    state.gotPost = post;
  },
  setUploadedPostImageName(state, UploadedPostImageName) {
    state.UploadedPostImageName = UploadedPostImageName;
  },
  setPostOldImage(state, oldPostImage) {
    state.oldPostImage = oldPostImage;
  },
};

const actions = {
  GetAllPosts(context) {
    Vue.http
      .get("dashboard/post")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setPosts", data);
      });
  },
  GetPostById(context, id) {
    Vue.http
      .get("dashboard/post/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setPost", data);
        context.commit("setPostOldImage", data.PostImage);
      });
  },
  AddPost(context, postDataForm) {
    Vue.http
      .post("dashboard/post", postDataForm)
      .then(() => {
        context.dispatch("GetAllPosts");
        router.push("/admin/post");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  EditPost(context, postData) {
    Vue.http
      .put("dashboard/post", postData.data)
      .then(() => {
        context.dispatch("GetAllPosts");
        router.push("/admin/post");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  DeletePost(context, id) {
    if (confirm("آیا از حذف این خبر مطمئن هستید؟")) {
      context.dispatch("DeletePostCategoryByPostID", id);
      Vue.http
        .delete("dashboard/post/" + id)
        .then(() => {
          context.dispatch("GetAllPosts");
        })
        .catch((err) => {
          if (err.status == 422) {
            alert(err.body.message);
          }
        });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
