<template>
  <div>
    <h3 class="mt-3">افزودن خبر جدید</h3>
    <div class="row">
      <div class="col-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">اطلاعات خبر</h4>
          <input
            type="text"
            class="form-control mb-2"
            placeholder="عنوان خبر"
            v-model="PostTitle"
          />
          <textarea
            class="form-control mb-2"
            placeholder="توضیحات کوتاه خبر"
            v-model="PostShortDesc"
          ></textarea>
          <ckeditor
            class="form-control"
            :editor="editor"
            v-model="PostDesc"
            :config="editorConfig"
            tag-name="textarea"
          ></ckeditor>
          <div class="categories">
            <div
              class="category"
              v-for="category in PostCategories.filter(
                (pc) => pc.Parent == null
              )"
              :key="category.CategoryID"
            >
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="category.CategoryID"
                  v-model="checkedCategories"
                />
                <label class="form-check-label">
                  {{ category.CategoryTitle }}
                </label>
              </div>
              <div class="row">
                <div
                  class="subCategory col-6"
                  v-for="spc in PostCategories.filter(
                    (pc) => pc.Parent == category.CategoryID
                  )"
                  :key="spc.CategoryID"
                >
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="spc.CategoryID"
                      v-model="checkedCategories"
                    />
                    <label class="form-check-label">
                      {{ spc.CategoryTitle }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">عکس خبر</h4>

          <div class="dropify-wrapper">
            <div class="dropify-message" v-if="PostImage == false">
              <span class="file-icon"></span>
              <p>عکس را انتخاب کنید</p>
              <p class="dropify-error">با پوزش فراوان، خطایی رخ داده</p>
            </div>
            <div class="dropify-loader"></div>
            <div class="dropify-errors-container"><ul></ul></div>
            <input
              type="file"
              class="dropify"
              @change="onFileChange($event)"
              name="picture"
              multiple
            />
            <button type="button" class="dropify-clear">پاک کردن</button>
            <img
              class="form-upload-profile"
              :src="PostImage"
              alt=""
              v-if="PostImage != false"
            />
            <div class="dropify-preview">
              <span class="dropify-render"> </span>
              <div class="dropify-infos">
                <div class="dropify-infos-inner">
                  <p class="dropify-filename">
                    <span class="file-icon"></span>
                    <span class="dropify-filename-inner"></span>
                  </p>
                  <p class="dropify-infos-message">
                    برای جایگزینی فایل را به اینجا بکشید یا کلیک کنید
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-success m-1 float-end" @click="addPost()">
      افزودن خبر جدید
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      PostTitle: "",
      PostShortDesc: "",
      PostDesc: "",
      picture: {},
      PostImage: false,
      UploadVisibility: false,
      checkedCategories: [],
      editor: ClassicEditor,
      editorConfig: {
        language: "fa",
      },
    };
  },
  methods: {
    addPost() {
      const postDataForm = new FormData();
      postDataForm.append("PostTitle", this.PostTitle);
      postDataForm.append("PostShortDesc", this.PostShortDesc);
      postDataForm.append("PostDesc", this.PostDesc);
      postDataForm.append("PostImage", this.picture, this.picture.name);
      postDataForm.append("checkedCategories", this.checkedCategories);
      this.$store.dispatch("AddPost", postDataForm);
    },
    onFileChange(event) {
      this.UploadVisibility = true;
      var files = event.target.files || event.dataTransfer.files;
      this.picture = event.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      // const image = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        this.PostImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    cancel() {
      this.$router.push("/admin/post");
    },
  },
  computed: {
    PostCategories() {
      return this.$store.getters.GetAllPostCategories.postCategories;
    },
  },
  created() {
    this.$store.dispatch("GetAllPostCategories");
    this.$store.dispatch("AuthPost", true);
  },
  updated() {
    this.$store.dispatch("AuthPost", true);
  },
};
</script>

<style>
.ck-content {
  height: 20rem !important;
}
</style>
