<template>
  <div>
    <router-link to="/admin/addPost" class="btn btn-success"
      >افزودن خبر جدید</router-link
    >
    <input
      type="search"
      class="form-control mb-3 mt-1"
      placeholder="جست و جو..."
      v-model="postSearchText"
    />
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>عنوان خبر</th>
        <th>تاریخ ثبت</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr v-for="post in Posts" :key="post.PostID">
          <td>{{ post.PostTitle }}</td>
          <td>{{ post.createdAt | moment("jYYYY/jMM/jDD") }}</td>
          <td>
            <router-link :to="'/admin/editPost/' + post.PostID">
              <i class="zmdi zmdi-edit m-1 text-warning"></i>
            </router-link>
            <i
              class="zmdi zmdi-delete m-1 text-danger"
              @click="deletePost(post.PostID)"
            ></i>
          </td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
export default {
  components: { DataTable },
  data() {
    return {
      postSearchText: "",
    };
  },
  methods: {
    deletePost(id) {
      this.$store.dispatch("DeletePost", id);
    },
  },
  computed: {
    Posts() {
      if (this.postSearchText) {
        return this.$store.getters.GetAllPosts.posts.filter((post) => {
          return this.postSearchText
            .toLowerCase()
            .split(" ")
            .every((v) => post.PostTitle.toLowerCase().includes(v));
        });
      } else {
        return this.$store.getters.GetAllPosts.posts;
      }
    },
  },
  created() {
    this.$store.dispatch("GetAllPosts");
    this.$store.dispatch("AuthPost", true);
    this.$store.dispatch("OpenCloseBlogMenu");
  },
  updated() {
    this.$store.dispatch("AuthPost", true);
  },
};
</script>
