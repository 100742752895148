<template>
  <div>
    <app-header></app-header>
    <div class="card-box container mt-4">
      <h3 class="mb-3">ویرایش مشخصات کاربری</h3>
      <input
        type="text"
        class="form-control mb-2"
        placeholder="نام کاربری"
        v-model="UserName"
      />
      <input
        type="text"
        class="form-control mb-2"
        placeholder="نام و نام خانوادگی"
        v-model="FullName"
      />
      <input
        type="email"
        class="form-control mb-2"
        placeholder="ایمیل"
        v-model="Email"
      />
      <input
        type="number"
        class="form-control mb-2"
        placeholder="شماره موبایل"
        v-model="Phone"
      />
      <button class="btn btn-warning m-1 float-end" @click="EditInfo()">
        ویرایش
      </button>
      <button class="btn btn-secondary m-1 float-end" @click="cancel()">
        انصراف
      </button>
    </div>
  </div>
</template>

<script>
import AppHeader from "../../../Header.vue";
export default {
  components: {
    "app-header": AppHeader,
  },
  methods: {
    EditInfo() {
      const ChangeInfoData = {
        UID: this.ChangeInfoData.UID,
        UserName: this.UserName,
        FullName: this.FullName,
        Email: this.Email,
        Phone: this.Phone,
      };
      this.$store.dispatch("ChangeInfo", ChangeInfoData);
    },
    cancel() {
      this.$router.push("/profile");
    },
  },
  computed: {
    ChangeInfoData() {
      return this.$store.getters.GetChangeInfoData;
    },
    UserName: {
      get() {
        return this.ChangeInfoData.UserName;
      },
      set(value) {
        this.ChangeInfoData.UserName = value;
      },
    },
    FullName: {
      get() {
        return this.ChangeInfoData.FullName;
      },
      set(value) {
        this.ChangeInfoData.FullName = value;
      },
    },
    Email: {
      get() {
        return this.ChangeInfoData.Email;
      },
      set(value) {
        this.ChangeInfoData.Email = value;
      },
    },
    Phone: {
      get() {
        return this.ChangeInfoData.Phone;
      },
      set(value) {
        this.ChangeInfoData.Phone = value;
      },
    },
  },
  created() {
    this.$store.dispatch("GetChangeInfoData");
  },
};
</script>
