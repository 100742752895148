<template>
  <div>
    <h3 class="mt-3">افراد تحت تکفل</h3>
    <router-link
      :to="'/admin/addneedychild/' + $route.params.id"
      class="btn btn-success m-1"
      >افزودن فرد جدید</router-link
    >
    <router-link to="/admin/needy" class="btn btn-secondary m-1"
      >بازگشت</router-link
    >
    <input
      type="search"
      class="form-control mb-3 mt-1"
      placeholder="جست و جو..."
      v-model="childSearchText"
    />
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>نام</th>
        <th>نام خانوادگی</th>
        <th>نسبت خانوادگی</th>
        <th>کد ملی</th>
        <th>شماره تلفن</th>
        <th>شماره موبایل</th>
        <th>ثبت کننده</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr v-for="child in NeedyChilds" :key="child.NeedyChildID">
          <td>{{ child.ChildName }}</td>
          <td>{{ child.ChildFamily }}</td>
          <td>{{ child.RelationShipTypeName }}</td>
          <td>{{ child.ChildNCode }}</td>
          <td>{{ child.Tel }}</td>
          <td>{{ child.Mobile }}</td>
          <td>{{ child.FullName }}</td>
          <td>
            <router-link
              :to="`/admin/editneedychild/${child.NeedyChildId}`"
              class="zmdi zmdi-edit m-1 text-warning"
            ></router-link>
            <i
              class="zmdi zmdi-delete m-1 text-danger"
              @click="deleteNeedyChild(child.NeedyChildId)"
            ></i>
          </td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
export default {
  components: { DataTable },
  data() {
    return {
      RelationShipType: {},
      isLoading: false,
      fullPage: true,
      childSearchText: "",
    };
  },
  methods: {
    deleteNeedyChild(id) {
      this.$store.dispatch("DeleteNeedyChild", {
        ChildId: id,
        NeedyId: this.$route.params.id,
      });
    },
  },
  computed: {
    RelationShipTypes() {
      return this.$store.getters.getAllRelationTypes.relationTypes;
    },
    NeedyChilds() {
      if (this.childSearchText) {
        return this.$store.getters.GetAllNeedyChilds.needyChilds.filter(
          (child) => {
            return (
              this.childSearchText
                .toLowerCase()
                .split(" ")
                .every((v) => child.ChildName.toLowerCase().includes(v)) ||
              this.childSearchText
                .toLowerCase()
                .split(" ")
                .every((v) => child.ChildFamily.toLowerCase().includes(v))
            );
          }
        );
      } else {
        return this.$store.getters.GetAllNeedyChilds.needyChilds;
      }
    },
  },
  created() {
    this.isLoading = true;
    this.$store.dispatch("GetRelationShipTypesResponse");
    this.$store.dispatch("GetAllNeedyChilds", this.$route.params.id);
    this.$store.dispatch("AuthNeedyChild", true);
  },
  updated() {
    this.isLoading = true;
    this.$store.dispatch("GetRelationShipTypesResponse");
    this.$store.dispatch("GetAllNeedyChilds", this.$route.params.id);
    this.$store.dispatch("AuthNeedyChild", true);
  },
};
</script>
