<template>
  <div>
    <button class="mt-3 btn btn-success" @click="showAddModal = true">
      افزودن وضعیت سکونت جدید
    </button>
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>نام وضعیت</th>
        <th>وضعیت</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr
          v-for="addressType in AddressTypes"
          :key="addressType.AddressTypeID"
        >
          <td>{{ addressType.AddressTypeName }}</td>
          <td>{{ addressType.Status == true ? "فعال" : "غیر فعال" }}</td>
          <td>
            <i
              class="zmdi zmdi-edit m-1 text-warning"
              @click="showGetEditModal(addressType.AddressTypeId)"
            ></i>
            <i
              :class="
                'fa ' +
                (addressType.Status == true
                  ? 'fa-toggle-on'
                  : 'fa-toggle-off') +
                ' text-primary m-1'
              "
              @click="setStatus(addressType.AddressTypeId, addressType.Status)"
            ></i>
          </td>
        </tr>
      </template>
    </data-table>
    <modal
      id="add-addresstype-modal"
      :showModal="showAddModal"
      title="افزودن وضعیت سکونت جدید"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام وضعیت"
          v-model="AddressType.AddressTypeName"
        />
        <button class="btn btn-success m-1 float-end" @click="addAddressType()">
          افزودن وضعیت جدید
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showAddModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
    <modal
      id="edit-addresstype-modal"
      title="ویرایش وضعیت سکونت"
      :showModal="showEditModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام وضعیت"
          v-model="AddressTypeName"
        />
        <button
          class="btn btn-warning m-1 float-end"
          @click="editAddressType()"
        >
          ویرایش وضعیت
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showEditModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
import Modal from "../../../Modal/Modal.vue";
export default {
  components: { DataTable, Modal },
  data() {
    return {
      showAddModal: false,
      showEditModal: false,
      AddressTypeId: 0,
    };
  },
  methods: {
    addAddressType() {
      const addressTypeData = {
        AddressTypeName: this.AddressTypeName,
      };
      this.$store.dispatch("AddAddressType", addressTypeData);
      this.AddressTypeName = "";
      this.showAddModal = false;
    },
    editAddressType() {
      const addressTypeData = {
        AddressTypeId: this.AddressTypeId,
        AddressTypeName: this.AddressTypeName,
      };
      this.$store.dispatch("EditAddressType", addressTypeData);
      this.AddressTypeName = "";
      this.showEditModal = false;
    },
    setStatus(id, status) {
      this.$store.dispatch("setAddressTypeStatus", {
        id,
        status,
      });
    },
    showGetEditModal(id) {
      this.showEditModal = true;
      this.AddressTypeId = id;
      this.$store.dispatch("GetAddressTypeById", this.AddressTypeId);
    },
  },
  computed: {
    AddressTypes() {
      return this.$store.getters.getAllAddressTypes.addressTypes;
    },
    AddressType() {
      return this.$store.getters.GetAddressType;
    },
    AddressTypeName: {
      get() {
        return this.AddressType.AddressTypeName;
      },
      set(value) {
        this.AddressType.AddressTypeName = value;
      },
    },
  },
  created() {
    this.$store.dispatch("GetAddressTypesResponse");
    this.$store.dispatch("AuthAdressTypeDef", true);
    this.$store.dispatch("OpenCloseSetMenu");
  },
  updated() {
    this.$store.dispatch("GetAddressTypesResponse");
    this.$store.dispatch("AuthAdressTypeDef", true);
  },
};
</script>
