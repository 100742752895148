<template>
  <div>
    <app-header></app-header>
    <div id="aid-detail-box" class="card-box container">
      <div class="row">
        <h3 class="aid-title">{{ AidDetail.AidName }}</h3>
        <div class="aid-detail col-6">
          <div class="row">
            <div class="detail col-6">
              <h4>
                <span>مبلغ :</span>
                {{ AidDetail.CostMoney }}
              </h4>
              <h4>
                <span>عنوان کالای حمایتی :</span>
                {{ AidDetail.ObjectName }}
              </h4>
            </div>
            <div class="detail col-6">
              <h4>
                <span>تاریخ ثبت :</span>
                {{ AidDetail.createdAt | moment("jYYYY/jMM/jDD") }}
              </h4>
              <h4><span>تعداد :</span> {{ AidDetail.Num }}</h4>
            </div>
          </div>
          <div class="details">
            <div
              class="row"
              v-for="(detail, index) in AidDetail.DetailsList"
              :key="index"
            >
              <div class="detail col-6">
                <h4>
                  {{ detail.ObjectName }}
                </h4>
              </div>
              <div class="detail col-6">
                <h4>{{ detail.Num }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="aid-detail col-6">
          <div class="aid-img">
            <img
              :src="ImageLink"
              id="aid-image"
              class="float-end rounded-circle"
              alt=""
            />
          </div>
          <div class="aid-progress">
            <span class="payed-title">مبلغ پرداخت شده :</span>
            <span class="payed-number">{{ AidDetail.PayedMoney }}</span>
            <div id="payed-progress" class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :style="{
                  width: `${
                    (AidDetail.PayedMoney / AidDetail.CostMoney) * 100
                  }%`,
                }"
              ></div>
            </div>
          </div>
        </div>
        <div class="aid-desc">
          <div class="title">
            <h4>توضیحات</h4>
          </div>
          <div class="desc">
            {{ AidDetail.AidDesc }}
          </div>
          <div class="help-with-pay">
            <button class="float-end" id="pay-button">
              <i class="zmdi zmdi-card"></i>
              کمک نقدی
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "../Header.vue";
export default {
  components: {
    "app-header": AppHeader,
  },
  computed: {
    AidDetail() {
      return this.$store.getters.GetAidDetails;
    },
    AidImageLink() {
      return this.$store.getters.GetAidImageLink;
    },
    ImageLink() {
      return this.AidImageLink + this.AidDetail.AidImage;
    },
  },
  created() {
    this.$store.dispatch("GetAidDetail", this.$route.params.id);
  },
};
</script>

<style scoped>
@import url("./aidDetail.css");
</style>
