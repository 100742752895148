import Admin from "../views/admin/Admin.vue";

//AdminHome
import AdminHome from "../views/admin/components/AdminHome/AdminHome.vue";

//User Panel
import AddUser from "../views/admin/components/UserPanel/AddUser.vue";
import EditUser from "../views/admin/components/UserPanel/EditUser.vue";

//UserGroup Panel
import UserGroupPanel from "../views/admin/components/UserGroupPanel/UserGroupPanel.vue";
import AddUserGroup from "../views/admin/components/UserGroupPanel/AddUserGroup.vue";
import EditUserGroup from "../views/admin/components/UserGroupPanel/EditUserGroup.vue";
import SetRoles from "../views/admin/components/UserGroupPanel/SetRoles.vue";

//Needy Panel
import NeedyPanel from "../views/admin/components/NeedyPanel/NeedyPanel.vue";
import AddNeedy from "../views/admin/components/NeedyPanel/AddNeedy.vue";
import EditNeedy from "../views/admin/components/NeedyPanel/EditNeedy.vue";

//AddressType Panel
import AddressTypePanel from "../views/admin/components/AddressTypePanel/AddressTypePanel.vue";

//MaritalType Panel
import MaritalTypePanel from "../views/admin/components/MaritalTypePanel/MaritalTypePanel.vue";

//PhisicalType Panel
import PhisicalTypePanel from "../views/admin/components/PhisicalTypePanel/PhisicalTypePanel.vue";

//SickType Panel
import SickTypePanel from "../views/admin/components/SickTypePanel/SickTypePanel.vue";

//WeakType Panel
import WeakTypePanel from "../views/admin/components/WeakTypePanel/WeakTypePanel.vue";

//RelationShipType Panel
import RelationShipTypePanel from "../views/admin/components/RelationShipTypePanel/RelationShipTypePanel.vue";

//NeedyChild Panel
import NeedyChildPanel from "../views/admin/components/NeedyChildPanel/NeedyChildPanel.vue";
import AddNeedyChild from "../views/admin/components/NeedyChildPanel/AddNeedyChild.vue";
import EditNeedyChild from "../views/admin/components/NeedyChildPanel/EditNeedyChild.vue";

//HelpRecord Panel
import HelpRecordPanel from "../views/admin/components/HelpRecordPanel/HelpRecordPanel.vue";
import AddHelpRecord from "../views/admin/components/HelpRecordPanel/AddHelpRecord.vue";

//Object Panel
import ObjectPanel from "../views/admin/components/ObjectPanel/ObjectPanel.vue";
import AddObject from "../views/admin/components/ObjectPanel/AddObject.vue";
import EditObject from "../views/admin/components/ObjectPanel/EditObject.vue";

//Aid Panel
import AidPanel from "../views/admin/components/AidPanel/AidPanel.vue";
import AddAid from "../views/admin/components/AidPanel/AddAid.vue";
import EditAid from "../views/admin/components/AidPanel/EditAid.vue";

//AidDetails Panel
import AidDetailsPanel from "../views/admin/components/AidDetailsPanel/AidDetailsPanel.vue";
import AddAidDetails from "../views/admin/components/AidDetailsPanel/AddAidDetails.vue";
import EditAidDetails from "../views/admin/components/AidDetailsPanel/EditAidDetails.vue";

//PostCategory Panel
import PostCategoryPanel from "../views/admin/components/PostCategoryPanel/PostCategoryPanel.vue";

//Post Panel
import PostPanel from "../views/admin/components/PostPanel/PostPanel.vue";
import AddPost from "../views/admin/components/PostPanel/AddPost.vue";
import EditPost from "../views/admin/components/PostPanel/EditPost.vue";

// Change Admin Details Panel
import ChangeInfo from "../views/admin/components/ChangeInfo/ChangeInfo.vue";
import ChangePassword from "../views/admin/components/ChangePassword/ChangePassword.vue";

// Manage Pages Panels
import AboutUs from "../views/admin/components/AboutUsPanel/AboutUsPanel.vue";
import ContactUs from "../views/admin/components/ContactUsPanel/ContactUsPanel.vue";

export const adminRoute = {
  path: "/admin",
  component: Admin,
  children: [
    {
      path: "/admin",
      component: AdminHome,
    },
    {
      path: "adduser",
      component: AddUser,
    },
    {
      path: "edituser/:id",
      component: EditUser,
    },
    {
      path: "usergroup",
      component: UserGroupPanel,
    },
    {
      path: "addUsergroup",
      component: AddUserGroup,
    },
    {
      path: "editUsergroup/:id",
      component: EditUserGroup,
    },
    {
      path: "setRoles/:id",
      component: SetRoles,
    },
    {
      path: "needy",
      component: NeedyPanel,
    },
    {
      path: "addNeedy",
      component: AddNeedy,
    },
    {
      path: "editneedy/:id",
      component: EditNeedy,
    },
    {
      path: "helpRecord/:id",
      component: HelpRecordPanel,
    },
    {
      path: "addHelpRecord/:id",
      component: AddHelpRecord,
    },
    {
      path: "addresstype",
      component: AddressTypePanel,
    },
    {
      path: "maritaltype",
      component: MaritalTypePanel,
    },
    {
      path: "phisicaltype",
      component: PhisicalTypePanel,
    },
    {
      path: "sicktype",
      component: SickTypePanel,
    },
    {
      path: "weaktype",
      component: WeakTypePanel,
    },
    {
      path: "relationshiptype",
      component: RelationShipTypePanel,
    },
    {
      path: "needychild/:id",
      component: NeedyChildPanel,
    },
    {
      path: "addneedychild/:id",
      component: AddNeedyChild,
    },
    {
      path: "editneedychild/:id",
      component: EditNeedyChild,
    },
    {
      path: "object",
      component: ObjectPanel,
    },
    {
      path: "addobject",
      component: AddObject,
    },
    {
      path: "editobject/:id",
      component: EditObject,
    },
    {
      path: "aid",
      component: AidPanel,
    },
    {
      path: "addaid",
      component: AddAid,
    },
    {
      path: "editaid/:id",
      component: EditAid,
    },
    {
      path: "aiddetail/:id",
      component: AidDetailsPanel,
    },
    {
      path: "addaiddetail/:id",
      component: AddAidDetails,
    },
    {
      path: "editaiddetail/:id",
      component: EditAidDetails,
    },
    {
      path: "postCategory",
      component: PostCategoryPanel,
    },
    {
      path: "post",
      component: PostPanel,
    },
    {
      path: "addPost",
      component: AddPost,
    },
    {
      path: "editPost/:id",
      component: EditPost,
    },
    {
      path: "changeInfo",
      component: ChangeInfo,
    },
    {
      path: "changePassword",
      component: ChangePassword,
    },
    {
      path: "aboutUs",
      component: AboutUs,
    },
    {
      path: "contactUs",
      component: ContactUs,
    },
  ],
};
