<template>
  <div>
    <app-header></app-header>
    <div class="post-detail container">
      <div class="card-box">
        <div class="row">
          <div class="col-6">
            <img
              class="img-fluid post-image"
              :src="ImageLink + Post.PostImage"
              alt=""
            />
          </div>
          <div class="second-detail col-6">
            <div class="post-title">
              <div class="row">
                <h3 class="col-6">{{ Post.PostTitle }}</h3>
                <!-- <h5>تاریخ ثبت :</h5> -->
                <div class="category-list col-6">
                  <div
                    class="category"
                    v-for="category in Post.Categories"
                    :key="category.CategoryID"
                  >
                    {{ category.CategoryTitle }}
                  </div>
                </div>
              </div>
            </div>
            <div class="post-short-desc">
              <h4>
                {{ Post.PostShortDesc }}
              </h4>
            </div>
            <div class="post-desc">
              <div v-html="Post.PostDesc"></div>
            </div>
            <div class="create-date">
              {{ Post.createdAt | moment("jYYYY/jMM/jDD") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "../Header.vue";
export default {
  components: {
    "app-header": AppHeader,
  },
  computed: {
    Post() {
      return this.$store.getters.GetPostDetail;
    },
    ImageLink() {
      return this.$store.getters.GetBasePostImageAddress;
    },
  },
  created() {
    this.$store.dispatch("GetPostDetail", this.$route.params.id);
  },
};
</script>

<style scoped>
@import url("./blogDetail.css");
</style>
