<template>
  <div>
    <button class="mt-3 btn btn-success" @click="showAddModal = true">
      افزودن وضعیت معلولیت جدید
    </button>
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>نام وضعیت</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr v-for="weakType in WeakTypes" :key="weakType.WeakTypeId">
          <td>{{ weakType.WeakTypeName }}</td>
          <td>
            <i
              class="zmdi zmdi-edit text-warning m-1"
              @click="showGetEditModal(weakType.WeakTypeId)"
            ></i>
            <i
              :class="
                'fa ' +
                (weakType.Status == true ? 'fa-toggle-on' : 'fa-toggle-off') +
                ' text-primary m-1'
              "
              @click="setStatus(weakType.WeakTypeId, weakType.Status)"
            ></i>
          </td>
        </tr>
      </template>
    </data-table>

    <modal
      id="add-weaktype-modal"
      title="افزودن وضعیت معلولیت جدید"
      :showModal="showAddModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام وضعیت"
          v-model="WeakType.WeakTypeName"
        />
        <button class="btn btn-success m-1 float-end" @click="addWeakType()">
          افزودن وضعیت جدید
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showAddModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
    <modal
      id="edit-weaktype-modal"
      title="ویرایش وضعیت معولیت"
      :showModal="showEditModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام وضعیت"
          v-model="EditWeakType.WeakTypeName"
        />
        <button class="btn btn-warning m-1 float-end" @click="editWeakType()">
          ویرایش وضعیت
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showEditModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
import Modal from "../../../Modal/Modal.vue";
export default {
  components: { DataTable, Modal },
  data() {
    return {
      showAddModal: false,
      showEditModal: false,
      WeakType: {
        WeakTypeName: "",
      },
      EditWeakType: {
        WeakTypeID: 0,
        WeakTypeName: "",
      },
    };
  },
  methods: {
    addWeakType() {
      this.$http
        .post("dashboard/weakType", this.WeakType)
        .then(() => {
          this.showAddModal = false;
          this.$store.dispatch("GetWeakTypesResponse");
          this.WeakType.WeakTypeName = "";
        })
        .catch((err) => console.log(err));
    },
    showGetEditModal(id) {
      this.showEditModal = true;
      this.EditWeakType.WeakTypeID = id;
      this.$http
        .get("dashboard/weakType/" + this.EditWeakType.WeakTypeID)
        .then((response) => {
          this.EditWeakType.WeakTypeName = response.data.WeakTypeName;
        })
        .catch((err) => console.log(err));
    },
    editWeakType() {
      this.$http
        .put("dashboard/weakType", {
          WeakTypeId: this.EditWeakType.WeakTypeID,
          WeakTypeName: this.EditWeakType.WeakTypeName,
        })
        .then(() => {
          this.showEditModal = false;
          this.$store.dispatch("GetWeakTypesResponse");
          this.EditWeakType.WeakTypeName = "";
        })
        .catch((err) => console.log(err));
    },

    setStatus(id, status) {
      let message = "";
      if (status == true) {
        message = "آیا از غیرفعالسازی این وضعیت مطمئن هستید؟";
      } else {
        message = "آیا از فعالسازی این وضعیت مطمئن هستید؟";
      }
      if (confirm(message)) {
        this.$http
          .delete("dashboard/weakType/" + id)
          .then(() => {
            this.$store.dispatch("GetWeakTypesResponse");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    WeakTypes() {
      return this.$store.getters.getAllWeakTypes.weakTypes;
    },
  },
  created() {
    this.$store.dispatch("GetWeakTypesResponse");
    this.$store.dispatch("AuthWeakTypeDef", true);
    this.$store.dispatch("OpenCloseSetMenu");
  },
  updated() {
    this.$store.dispatch("AuthWeakTypeDef", true);
  },
};
</script>
