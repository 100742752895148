<template>
  <div class="topbar">
    <!-- LOGO -->
    <div class="topbar-left">
      <router-link to="/" class="logo">
        <span>سامانه لبخند مهدوی</span>
      </router-link>
    </div>

    <div class="navbar navbar-default" role="navigation">
      <div class="w-100">
        <ul class="nav navbar-nav navbar-left">
          <!-- Header Icons -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
