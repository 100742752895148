import Blog from "../views/Blog/Blog.vue";
import BlogDetail from "../views/BlogDetail/BlogDetail.vue";

export const blogRoute = {
  component: Blog,
  path: "/blog",
};

export const blogDetailRoute = {
  component: BlogDetail,
  path: "/blog/blogDetail/:id",
};
