<template>
  <div>
    <h3 class="mt-3">ویرایش فرد تحت سرپرستی</h3>
    <input
      type="text"
      class="form-control mb-3"
      placeholder="نام"
      v-model="ChildName"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="نام خانوادگی"
      v-model="ChildFamily"
    />
    <select
      class="form-select mb-3"
      v-model="RelationShipSelected"
      @change="RelationShipTypeID = RelationShipSelected"
    >
      <option
        v-for="relationShip in RelationShipTypes"
        :key="relationShip.RelationShipTypeId"
        :value="relationShip.RelationShipTypeId"
      >
        {{ relationShip.RelationShipTypeName }}
      </option>
    </select>
    <input
      type="text"
      class="form-control mb-3"
      placeholder="کد ملی"
      v-model="ChildNCode"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="شماره تلفن"
      v-model="Tel"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="شماره موبایل"
      v-model="Mobile"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="آدرس"
      v-model="Address"
    />
    <button class="btn btn-warning m-1 float-end" @click="EditNeedyChild()">
      ویرایش فرد
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    cancel() {
      this.$router.push("/admin/needychild/" + this.NeedyChild.NeedyId);
    },
    EditNeedyChild() {
      const needyChildData = {
        data: {
          NeedyChildID: this.$route.params.id,
          ChildName: this.ChildName,
          ChildFamily: this.ChildFamily,
          RelationShipTypeID: this.RelationShipTypeID,
          ChildNCode: this.ChildNCode,
          Tel: this.Tel,
          Mobile: this.Mobile,
          NeedyID: this.NeedyChild.NeedyId,
          Address: this.Address,
        },
        nid: this.NeedyChild.NeedyId,
      };
      this.$store.dispatch("EditNeedyChild", needyChildData);
    },
  },
  computed: {
    RelationShipTypes() {
      return this.$store.getters.getAllRelationTypes.relationShipTypes;
    },
    NeedyChild() {
      return this.$store.getters.GetNeedyChild;
    },
    ChildName: {
      get() {
        return this.NeedyChild.ChildName;
      },
      set(value) {
        this.NeedyChild.ChildName = value;
      },
    },
    ChildFamily: {
      get() {
        return this.NeedyChild.ChildFamily;
      },
      set(value) {
        this.NeedyChild.ChildFamily = value;
      },
    },
    RelationShipTypeID: {
      get() {
        return this.NeedyChild.RelationShipTypeID;
      },
      set(value) {
        this.NeedyChild.RelationShipTypeId = value;
      },
    },
    RelationShipSelected: {
      get() {
        return this.NeedyChild.RelationShipTypeID;
      },
      set(value) {
        this.NeedyChild.RelationShipTypeID = value;
      },
    },
    ChildNCode: {
      get() {
        return this.NeedyChild.ChildNCode;
      },
      set(value) {
        this.NeedyChild.ChildNCode = value;
      },
    },
    Tel: {
      get() {
        return this.NeedyChild.Tel;
      },
      set(value) {
        this.NeedyChild.Tel = value;
      },
    },
    Mobile: {
      get() {
        return this.NeedyChild.Mobile;
      },
      set(value) {
        this.NeedyChild.Mobile = value;
      },
    },
    Address: {
      get() {
        return this.NeedyChild.Address;
      },
      set(value) {
        this.NeedyChild.Address = value;
      },
    },
  },
  created() {
    this.$store.dispatch("GetRelationShipTypesResponse");
    this.$store.dispatch("GetNeedyChildById", this.$route.params.id);
    this.$store.dispatch("AuthNeedyChild", true);
  },
  updated() {
    this.$store.dispatch("AuthNeedyChild", true);
  },
};
</script>
