import Vue from "vue";

import { router } from "../../router/vueRouter";

const state = {
  users: [],
  gotUser: {},
  BaseUserPictureAddress: "https://api.labkhandmahdavi.ir/users/",
  // BaseUserPictureAddress: "http://localhost:3000/users/",
  UploadedUserPictureName: "",
  oldImage: "",
};

const getters = {
  GetAllUsers(state) {
    return state.users;
  },
  GetUser(state) {
    return state.gotUser;
  },
  GetOldImage(state) {
    return state.oldImage;
  },
  GetBaseUserPictureAddress(state) {
    return state.BaseUserPictureAddress;
  },
  GetUploadedUserPictureName(state) {
    return state.UploadedUserPictureName;
  },
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  setUser(state, gotUser) {
    state.gotUser = gotUser;
  },
  setUploadedUserPictureName(state, UploadedUserPictureName) {
    state.UploadedUserPictureName = UploadedUserPictureName;
  },
  setOldImage(state, oldImage) {
    state.oldImage = oldImage;
  },
};

const actions = {
  GetAllUsersResponse(context) {
    Vue.http
      .get("dashboard/user")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setUsers", data);
      });
  },
  GetUserById(context, id) {
    Vue.http
      .get("dashboard/user/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setUser", data);
        context.commit("setOldImage", data.UserPicture);
      });
  },
  AddUser(context, userAdminData) {
    Vue.http
      .post("dashboard/user", userAdminData)
      .then(() => {
        router.push("/admin/usergroup");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  EditUser(context, userAdminData) {
    Vue.http
      .put("dashboard/user", userAdminData)
      .then(() => {
        router.push("/admin/usergroup");
        context.dispatch("GetProfile");
      })
      .catch((err) => console.log(err));
  },
  DeleteUser(context, userAdminData) {
    if (confirm(`آیا از حذف کاربر ${userAdminData.name} مطمئن هستید؟`)) {
      Vue.http.delete("dashboard/user/" + userAdminData.id).then(() => {
        context.dispatch("GetAllUsersResponse");
      });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
