<template>
  <div>
    <h3 class="mt-3">افزودن کاربر جدید</h3>
    <div class="row">
      <div class="col-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">اطلاعات شخصی</h4>
          <input
            type="text"
            class="form-control mb-3"
            placeholder="نام کاربری"
            v-model="UserName"
          />
          <input
            type="password"
            class="form-control mb-3"
            placeholder="رمز عبور"
            v-model="Password"
          />
          <input
            type="text"
            class="form-control mb-3"
            placeholder="نام و نام خانوادگی"
            v-model="FullName"
          />
          <input
            type="email"
            class="form-control mb-3"
            placeholder="ایمیل"
            v-model="Email"
          />
          <input
            type="number"
            class="form-control mb-3"
            placeholder="شماره موبایل"
            v-model="Phone"
          />
          <select
            class="form-select mb-3"
            v-model="UserGroupSelected"
            @change="UserGroupId = UserGroupSelected"
          >
            <option value="" selected>
              لطفا گروه کاربری را انتخاب کنید...
            </option>
            <option
              v-for="userGroup in UserGroups"
              :key="userGroup.UserGroupId"
              :value="userGroup.UserGroupId"
            >
              {{ userGroup.UserGroupName }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">عکس پروفایل</h4>

          <div class="dropify-wrapper">
            <div class="dropify-message" v-if="UserPicture == false">
              <span class="file-icon"></span>
              <p>عکس را انتخاب کنید</p>
              <p class="dropify-error">با پوزش فراوان، خطایی رخ داده</p>
            </div>
            <div class="dropify-loader"></div>
            <div class="dropify-errors-container"><ul></ul></div>
            <input
              type="file"
              class="dropify"
              @change="onFileChange($event)"
              name="picture"
              multiple
            />
            <button type="button" class="dropify-clear">پاک کردن</button>
            <img
              class="form-upload-profile"
              :src="UserPicture"
              alt=""
              v-if="UserPicture != false"
            />
            <div class="dropify-preview">
              <span class="dropify-render"> </span>
              <div class="dropify-infos">
                <div class="dropify-infos-inner">
                  <p class="dropify-filename">
                    <span class="file-icon"></span>
                    <span class="dropify-filename-inner"></span>
                  </p>
                  <p class="dropify-infos-message">
                    برای جایگزینی فایل را به اینجا بکشید یا کلیک کنید
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-success m-1 float-end" @click="AddUser()">
      افزودن کاربر جدید
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      UserGroupSelected: "",
      UserName: "",
      Password: "",
      FullName: "",
      Email: "",
      Phone: "",
      UserGroupId: 0,
      UserPicture: false,
      picture: null,
    };
  },
  methods: {
    UploadImage() {
      return this.$store.dispatch("UploadAidImage", {
        userPicture: this.picture,
        oldImage: null,
      });
    },
    AddUser() {
      const UserData = new FormData();
      UserData.append("UserName", this.UserName);
      UserData.append("Password", this.Password);
      UserData.append("FullName", this.FullName);
      UserData.append("Email", this.Email);
      UserData.append("Phone", this.Phone);
      UserData.append("UserGroupId", this.UserGroupId);
      if (this.picture) {
        console.log("");
        UserData.append("UserPicture", this.picture, this.picture.name);
      }
      this.$store.dispatch("AddUser", UserData);
    },
    cancel() {
      this.$router.push("/admin/usergroup");
    },
    onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      this.picture = event.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      // const image = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        this.UserPicture = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    UserGroups() {
      return this.$store.getters.getAllUserGroups.userGroups;
    },
  },
  created() {
    this.$store.dispatch("GetAllUserGroupsResponse");

    // let externalScript = document.createElement("script");
    // externalScript.setAttribute("src", "../../js/modernzir.js");
    // document.head.appendChild(externalScript);
    this.$store.dispatch("AuthUserDef", true);
  },
  updated() {
    // this.$store.dispatch("GetAllUserGroupsResponse");

    // let externalScript = document.createElement("script");
    // externalScript.setAttribute("src", "../../js/modernzir.js");
    // document.head.appendChild(externalScript);
    this.$store.dispatch("AuthUserDef", true);
  },
};
</script>
