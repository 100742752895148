<template>
  <div>
    <b-modal id="authorize-err-modal" ref="authorize-err-modal">
      <b-alert show variant="danger">
        دسترسی به این قسمت برای شما محدود است...
      </b-alert>
    </b-modal>
    <splash v-show="SplashID == 0"></splash>
    <router-view v-show="SplashID == 1"></router-view>
  </div>
</template>

<script>
import Splash from "./views/splash/Splash.vue";
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  components: {
    splash: Splash,
  },
  computed: {
    SplashID() {
      return this.$store.getters.getSplashID;
    },
  },
  created() {
    setTimeout(() => {
      this.$store.commit("setSplashID", 1);
    }, 2000);

    this.$store.dispatch("SetFullName");
    // setTimeout(() => {
    this.$store.commit("setSplashID", 1);

    if (!navigator.onLine) {
      this.$store.commit("setSplashID", 0);
    } else {
      this.$store.commit("setSplashID", 1);
    }
    // }, 2000);

    this.$store.dispatch("AddVisitSite");
  },

  updated() {
    if (!navigator.onLine) {
      setInterval(() => {
        if (!navigator.onLine) {
          this.$store.commit("setSplashID", 0);
        } else {
          this.$store.commit("setSplashID", 1);
        }
      }, 2000);
    } else {
      this.$store.commit("setSplashID", 1);
    }
  },
};
</script>
