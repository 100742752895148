<template>
  <div>
    <app-header></app-header>
    <div class="searchbar container">
      <input
        type="text"
        id="input-search"
        placeholder="دنبال چه نیازی هستید؟"
        class="form-control"
        aria-label="جست و جو"
        v-model="searchText"
      />
      <div class="requirement-list">
        <div class="row g-2">
          <div
            class="col-12 col-sm-6 col-md-4"
            v-for="aid in AidItems"
            :key="aid.AidID"
          >
            <router-link
              class="card-box requirement"
              tag="article"
              :to="'/aid/aidDetail/' + aid.AidID"
            >
              <img
                :src="AidImageLink + aid.AidImage"
                class="img-fluid aid-image w-100"
                alt=""
              />
              <h4 class="requirement-title text-center">{{ aid.AidName }}</h4>
              <h6 class="requirement-desc text-center">
                {{
                  aid.AidDesc.length > 80
                    ? aid.AidDesc.substring(0, 80) + "..."
                    : aid.AidDesc
                }}
              </h6>
              <div class="grid" id="aid-money">
                <h6 class="float-start" id="aid-payed-money">
                  {{ aid.PayedMoney }}
                </h6>
                <h6 class="float-end" id="aid-cost-money">
                  {{ aid.CostMoney }}
                </h6>
              </div>
              <div class="progress" id="aid-payed-money-progress">
                <div
                  class="progress-bar progress-bar-animated"
                  role="progressbar"
                  :style="{
                    width: `${(aid.PayedMoney / aid.CostMoney) * 100}%`,
                  }"
                ></div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <nav class="d-flex justify-content-center">
        <ul class="pagination">
          <li
            v-for="pageNumber in Aids.totalPages"
            :key="pageNumber"
            :class="
              pageNumber == Aids.currentPage ? 'page-item active' : 'page-item'
            "
          >
            <router-link class="page-link" :to="'?page=' + pageNumber">{{
              pageNumber
            }}</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import AppHeader from "../Header.vue";
export default {
  data() {
    return {
      searchText: "",
    };
  },
  components: {
    "app-header": AppHeader,
  },
  watch: {
    $route() {
      this.$store.dispatch("GetAllAids", {
        page: this.$route.query.page,
        search: this.searchText,
      });
    },
    searchText() {
      this.$store.dispatch("GetAllAids", {
        page: this.$route.query.page >= 1 ? 1 : this.$route.query.page,
        search: this.searchText,
      });
    },
  },
  computed: {
    Aids() {
      return this.$store.getters.GetAids;
    },
    AidItems() {
      if (this.searchText) {
        return this.$store.getters.GetAids.aids.filter((aid) => {
          return this.searchText
            .toLowerCase()
            .split(" ")
            .every((v) => aid.AidName.toLowerCase().includes(v));
        });
      } else {
        return this.$store.getters.GetAids.aids;
      }
    },
    AidImageLink() {
      return this.$store.getters.GetAidImageLink;
    },
  },
  created() {
    this.$store.dispatch("GetAllAids", {
      page: this.$route.query.page,
      search: "",
    });
  },
};
</script>
<style scoped>
@import url("./aids.css");
</style>
