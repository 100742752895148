<template>
  <div>
    <button class="mt-3 btn btn-success" @click="showAddModal = true">
      افزودن وضعیت تأهل جدید
    </button>
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>نام وضعیت</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr
          v-for="maritalType in MaritalTypes"
          :key="maritalType.MaritalTypeId"
        >
          <td>{{ maritalType.MaritalTypeName }}</td>
          <td>
            <i
              class="zmdi zmdi-edit text-warning"
              @click="showGetEditModal(maritalType.MaritalTypeId)"
            ></i>
            <i
              :class="
                'fa ' +
                (maritalType.Status == true
                  ? 'fa-toggle-on'
                  : 'fa-toggle-off') +
                ' text-primary m-1'
              "
              @click="setStatus(maritalType.MaritalTypeId, maritalType.Status)"
            ></i>
          </td></tr
      ></template>
    </data-table>
    <modal
      id="add-maritaltype-modal"
      title="افزودن وضعیت تأهل جدید"
      :showModal="showAddModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام وضعیت"
          v-model="MaritalType.MaritalTypeName"
        />
        <button class="btn btn-success m-1 float-end" @click="addMaritalType()">
          افزودن وضعیت جدید
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showAddModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
    <modal
      id="edit-addresstype-modal"
      title="ویرایش وضعیت تأهل سکونت"
      :showModal="showEditModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام وضعیت"
          v-model="EditMaritalType.MaritalName"
        />
        <button
          class="btn btn-warning m-1 float-end"
          @click="editMaritalType()"
        >
          ویرایش وضعیت
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showEditModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
import Modal from "../../../Modal/Modal.vue";
export default {
  components: { DataTable, Modal },
  data() {
    return {
      showAddModal: false,
      showEditModal: false,
      MaritalType: {
        MaritalName: "",
      },
      EditMaritalType: {
        MaritalId: 0,
        MaritalName: "",
      },
    };
  },
  methods: {
    addMaritalType() {
      this.$http
        .post("dashboard/maritalType", this.MaritalType)
        .then((response) => {
          if (response.data == "INVALID_MARITAL_TYPE") {
            return "INVALID MARITAL TYPE";
          } else {
            this.showAddModal = false;
            this.$store.dispatch("GetMaritalTypesResponse");
            this.MaritalType.MaritalName = "";
          }
        })
        .catch((err) => console.log(err));
    },
    showGetEditModal(id) {
      this.showEditModal = true;
      this.EditMaritalType.MaritalId = id;
      this.$http
        .get("dashboard/maritalType/" + this.EditMaritalType.MaritalId)
        .then((response) => {
          this.EditMaritalType.MaritalName = response.data.MaritalTypeName;
        })
        .catch((err) => console.log(err));
    },
    editMaritalType() {
      this.$http
        .put("dashboard/maritalType", {
          MaritalTypeId: this.EditMaritalType.MaritalId,
          MaritalTypeName: this.EditMaritalType.MaritalName,
        })
        .then(() => {
          this.showEditModal = false;
          this.$store.dispatch("GetMaritalTypesResponse");
          this.EditMaritalType.MaritalName = "";
        })
        .catch((err) => console.log(err));
    },
    setStatus(id, status) {
      let message = "";
      if (status == true) {
        message = "آیا از غیرفعالسازی این وضعیت مطمئن هستید؟";
      } else {
        message = "آیا از فعالسازی این وضعیت مطمئن هستید؟";
      }
      if (confirm(message)) {
        this.$http
          .delete("dashboard/maritalType/" + id)
          .then(() => {
            this.$store.dispatch("GetMaritalTypesResponse");
          })
          .catch((err) => console.log(err));
      }
    },
  },
  computed: {
    MaritalTypes() {
      return this.$store.getters.getAllMaritalTypes.maritalTypes;
    },
  },
  created() {
    this.$store.dispatch("GetMaritalTypesResponse");
    this.$store.dispatch("AuthMaritalTypeDef", true);
    this.$store.dispatch("OpenCloseSetMenu");
  },
  updated() {
    this.$store.dispatch("AuthMaritalTypeDef", true);
  },
};
</script>
