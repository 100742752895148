<template>
  <div class="login-tab">
    <div class="form">
      <input
        type="text"
        class="form-control"
        placeholder="نام کاربری"
        v-model="UserName"
      />
      <input
        type="password"
        class="form-control"
        placeholder="رمز عبور"
        v-model="password"
      />
      <button @click="login()">ورود</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      UserName: "",
      password: "",
    };
  },
  methods: {
    login() {
      const LoginUserData = {
        UserName: this.UserName,
        Password: this.password,
      };
      this.$store.dispatch("Login", LoginUserData);
    },
  },
};
</script>

<style scoped>
@import url("./login.css");
</style>
