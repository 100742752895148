import Vue from "vue";
import VueResource from "vue-resource";

import { store } from "../store/store";

Vue.use(VueResource);

Vue.http.options.root = "https://api.labkhandmahdavi.ir/";
// Vue.http.options.root = "http://localhost:3000/";

Vue.http.interceptors.push((request, next) => {
  request.headers.set(
    "Authorization",
    `bearer ${Vue.cookie.get("LabkhandAuthenticationToken")}`
  );

  store.commit("setSplashID", 0);
  next((response) => {
    store.commit("setSplashID", 0);
    return response;
  });
});
