<template>
  <div class="widescreen">
    <router-link to="/admin/addUsergroup" class="btn btn-success mt-4"
      >افزودن گروه جدید</router-link
    >
    <div class="mt-2">
      <input
        type="search"
        class="form-control mb-3"
        placeholder="جست و جو..."
        v-model="userGroupSearchText"
      />
      <data-table>
        <template v-slot:tbl-thead>
          <th @click="sort('UserGroupName')">نام گروه</th>
          <th></th>
        </template>
        <template v-slot:tbl-tbody>
          <tr v-for="group in UserGroups" :key="group.UserGroupId" role="row">
            <td
              @click="
                UserGroupIDUser = group.UserGroupId;
                usersVisiblity = true;
              "
              id="userGroupName"
            >
              {{ group.UserGroupName }}
            </td>
            <td>
              <router-link :to="'/admin/editUsergroup/' + group.UserGroupId">
                <i class="zmdi zmdi-edit m-1 text-warning"></i>
              </router-link>
              <i
                class="zmdi zmdi-delete m-1 text-danger"
                id="deleteUserGroup"
                v-if="group.userLength > 0 ? false : true"
                @click="deleteUserGroup(group.UserGroupId, group.UserGroupName)"
              ></i>
              <i
                id="role-settings"
                class="zmdi zmdi-settings m-1 text-primary"
                @click="
                  showModal = true;
                  setRoles(group.UserGroupId);
                "
              ></i>
            </td>
          </tr>
        </template>
      </data-table>
    </div>
    <div class="mt-2" v-if="usersVisiblity">
      <router-link to="/admin/adduser" class="btn btn-success mb-2"
        >افزودن کاربر جدید</router-link
      >
      <input
        type="search"
        class="form-control mb-3"
        placeholder="جست و جو..."
        v-model="userSearchText"
      />
      <data-table>
        <template v-slot:tbl-thead>
          <th>نام کاربری</th>
          <th>نام و نام خانوادگی</th>
          <th>ایمیل</th>
          <th>شماره موبایل</th>
          <th>وضعیت</th>
          <th>تاریخ ثبت نام</th>
          <th></th>
        </template>
        <template v-slot:tbl-tbody>
          <tr v-for="user in Users" :key="user.UID" role="row">
            <td>{{ user.UserName }}</td>
            <td>{{ user.FullName }}</td>
            <td>{{ user.Email }}</td>
            <td>{{ user.Phone }}</td>
            <td>{{ user.IsActive == true ? "فعال" : "غیرفعال" }}</td>
            <td>{{ user.createdAt | moment("jYYYY/jMM/jDD") }}</td>
            <td>
              <router-link :to="'/admin/edituser/' + user.UID">
                <i class="zmdi zmdi-edit m-1 text-warning"></i>
              </router-link>
              <i
                class="zmdi zmdi-delete m-1 text-danger"
                id="delteUser"
                @click="deleteUser(user.UID, user.FullName)"
              ></i>
            </td>
          </tr>
        </template>
      </data-table>
    </div>
    <modal :showModal="showModal" title="ثبت دسترسی">
      <template v-slot:modalBody>
        <div class="roles">
          <div
            class="role"
            v-for="role in Roles.filter((role) => role.Parent == null)"
            :key="role.RoleID"
          >
            <div class="form-check">
              <input
                type="checkbox"
                name=""
                id=""
                :value="role.RoleID"
                class="form-check-input"
                v-model="checkedRoles"
              />
              <label class="form-check-label">{{ role.RoleTitle }}</label>
            </div>
            <div class="row subroles">
              <div
                class="subrole col-6"
                v-for="subRole in Roles.filter(
                  (subRole) => subRole.Parent == role.RoleID
                )"
                :key="subRole.RoleID"
              >
                <div class="form-check">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    class="form-check-input"
                    :value="subRole.RoleID"
                    v-model="checkedRoles"
                  />
                  <label class="form-check-label">{{
                    subRole.RoleTitle
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-usergroup-buttons float-end">
          <button class="btn btn-danger mx-1" @click="showModal = false">
            انصراف
          </button>
          <button class="btn btn-success" @click="addSetRoles()">ثبت</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
import Modal from "../../../Modal/Modal.vue";
export default {
  data() {
    return {
      usersVisiblity: false,
      UserList: [],
      UserGroupId: 0,
      UserGroupIDUser: 0,
      checkedRoles: [],
      userGroupSearchText: null,
      userSearchText: null,
      userSearched: [],
      currentSort: "id",
      currentSortDir: "asc",
      showModal: false,
      isPageUpdated: false,
    };
  },
  methods: {
    deleteUserGroup(id, groupName) {
      this.$store.dispatch("DeleteUserGroup", {
        id,
        groupName,
      });
    },
    setRoles(id) {
      this.UserGroupId = id;
      this.$store.dispatch("getAllRoleGroupsResponse", id).then((data) => {
        data.roleGroups.forEach((roleGroup) => {
          this.checkedRoles.push(roleGroup.RoleID);
        });
      });
    },
    addSetRoles() {
      this.$store.dispatch("addSetRoles", {
        UserGroupId: this.UserGroupId,
        checkedRoles: this.checkedRoles,
      });
      this.showModal = false;
    },
    deleteUser(id, name) {
      this.$store.dispatch("DeleteUser", {
        id,
        name,
      });
    },
  },
  computed: {
    UserGroups() {
      if (this.userGroupSearchText) {
        return this.$store.getters.getAllUserGroups.userGroups.filter(
          (usergroup) => {
            return this.userGroupSearchText
              .toLowerCase()
              .split(" ")
              .every((v) => usergroup.UserGroupName.toLowerCase().includes(v));
          }
        );
      } else {
        return this.$store.getters.getAllUserGroups.userGroups;
      }
    },
    Users() {
      if (this.userSearchText != null) {
        return this.$store.getters.GetAllUsers.users
          .filter((user) => user.UserGroupId == this.UserGroupIDUser)
          .filter((user) => {
            return (
              this.userSearchText
                .toLowerCase()
                .split(" ")
                .every((v) => user.FullName.toLowerCase().includes(v)) ||
              this.userSearchText
                .toLowerCase()
                .split(" ")
                .every((v) => user.UserName.toLowerCase().includes(v))
            );
          });
      } else {
        return this.$store.getters.GetAllUsers.users.filter(
          (user) => user.UserGroupId == this.UserGroupIDUser
        );
      }
    },
    Roles() {
      return this.$store.getters.getAllRoles.roles;
    },
  },
  components: {
    Modal,
    DataTable,
  },
  created() {
    this.UserGroupSortName = "id";
    this.$store.dispatch("GetAllUserGroupsResponse");
    this.$store.dispatch("GetAllUsersResponse");
    this.$store.dispatch("setRolesResponse");
    this.$store.dispatch("AuthUserDef", true);
    this.$store.dispatch("OpenCloseSetMenu");
  },
  updated() {
    this.UserGroupSortName = "id";
    this.$store.dispatch("AuthUserDef", true);
  },
};
</script>
