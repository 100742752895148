<template>
  <div>
    <app-header></app-header>
    <div class="about-box container card-box">
      <h3 id="aboutus-title">درباره ما</h3>
      <div class="desc" v-html="AboutUs"></div>
    </div>
  </div>
</template>

<script>
import AppHeader from "../Header.vue";
export default {
  components: {
    "app-header": AppHeader,
  },
  computed: {
    AboutUs() {
      return this.$store.getters.AboutUs;
    },
  },
  created() {
    this.$store.dispatch("AboutUs");
  },
};
</script>

<style scoped>
@import url("./aboutUs.css");
</style>
