<template>
  <div>
    <app-header></app-header>
    <div id="profile-card" class="card-box container">
      <div class="user-box">
        <div class="user-img" v-if="Profile.UserPicture">
          <img
            :src="UserPicture"
            alt="user-img"
            :title="'پروفایل ' + FullName"
            id="user-profile-picture"
            class="img-circle img-thumbnail img-responsive"
          />
        </div>
        <div class="user-img" v-else>
          <img
            src="./user.png"
            alt="user-img"
            :title="'پروفایل ' + FullName"
            class="img-circle img-thumbnail img-responsive"
          />
        </div>
        <h5>{{ FullName }}</h5>
        <input
          type="file"
          ref="file"
          v-show="false"
          @change="onFileChange($event)"
        />
        <button
          id="change-picture-button"
          class="btn-sm"
          @click="$refs.file.click()"
        >
          ویرایش تصویر پروفایل
        </button>
      </div>
      <div class="activities-list">
        <div class="change-password">
          <router-link to="/profile/changePassword">
            <i class="zmdi zmdi-key"></i>
            ویرایش رمز عبور
          </router-link>
        </div>
        <div class="change-info">
          <router-link to="/profile/changeInfo">
            <i class="zmdi zmdi-account"></i>
            ویرایش مشخصات کاربری
          </router-link>
        </div>
        <div class="exit" @click="LogOut()">
          <i class="zmdi zmdi-sign-in"></i>
          خروج
        </div>
      </div>
      <div class="row">
        <div class="col-12 count-box">
          <div class="count-number">
            <h3>{{ Profile.HelpCount }}</h3>
          </div>
          تعداد کمک ها
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
export default {
  data() {
    return {
      picture: null,
      UserPicture: null,
      isImageChoosed: false,
    };
  },
  components: {
    "app-header": Header,
  },
  methods: {
    LogOut() {
      this.$store.dispatch("LogOut");
      this.$router.push("/");
    },
    onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      this.picture = event.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
      this.isImageChoosed = true;
    },
    createImage(file) {
      // const image = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        this.UserPicture = e.target.result;

        const image = new FormData();
        image.append("profileImage", this.picture, this.picture.name);
        this.$store.dispatch("ChangeProfile", image);
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    Profile() {
      return this.$store.getters.GetProfile;
    },
    GetBaseUserPictureAddress() {
      return this.$store.getters.GetBaseUserPictureAddress;
    },
    FullName() {
      return this.$store.getters.GetFullName;
    },
  },
  created() {
    if (!this.$store.getters.GetIsAuthenticated) this.$router.push("/404");
    this.$store.dispatch("SetFullName");
    this.$store.dispatch("GetProfile");
    this.UserPicture =
      this.GetBaseUserPictureAddress + this.Profile.UserPicture;
  },
  mounted() {
    if (!this.$store.getters.GetIsAuthenticated) this.$router.push("/404");
  },
  updated() {
    if (!this.$store.getters.GetIsAuthenticated) this.$router.push("/404");
  },
};
</script>

<style scoped>
@import url("./account.css");
.exit {
  cursor: pointer;
}
</style>
