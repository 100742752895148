<template>
  <div class="not-found-page">
    <img src="./not-found.svg" alt="صفحه ی 404" class="not-found-image" />
    <h4 id="not-found-title">صفحه 404</h4>
    <h6 id="not-found-desc">صفحه ی درخواست شده پیدا نشد!</h6>
    <router-link id="not-found-home" to="/">بازگشت به صفحه اصلی</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("404.css");
</style>
