<template>
  <div>
    <app-header></app-header>
    <div class="help-box card-box container">
      <img
        src="../../assets/images/fast-pay.svg"
        width="300"
        height="300"
        alt=""
      />
      <h3>پرداخت سریع</h3>
      <h4>
        از این قسمت میتوانید مبالغ مورد نظر را پرداخت کنید. ما این مبالغ
        <br />را برای نیاز های مهم اختصاص میدهیم و برای شما گزارش خواهیم فرستاد.
      </h4>
      <div class="pay-input">
        <div class="row">
          <div class="pay-btn-number col-1">
            <span @click="increase">+</span>
            <span @click="decrease">-</span>
          </div>
          <div class="col-9">
            <div class="input-pay form-control h-100">
              <div class="row h-100">
                <div class="col-11 h-100">
                  <input
                    type="number"
                    id="fast-pay-number"
                    placeholder="0"
                    value="10000"
                    v-model="payNumber"
                    @input="payNumber = payNumber.toLocaleString()"
                  />
                </div>
                <div class="col-1 h-100 currency">
                  <span>ریال</span>
                </div>
              </div>
            </div>
          </div>
          <button class="col-2">پرداخت</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "../Header.vue";
export default {
  data() {
    return {
      payNumber: 10000,
    };
  },
  components: {
    "app-header": AppHeader,
  },
  methods: {
    increase() {
      this.payNumber = parseInt(this.payNumber);
      this.payNumber += 10000;
    },
    decrease() {
      this.payNumber = parseInt(this.payNumber);
      this.payNumber -= 10000;
    },
  },
};
</script>

<style scoped>
@import url("./helpUs.css");
</style>
