<template>
  <div class="left side-menu">
    <div class="sidebar-inner slimscrollleft">
      <div class="user-box">
        <div class="user-img" v-if="Profile.UserPicture">
          <input
            type="file"
            ref="file"
            v-show="false"
            @change="onFileChange($event)"
          />
          <img
            :src="GetBaseUserPictureAddress + Profile.UserPicture"
            alt="user-img"
            :title="'پروفایل ' + FullName"
            id="img-profile"
            class="img-circle img-thumbnail img-responsive"
            @click="$refs.file.click()"
          />
          <h3>{{ FullName }}</h3>
        </div>
        <div class="user-img" v-else>
          <img
            src="../images/users/user.png"
            alt="user-img"
            :title="'پروفایل ' + FullName"
            class="img-circle img-thumbnail img-responsive"
          />
        </div>
        <i
          class="zmdi zmdi-sign-in"
          @click="LogOut()"
          title="خروج از حساب کاربری"
        ></i>
        <router-link
          active-class="active"
          to="/admin/changeInfo"
          class="zmdi zmdi-account"
          tag="i"
          title="تغییر مشخصات کاربری"
        >
        </router-link>
        <router-link
          active-class="active"
          to="/admin/changePassword"
          class="zmdi zmdi-key"
          tag="i"
          title="تغییر رمز عبور"
        >
        </router-link>
        <h3></h3>
      </div>
      <!-- End User -->

      <!--- Sidemenu -->
      <div id="sidebar-menu" v-slimscroll>
        <ul>
          <li v-if="!$store.getters.GetDashboardAuth">
            <router-link
              to="/admin"
              class="waves-effect"
              active-class="active"
              exact
              ><i class="zmdi zmdi-view-dashboard"> </i
              ><span>داشبورد</span></router-link
            >
          </li>
          <li
            class="has_sub"
            @click="OpenCloseSetMenu()"
            v-if="!$store.getters.GetBaseDefAuth"
          >
            <a
              href="javascript:void(0);"
              :class="'waves-effect' + (isSetMenuOpen ? ' subdrop' : '')"
              ><i
                :class="
                  'zmdi ' +
                  (isSetMenuOpen ? 'zmdi-plus' : 'zmdi-window-minimize')
                "
              ></i>
              <span>تعاریف پایه</span> <span class="menu-arrow"></span
            ></a>
            <ul
              class="list-unstyled"
              :style="isSetMenuOpen ? 'display:block' : 'display:none'"
            >
              <li v-if="!$store.getters.GetUserDefAuth">
                <router-link
                  to="/admin/usergroup"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-account"> </i
                  ><span>کاربران</span></router-link
                >
              </li>
              <li v-if="!$store.getters.GetAddressTypeDefAuth">
                <router-link
                  to="/admin/addresstype"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-home"> </i
                  ><span>وضعیت سکونت</span></router-link
                >
              </li>
              <li v-if="!$store.getters.GetMaritalTypeDefAuth">
                <router-link
                  to="/admin/maritaltype"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-male-alt"> </i
                  ><span>وضعیت تأهل</span></router-link
                >
              </li>
              <li v-if="!$store.getters.GetPhisicalTypeDefAuth">
                <router-link
                  to="/admin/phisicaltype"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-walk"> </i
                  ><span>وضعیت جسمانی</span></router-link
                >
              </li>
              <li v-if="!$store.getters.GetSickTypeDefAuth">
                <router-link
                  to="/admin/sicktype"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-accounts-list-alt"> </i
                  ><span>وضعیت بیماری</span></router-link
                >
              </li>
              <li v-if="!$store.getters.GetWeakTypeDefAuth">
                <router-link
                  to="/admin/weaktype"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-male"> </i
                  ><span>وضعیت معلولیت</span></router-link
                >
              </li>
            </ul>
          </li>
          <li
            class="has_sub"
            @click="OpenCloseNeedyMenu()"
            v-if="!$store.getters.GetNeedyAuth"
          >
            <a
              href="javascript:void(0);"
              :class="'waves-effect' + (isNeedyMenuOpen ? ' subdrop' : '')"
              ><i
                :class="
                  'zmdi ' +
                  (isNeedyMenuOpen ? 'zmdi-plus' : 'zmdi-window-minimize')
                "
              ></i>
              <span>نیازمندان</span> <span class="menu-arrow"></span
            ></a>
            <ul
              class="list-unstyled"
              :style="isNeedyMenuOpen ? 'display:block' : 'display:none'"
            >
              <li v-if="!$store.getters.GetNeedyAuth">
                <router-link
                  to="/admin/needy"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-accounts"> </i
                  ><span>نیازمندان</span></router-link
                >
              </li>
              <li v-if="!$store.getters.GetRelationShipTypeAuth">
                <router-link
                  to="/admin/relationshiptype"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-accounts-alt"> </i
                  ><span>نسبت خانوادگی</span></router-link
                >
              </li>
            </ul>
          </li>
          <li
            class="has_sub"
            @click="OpenCloseAidMenu()"
            v-if="!$store.getters.GetAidAuth"
          >
            <a
              href="javascript:void(0);"
              :class="'waves-effect' + (isAidMenuOpen ? ' subdrop' : '')"
              ><i
                :class="
                  'zmdi ' +
                  (isAidMenuOpen ? 'zmdi-plus' : 'zmdi-window-minimize')
                "
              ></i>
              <span>بسته های حمایتی</span> <span class="menu-arrow"></span
            ></a>
            <ul
              class="list-unstyled"
              :style="isAidMenuOpen ? 'display:block' : 'display:none'"
            >
              <li v-if="!$store.getters.GetObjectAuth">
                <router-link
                  to="/admin/object"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-seat"> </i
                  ><span>کالاهای حمایتی</span></router-link
                >
              </li>
              <li v-if="!$store.getters.GetAidAuth">
                <router-link
                  to="/admin/aid"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-dropbox"> </i
                  ><span>بسته های حمایتی</span></router-link
                >
              </li>
            </ul>
          </li>
          <li
            class="has_sub"
            v-if="!$store.getters.GetPostsAuth"
            @click="OpenCloseBlogMenu()"
          >
            <a
              href="javascript:void(0);"
              :class="'waves-effect' + (isBlogMenuOpen ? ' subdrop' : '')"
              ><i
                :class="
                  'zmdi ' +
                  (isBlogMenuOpen ? 'zmdi-plus' : 'zmdi-window-minimize')
                "
              ></i>
              <span>اخبار</span> <span class="menu-arrow"></span
            ></a>
            <ul
              class="list-unstyled"
              :style="isBlogMenuOpen ? 'display:block' : 'display:none'"
            >
              <li v-if="!$store.getters.GetPostCategoryAuth">
                <router-link
                  to="/admin/postCategory"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-collection-item"> </i
                  ><span>گروه خبر</span></router-link
                >
              </li>
              <li v-if="!$store.getters.GetPostAuth">
                <router-link
                  to="/admin/post"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-collection-text"> </i
                  ><span>خبر</span></router-link
                >
              </li>
            </ul>
          </li>
          <li
            class="has_sub mb-5"
            v-if="!$store.getters.GetPageManagement"
            @click="OpenCloseManagePagedMenu()"
          >
            <a
              href="javascript:void(0);"
              :class="'waves-effect' + (isManagePageOpen ? ' subdrop' : '')"
              ><i
                :class="
                  'zmdi ' +
                  (isManagePageOpen ? 'zmdi-plus' : 'zmdi-window-minimize')
                "
              ></i>
              <span>مدیریت صفحات</span> <span class="menu-arrow"></span
            ></a>
            <ul
              class="list-unstyled"
              :style="isManagePageOpen ? 'display:block' : 'display:none'"
            >
              <li v-if="!$store.getters.GetAboutUsAuth">
                <router-link
                  to="/admin/aboutUs"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-file-text"> </i
                  ><span>درباره ما</span></router-link
                >
              </li>
              <li v-if="!$store.getters.GetContactUsAuth">
                <router-link
                  to="/admin/contactUs"
                  class="waves-effect"
                  active-class="active"
                  ><i class="zmdi zmdi-phone"> </i
                  ><span>ارتباط با ما</span></router-link
                >
              </li>
            </ul>
          </li>
        </ul>

        <div class="clearfix"></div>
      </div>
      <!-- Sidebar -->
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      picture: null,
      UserPicture: null,
      isImageChoosed: false,
    };
  },
  methods: {
    OpenCloseSetMenu() {
      this.$store.dispatch("OpenCloseSetMenu");
    },
    OpenCloseBlogMenu() {
      this.$store.dispatch("OpenCloseBlogMenu");
    },
    OpenCloseNeedyMenu() {
      this.$store.dispatch("OpenCloseNeedyMenu");
    },
    OpenCloseAidMenu() {
      this.$store.dispatch("OpenCloseAidMenu");
    },
    OpenCloseManagePagedMenu() {
      this.$store.dispatch("OpenCloseManagePagesMenu");
    },
    LogOut() {
      this.$store.dispatch("LogOut");
      this.$router.push("/");
    },
    onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      this.picture = event.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
      this.isImageChoosed = true;
    },
    createImage(file) {
      // const image = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        this.UserPicture = e.target.result;

        const image = new FormData();
        image.append("profileImage", this.picture, this.picture.name);
        this.$store.dispatch("ChangeProfile", image);
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    FullName() {
      return this.$store.getters.GetFullName;
    },
    Profile() {
      return this.$store.getters.GetProfile;
    },
    GetBaseUserPictureAddress() {
      return this.$store.getters.GetBaseUserPictureAddress;
    },
    showModal() {
      return this.$store.getters.GetShowModal;
    },
    isSetMenuOpen() {
      return this.$store.getters.GetSetMenuOpen;
    },
    isBlogMenuOpen() {
      return this.$store.getters.GetBlogMenuOpen;
    },
    isNeedyMenuOpen() {
      return this.$store.getters.GetNeedyMenuOpen;
    },
    isAidMenuOpen() {
      return this.$store.getters.GetAidMenuOpen;
    },
    isManagePageOpen() {
      return this.$store.getters.GetManagePageMenuOpen;
    },
  },
  created() {
    this.$store.dispatch("GetProfile");
    this.$store.dispatch("AuthDashboard", false);
    this.$store.dispatch("AuthBaseDef", false);
    this.$store.dispatch("AuthUserDef", false);
    this.$store.dispatch("AuthNeedy", false);
    this.$store.dispatch("AuthObject", false);
    this.$store.dispatch("AuthAid", false);
    this.$store.dispatch("AuthAdressTypeDef", false);
    this.$store.dispatch("AuthMaritalTypeDef", false);
    this.$store.dispatch("AuthPhisicalTypeDef", false);
    this.$store.dispatch("AuthSickTypeDef", false);
    this.$store.dispatch("AuthWeakTypeDef", false);
    this.$store.dispatch("AuthRelationShipType", false);
    this.$store.dispatch("AuthPosts", false);
    this.$store.dispatch("AuthPostCategory", false);
    this.$store.dispatch("AuthPost", false);
    this.$store.dispatch("AuthPageManagement", false);
    this.$store.dispatch("AuthAboutUs", false);
    this.$store.dispatch("AuthContactUs", false);
  },
};
</script>

<style scoped>
#img-profile {
  cursor: pointer;
}
</style>
