<template>
  <div>
    <div class="card-box container mt-4">
      <div class="change-info-titles">
        <h3 class="mb-3 float-start">ویرایش مشخصات کاربری</h3>
        <input
          type="file"
          ref="file"
          v-show="false"
          @change="onFileChange($event)"
        />
        <span class="float-end my-4" @click="$refs.file.click()"
          >آپلود عکس پروفایل</span
        >
      </div>
      <input
        type="text"
        class="form-control mb-2"
        placeholder="نام کاربری"
        v-model="UserName"
      />
      <input
        type="text"
        class="form-control mb-2"
        placeholder="نام و نام خانوادگی"
        v-model="FullName"
      />
      <input
        type="email"
        class="form-control mb-2"
        placeholder="ایمیل"
        v-model="Email"
      />
      <input
        type="number"
        class="form-control mb-2"
        placeholder="شماره موبایل"
        v-model="Phone"
      />
      <button class="btn btn-warning m-1 float-end" @click="EditInfo()">
        ویرایش
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    EditInfo() {
      const ChangeInfoData = {
        UID: this.ChangeInfoData.UID,
        UserName: this.UserName,
        FullName: this.FullName,
        Email: this.Email,
        Phone: this.Phone,
      };
      this.$store.dispatch("ChangeInfo", ChangeInfoData);
    },
    onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      this.picture = event.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
      this.isImageChoosed = true;
    },
    createImage(file) {
      // const image = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        this.UserPicture = e.target.result;

        const image = new FormData();
        image.append("profileImage", this.picture, this.picture.name);
        this.$store.dispatch("ChangeProfile", image);
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    ChangeInfoData() {
      return this.$store.getters.GetChangeInfoData;
    },
    UserName: {
      get() {
        return this.ChangeInfoData.UserName;
      },
      set(value) {
        this.ChangeInfoData.UserName = value;
      },
    },
    FullName: {
      get() {
        return this.ChangeInfoData.FullName;
      },
      set(value) {
        this.ChangeInfoData.FullName = value;
      },
    },
    Email: {
      get() {
        return this.ChangeInfoData.Email;
      },
      set(value) {
        this.ChangeInfoData.Email = value;
      },
    },
    Phone: {
      get() {
        return this.ChangeInfoData.Phone;
      },
      set(value) {
        this.ChangeInfoData.Phone = value;
      },
    },
  },
  created() {
    this.$store.dispatch("GetChangeInfoData");
  },
};
</script>

<style scoped>
.change-info-titles span {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 8px;
  color: #ffffff;
  background-color: #29b1ff;
}
</style>
