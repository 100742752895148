import Vue from "vue";

const state = {
  dashboardAuth: false,
  baseDefAuth: false,
  userDefAuth: false,
  needyAuth: false,
  needyChildAuth: false,
  helpRecordAuth: false,
  objectAuth: false,
  aidAuth: false,
  aidDetailAuth: false,
  addressTypeDefAuth: false,
  maritalTypeDefAuth: false,
  phisicalTypeDefAuth: false,
  sickTypeDefAuth: false,
  weakTypeDefAuth: false,
  relationShipTypeAuth: false,
  postsAuth: false,
  postCategoryAuth: false,
  postAuth: false,
  pageManagementAuth: false,
  aboutUsAuth: false,
  contactUsAuth: false,
};

const getters = {
  GetDashboardAuth(state) {
    return state.dashboardAuth;
  },
  GetBaseDefAuth(state) {
    return state.baseDefAuth;
  },
  GetUserDefAuth(state) {
    return state.userDefAuth;
  },
  GetNeedyAuth(state) {
    return state.needyAuth;
  },
  GetNeedyChildAuth(state) {
    return state.needyChildAuth;
  },
  GetHelpRecordAuth(state) {
    return state.helpRecordAuth;
  },
  GetObjectAuth(state) {
    return state.objectAuth;
  },
  GetAidAuth(state) {
    return state.aidAuth;
  },
  GetAidDetailAuth(state) {
    return state.aidDetailAuth;
  },
  GetAddressTypeDefAuth(state) {
    return state.addressTypeDefAuth;
  },
  GetMaritalTypeDefAuth(state) {
    return state.maritalTypeDefAuth;
  },
  GetPhisicalTypeDefAuth(state) {
    return state.phisicalTypeDefAuth;
  },
  GetSickTypeDefAuth(state) {
    return state.sickTypeDefAuth;
  },
  GetWeakTypeDefAuth(state) {
    return state.weakTypeDefAuth;
  },
  GetRelationShipTypeAuth(state) {
    return state.relationShipTypeAuth;
  },
  GetPostsAuth(state) {
    return state.postsAuth;
  },
  GetPostCategoryAuth(state) {
    return state.postCategoryAuth;
  },
  GetPostAuth(state) {
    return state.postAuth;
  },
  GetPageManagement(state) {
    return state.pageManagementAuth;
  },
  GetAboutUsAuth(state) {
    return state.aboutUsAuth;
  },
  GetContactUsAuth(state) {
    return state.contactUsAuth;
  },
};

const mutations = {
  setDashboardAuth(state, dashboardAuth) {
    state.dashboardAuth = dashboardAuth;
  },
  setBaseDefAuth(state, baseDefAuth) {
    state.baseDefAuth = baseDefAuth;
  },
  setUserDefAuth(state, userDefAuth) {
    state.userDefAuth = userDefAuth;
  },
  setNeedyAuth(state, needyAuth) {
    state.needyAuth = needyAuth;
  },
  setNeedyChildAuth(state, needyChildAuth) {
    state.needyChildAuth = needyChildAuth;
  },
  setHelpRecordAuth(state, helpRecordAuth) {
    state.helpRecordAuth = helpRecordAuth;
  },
  setObjectAuth(state, objectAuth) {
    state.objectAuth = objectAuth;
  },
  setAidAuth(state, aidAuth) {
    state.aidAuth = aidAuth;
  },
  setAidDetailAuth(state, aidDetailAuth) {
    state.aidDetailAuth = aidDetailAuth;
  },
  setAddressTypeDefAuth(state, addressTypeDefAuth) {
    state.addressTypeDefAuth = addressTypeDefAuth;
  },
  setMaritalTypeDefAuth(state, maritalTypeDefAuth) {
    state.maritalTypeDefAuth = maritalTypeDefAuth;
  },
  setPhisicalTypeDefAuth(state, phisicalTypeDefAuth) {
    state.phisicalTypeDefAuth = phisicalTypeDefAuth;
  },
  setSickTypeDefAuth(state, sickTypeDefAuth) {
    state.sickTypeDefAuth = sickTypeDefAuth;
  },
  setWeakTypeDefAuth(state, weakTypeDefAuth) {
    state.weakTypeDefAuth = weakTypeDefAuth;
  },
  setRelationShipTypeAuth(state, relationShipTypeAuth) {
    state.relationShipTypeAuth = relationShipTypeAuth;
  },
  setPostsAuth(state, postsAuth) {
    state.postsAuth = postsAuth;
  },
  setPostCategoryAuth(state, postCategoryAuth) {
    state.postCategoryAuth = postCategoryAuth;
  },
  setPostAuth(state, postAuth) {
    state.postAuth = postAuth;
  },
  setPageManagementAuth(state, pageManagementAuth) {
    state.pageManagementAuth = pageManagementAuth;
  },
  setAboutUsAuth(state, aboutUsAuth) {
    state.aboutUsAuth = aboutUsAuth;
  },
  setContactUsAuth(state, contactUsAuth) {
    state.contactUsAuth = contactUsAuth;
  },
};

const actions = {
  AuthDashboard(context, isInPage) {
    Vue.http.get("authDashboard").catch((err) => {
      if (err.status == 401) {
        context.commit("setDashboardAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthBaseDef(context, isInPage) {
    Vue.http.get("authBaseDef").catch((err) => {
      if (err.status == 401) {
        context.commit("setBaseDefAuth", true);

        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthUserDef(context, isInPage) {
    Vue.http.get("authUserDef").catch((err) => {
      if (err.status == 401) {
        context.commit("setUserDefAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthNeedy(context, isInPage) {
    Vue.http.get("authNeedy").catch((err) => {
      if (err.status == 401) {
        context.commit("setNeedyAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthNeedyChild(context, isInPage) {
    Vue.http.get("authNeedyChild").catch((err) => {
      if (err.status == 401) {
        context.commit("setNeedyChildAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthHelpRecord(context, isInPage) {
    Vue.http.get("authHelpRecord").catch((err) => {
      if (err.status == 401) {
        context.commit("setHelpRecordAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthObject(context, isInPage) {
    Vue.http.get("authObject").catch((err) => {
      if (err.status == 401) {
        context.commit("setObjectAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthAid(context, isInPage) {
    Vue.http.get("authAid").catch((err) => {
      if (err.status == 401) {
        context.commit("setAidAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthAidDetail(context, isInPage) {
    Vue.http.get("authAidDetail").catch((err) => {
      if (err.status == 401) {
        context.commit("setAidDetailAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthAdressTypeDef(context, isInPage) {
    Vue.http.get("authAddressTypeDef").catch((err) => {
      if (err.status == 401) {
        context.commit("setAddressTypeDefAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthMaritalTypeDef(context, isInPage) {
    Vue.http.get("authMaritalTypeDef").catch((err) => {
      if (err.status == 401) {
        context.commit("setMaritalTypeDefAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthPhisicalTypeDef(context, isInPage) {
    Vue.http.get("authPhisicalTypeDef").catch((err) => {
      if (err.status == 401) {
        context.commit("setPhisicalTypeDefAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthSickTypeDef(context, isInPage) {
    Vue.http.get("authSickTypeDef").catch((err) => {
      if (err.status == 401) {
        context.commit("setSickTypeDefAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthWeakTypeDef(context, isInPage) {
    Vue.http.get("authWeakTypeDef").catch((err) => {
      if (err.status == 401) {
        context.commit("setWeakTypeDefAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthRelationShipType(context, isInPage) {
    Vue.http.get("authRelationType").catch((err) => {
      if (err.status == 401) {
        context.commit("setRelationShipTypeAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthPosts(context, isInPage) {
    Vue.http.get("authPosts").catch((err) => {
      if (err.status == 401) {
        context.commit("setPostsAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthPostCategory(context, isInPage) {
    Vue.http.get("authPostCategory").catch((err) => {
      if (err.status == 401) {
        context.commit("setPostCategoryAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthPost(context, isInPage) {
    Vue.http.get("authPost").catch((err) => {
      if (err.status == 401) {
        context.commit("setPostAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthPageManagement(context, isInPage) {
    Vue.http.get("authPageManagement").catch((err) => {
      if (err.status == 401) {
        context.commit("setPageManagementAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthAboutUs(context, isInPage) {
    Vue.http.get("authAboutUs").catch((err) => {
      if (err.status == 401) {
        context.commit("setAboutUsAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
  AuthContactUs(context, isInPage) {
    Vue.http.get("authContactUs").catch((err) => {
      if (err.status == 401) {
        context.commit("setContactUsAuth", true);
        if (isInPage) {
          context.commit("setShowModal", true);
        } else {
          context.commit("setShowModal", false);
        }
      }
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
