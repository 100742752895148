<template>
  <div class="register-tab">
    <div class="form">
      <input
        type="text"
        class="form-control"
        placeholder="نام کاربری"
        v-model="UserName"
      />
      <input
        type="password"
        class="form-control"
        placeholder="رمز عبور"
        v-model="password"
      />
      <input
        type="text"
        class="form-control"
        placeholder="نام و نام خانوادگی"
        v-model="FullName"
      />
      <input
        type="email"
        class="form-control"
        placeholder="ایمیل"
        v-model="Email"
      />
      <input
        type="phone"
        class="form-control"
        placeholder="شماره موبایل"
        v-model="Phone"
      />
      <button @click="register()">ثبت نام</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      UserName: "",
      password: "",
      FullName: "",
      Email: "",
      Phone: "",
    };
  },
  methods: {
    register() {
      const registerUserData = {
        UserName: this.UserName,
        Password: this.password,
        FullName: this.FullName,
        Email: this.Email,
        Phone: this.Phone,
      };
      this.$store.dispatch("Register", registerUserData);
    },
  },
};
</script>

<style scoped>
@import url("./register.css");
</style>
