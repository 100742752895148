import Vue from "vue";
import VuePersianDateTimePicker from "vue-persian-datetime-picker";

Vue.use(VuePersianDateTimePicker, {
  name: "date-picker",
  props: {
    inputFormat: "YYYY-MM-DD",
    format: "jYYYY/jMM/jDD",
    editable: false,
    inputClass: "form-control",
    placeholder: "لطفا تاریخ کمک را انتخاب کنید",
    altFormat: "YYYY/MM/DD",
    color: "#29b1ff",
    autoSubmit: false,
  },
});
