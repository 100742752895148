import Account from "../views/account/Account.vue";
import ChangeInfo from "../views/account/components/ChangeInfo/ChangeInfo.vue";
import ChangePassword from "../views/account/components/ChangePassword/ChangePassword.vue";

export const accountRoute = {
  path: "/profile",
  component: Account,
};

export const changeInfoRoute = {
  path: "/profile/changeInfo/",
  component: ChangeInfo,
};

export const changePasswordRoute = {
  path: "/profile/changePassword/",
  component: ChangePassword,
};
