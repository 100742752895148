<template>
  <div>
    <h3 class="mt-3">حمایت ها</h3>
    <router-link
      :to="'/admin/addHelpRecord/' + $route.params.id"
      class="btn btn-success mt-4 mb-2"
      >افزودن حمایت جدید</router-link
    >
    <router-link to="/admin/needy" class="btn btn-secondary mt-4 mx-1 mb-2"
      >بازگشت</router-link
    >
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>نام نیازمند</th>
        <th>عنوان بسته حمایتی</th>
        <th>مبلغ</th>
        <th>تاریخ کمک</th>
        <th>تاریخ ثبت</th>
        <th>فرد ثبت کننده</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr v-for="helpRecord in HelpRecords" :key="helpRecord.HelpId">
          <td>{{ helpRecord.NeedyFullName }}</td>
          <td>{{ helpRecord.AidName }}</td>
          <td>{{ helpRecord.CostMoney }}</td>
          <td>{{ helpRecord.HelpDate | moment("YYYY/MM/DD") }}</td>
          <td>{{ helpRecord.createdAt | moment("jYYYY/jMM/jDD") }}</td>
          <td>{{ helpRecord.FullName }}</td>
          <td>
            <i
              class="zmdi zmdi-delete text-danger"
              @click="deleteHelpRecord(helpRecord.HelpId)"
            ></i>
          </td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
export default {
  components: { DataTable },
  methods: {
    deleteHelpRecord(id) {
      this.$store.dispatch("DeleteHelpRecord", {
        id,
        nid: this.$route.params.id,
      });
    },
  },
  computed: {
    HelpRecords() {
      return this.$store.getters.GetAllHelpRecords.helpRecords;
    },
  },
  created() {
    this.$store.dispatch("GetAllHelpRecords", this.$route.params.id);
    this.$store.dispatch("AuthHelpRecord", true);
  },
  updated() {
    this.$store.dispatch("AuthHelpRecord", true);
  },
};
</script>
