import Vue from "vue";

import { router } from "../../router/vueRouter";

const state = {
  objects: [],
  object: {},
};

const getters = {
  getAllObjects(state) {
    return state.objects;
  },
  GetObject(state) {
    return state.object;
  },
};

const mutations = {
  setObjects(state, objects) {
    state.objects = objects;
  },
  setObject(state, object) {
    state.object = object;
  },
};

const actions = {
  GetObjectsResponse(context) {
    Vue.http
      .get("dashboard/object")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setObjects", data);
      })
      .catch((err) => console.log(err));
  },
  GetObjectById(context, id) {
    Vue.http
      .get("dashboard/object/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setObject", data);
      })
      .catch((err) => console.log(err));
  },
  AddObject(context, objectData) {
    Vue.http
      .post("dashboard/object", objectData)
      .then(() => {
        router.push("/admin/object");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  EditObject(context, objectData) {
    Vue.http
      .put("dashboard/object", objectData)
      .then(() => {
        router.push("/admin/object");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  DeleteObject(context, deleteObjectData) {
    if (confirm(`آیا از حذف کالا ${deleteObjectData.name} مطمئن هستید؟`)) {
      Vue.http
        .delete("dashboard/object/" + deleteObjectData.id)
        .then(() => {
          context.dispatch("GetObjectsResponse");
        })
        .catch((err) => {
          if (err.status == 422) {
            alert(err.body.message);
          }
        });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
