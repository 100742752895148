import Vue from "vue";

import App from "./App.vue";
import "./assets/bootstrapvue";
import "./assets/fonts/font-awesome/css/all.css";
import "./assets/fonts/fonts.css";
import "./assets/moment";
import "./assets/vuescroll";
import "./assets/vueKnob";
import { router } from "./router/vueRouter";
import "./resource/vueResource";
import "./assets/cookie";
import "./assets/datapicker";
import "./assets/ckeditor";
import { store } from "./store/store";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
