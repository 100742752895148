<template>
  <transition name="fade">
    <div class="modal-mask" v-if="showModal">
      <div class="modal-wrapper">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-center">
                {{ title }}
              </h4>
            </div>
            <div class="modal-body">
              <slot name="modalBody"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },
};
</script>
