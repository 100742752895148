import Vue from "vue";

import { router } from "../../router/vueRouter";

const state = {
  helpRecords: [],
};

const getters = {
  GetAllHelpRecords(state) {
    return state.helpRecords;
  },
};

const mutations = {
  setHelpRecords(state, helpRecords) {
    state.helpRecords = helpRecords;
  },
};

const actions = {
  GetAllHelpRecords(context, id) {
    Vue.http
      .get("dashboard/helpRecord/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setHelpRecords", data);
      })
      .catch((err) => console.log(err));
  },
  AddHelpRecord(context, helpRecordData) {
    Vue.http
      .post("dashboard/helpRecord", helpRecordData)
      .then(() => {
        router.push("/admin/helpRecord/" + helpRecordData.NeedyId);
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  DeleteHelpRecord(context, deleteData) {
    if (confirm("آیا از حذف این حمایت مطمئن هستید؟")) {
      Vue.http
        .delete("dashboard/helpRecord/" + deleteData.id)
        .then(() => {
          context.dispatch("GetAllHelpRecords", deleteData.nid);
        })
        .catch((err) => {
          if (err.status == 422) {
            alert(err.body.message);
          }
        });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
