import NotFound from '../views/404/404.vue';

export const NotFoundRoute = {
    path: '/404' , 
    component: NotFound
};

export const NotFoundRedirect = {
    path: '*' ,
    redirect: '/404'
}