<template>
  <div>
    <div class="card-box container mt-4">
      <h3 class="mb-3">ویرایش رمز عبور</h3>
      <input
        type="password"
        class="form-control mb-2"
        placeholder="رمز عبور فعلی"
        v-model="OldPassword"
      />
      <input
        type="password"
        class="form-control mb-2"
        placeholder="رمز عبور جدید"
        v-model="NewPassword"
      />
      <input
        type="password"
        class="form-control mb-2"
        placeholder="تکرار رمز عبور جدید"
        v-model="ConfirmNewPassword"
      />
      <button class="btn btn-warning m-1 float-end" @click="EditPassword()">
        ویرایش
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      OldPassword: "",
      NewPassword: "",
      ConfirmNewPassword: "",
    };
  },
  methods: {
    EditPassword() {
      const ChangePasswordData = {
        UID: this.$route.params.id,
        OldPassword: this.OldPassword,
        NewPassword: this.NewPassword,
        ConfirmNewPassword: this.ConfirmNewPassword,
      };
      this.$store.dispatch("ChangePassword", ChangePasswordData);
    },
  },
};
</script>
