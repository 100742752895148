import Vue from "vue";
import { router } from "../../router/vueRouter";

const state = {
  aids: [],
  gotAid: {},
  BaseAidImageAddress: "https://api.labkhandmahdavi.ir/aids/",
  // BaseAidImageAddress: "http://localhost:3000/aids/",
  UploadedAidImageName: "",
  oldAidImage: "",
};

const getters = {
  GetAllAids(state) {
    return state.aids;
  },
  GetAid(state) {
    return state.gotAid;
  },
  GetOldAidImage(state) {
    return state.oldImage;
  },
  GetBaseAidImageAddress(state) {
    return state.BaseAidImageAddress;
  },
  GetUploadedAidImageName(state) {
    return state.UploadedAidImageName;
  },
};

const mutations = {
  setAids(state, aids) {
    state.aids = aids;
  },
  setAid(state, gotAid) {
    state.gotAid = gotAid;
  },
  setUploadedAidImageName(state, UploadedAidImageName) {
    state.UploadedAidImageName = UploadedAidImageName;
  },
  setAidOldImage(state, oldImage) {
    state.oldImage = oldImage;
  },
};

const actions = {
  GetAllAidsResponse(context) {
    Vue.http
      .get("dashboard/aid")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setAids", data);
      })
      .catch((err) => console.log(err));
  },
  GetAidById(context, id) {
    Vue.http
      .get("dashboard/aid/" + id)
      .then((response) => {
        context.commit("setAid", response.body);
        context.commit("setAidOldImage", response.body.AidImage);
      })
      .catch((err) => console.log(err));
  },
  AddAid(context, aidData) {
    Vue.http
      .post("dashboard/aid", aidData)
      .then(() => {
        router.push("/admin/aid");
        context.dispatch("GetAllAidsResponse");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  EditAid(context, aidData) {
    Vue.http
      .put("dashboard/aid", aidData)
      .then(() => {
        context.dispatch("GetAllAidsResponse");
        router.push("/admin/aid");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  setStatus(context, statusData) {
    let message = "";
    if (statusData.status == true) {
      message = "آیا از غیرفعالسازی این وضعیت مطمئن هستید؟";
    } else {
      message = "آیا از فعالسازی این وضعیت مطمئن هستید؟";
    }
    if (confirm(message)) {
      Vue.http
        .delete("dashboard/aid/" + statusData.id)
        .then(() => {
          context.dispatch("GetAllAidsResponse");
        })
        .catch((err) => console.log(err));
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
