<template>
  <div>
    <app-header></app-header>
    <div class="container">
      <div class="searchbar">
        <input
          type="text"
          id="input-search-post"
          placeholder="دنبال چه خبری هستید؟"
          class="form-control"
          aria-label="جست و جو"
          v-model="searchText"
        />
      </div>
      <div class="posts-list">
        <div class="row">
          <div
            class="col-12 col-sm-6 col-md-4 p-2"
            v-for="post in PostsItems"
            :key="post.PostID"
          >
            <div class="post card-box">
              <img
                :src="PostImageLink + post.PostImage"
                class="img-fluid post-image w-100 m-2"
                alt=""
              />
              <div class="post-titles">
                <h4 class="post-title text-center">{{ post.PostTitle }}</h4>
                <h6 class="post-short-desc text-center">
                  {{
                    post.PostShortDesc.length > 80
                      ? post.PostShortDesc.substring(0, 80) + "..."
                      : post.PostShortDesc
                  }}
                </h6>
              </div>
              <div class="post-continue-btn">
                <router-link :to="'/blog/blogDetail/' + post.PostID"
                  >ادامه مطلب</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="d-flex justify-content-center">
      <ul class="pagination">
        <li
          v-for="pageNumber in Posts.totalPages"
          :key="pageNumber"
          :class="
            pageNumber == Posts.currentPage ? 'page-item active' : 'page-item'
          "
        >
          <router-link class="page-link" :to="'?page=' + pageNumber">{{
            pageNumber
          }}</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import AppHeader from "../Header.vue";
export default {
  components: {
    "app-header": AppHeader,
  },
  data() {
    return {
      searchText: "",
    };
  },
  watch: {
    $route() {
      this.$store.dispatch("GetAllPostsPage", {
        page: this.$route.query.page,
        search: this.searchText,
      });
    },
    searchText() {
      this.$store.dispatch("GetAllPostsPage", {
        page: this.$route.query.page >= 1 ? 1 : this.$route.query.page,
        search: this.searchText,
      });
    },
  },
  computed: {
    Posts() {
      return this.$store.getters.GetAllPostsPage;
    },
    PostsItems() {
      if (this.searchText) {
        return this.Posts.posts.filter((post) => {
          return this.searchText
            .toLowerCase()
            .split(" ")
            .every((v) => post.PostTitle.toLowerCase().includes(v));
        });
      } else {
        return this.Posts.posts;
      }
    },
    PostImageLink() {
      return this.$store.getters.GetBasePostImageAddress;
    },
  },
  created() {
    this.$store.dispatch("GetAllPostsPage", {
      page: this.$route.query.page,
      search: "",
    });
  },
};
</script>

<style scoped>
@import url("./blog.css");
</style>
