<template>
  <div>
    <h3 class="mt-3">ویرایش نیازمند</h3>
    <input
      type="text"
      class="form-control mb-3"
      placeholder="نام"
      v-model="Name"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="نام خانوادگی"
      v-model="Family"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="کد ملی"
      v-model="NCode"
    />
    <select
      class="form-select mb-3"
      v-model="AddressTypeSelected"
      @change="AddressTypeID = AddressTypeSelected"
    >
      <option :value="!isAddressType ? AddressTypeSelected : '0'">
        لطفا محل سکونت را انتخاب کنید...
      </option>
      <option
        v-for="addressType in AddressTypes"
        :key="addressType.AddressTypeId"
        :value="addressType.AddressTypeId"
      >
        {{ addressType.AddressTypeName }}
      </option>
    </select>
    <select
      class="form-select mb-3"
      v-model="MaritalTypeSelected"
      @change="MaritalTypeID = MaritalTypeSelected"
    >
      <option :value="!isMaritalType ? MaritalTypeSelected : '0'">
        لطفا وضعیت تأهل را وارد کنید...
      </option>
      <option
        v-for="maritalType in MaritalTypes"
        :key="maritalType.MaritalTypeId"
        :value="maritalType.MaritalTypeId"
      >
        {{ maritalType.MaritalTypeName }}
      </option>
    </select>
    <select
      class="form-select mb-3"
      v-model="PhisicalTypeSelected"
      @change="PhisicalTypeID = PhisicalTypeSelected"
    >
      <option :value="!isPhisicalType ? PhisicalTypeSelected : '0'">
        لطفا وضعیت جسمانی را انتخاب کنید...
      </option>
      <option
        v-for="phisicalType in PhisicalTypes"
        :key="phisicalType.PhisicalTypeId"
        :value="phisicalType.PhisicalTypeId"
      >
        {{ phisicalType.PhisicalTypeName }}
      </option>
    </select>
    <select
      class="form-select mb-3"
      v-model="SickTypeSelected"
      @change="SickTypeID = SickTypeSelected"
    >
      <option :value="!isSickType ? SickTypeSelected : '0'">
        لطفا وضعیت بیماری را انتخاب کنید...
      </option>
      <option
        v-for="sickType in SickTypes"
        :key="sickType.SickTypeId"
        :value="sickType.SickTypeId"
      >
        {{ sickType.SickTypeName }}
      </option>
    </select>
    <select
      class="form-select mb-3"
      v-model="WeakTypeSelected"
      @change="WeakTypeID = WeakTypeSelected"
    >
      <option :value="!isWeakType ? WeakTypeSelected : '0'">
        لطفا وضعیت معلولیت را انتخاب کنید...
      </option>
      <option
        v-for="weakType in WeakTypes"
        :key="weakType.WeakTypeId"
        :value="weakType.WeakTypeId"
      >
        {{ weakType.WeakTypeName }}
      </option>
    </select>
    <input
      type="text"
      class="form-control mb-3"
      placeholder="شماره تلفن"
      v-model="Tel"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="شماره موبایل"
      v-model="Mobile"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="آدرس"
      v-model="Address"
    />

    <button class="btn btn-warning m-1 float-end" @click="EditNeedy()">
      ویرایش نیازمند
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      AddressType: {
        AddressTypeName: "",
      },
      MaritalType: {
        MaritalName: "",
      },
      PhisicalType: {
        PhisicalTypeName: "",
      },
      SickType: {
        SickTypeName: "",
      },
      WeakType: {
        WeakTypeName: "",
      },
    };
  },
  methods: {
    addAddressType() {
      this.$http
        .post("AddressTypeAdmin", this.AddressType)
        .then((response) => {
          if (response.data == "INVALID_ADDRESS_TYPE") {
            return "INVALID ADDRESS TYPE";
          } else {
            this.$refs["add-addresstype-modal"].hide();
            this.$store.dispatch("GetAddressTypesResponse");
            this.AddressType.AddressTypeName = "";
          }
        })
        .catch((err) => console.log(err));
    },
    addMaritalType() {
      this.$http
        .post("MaritalTypeAdmin", this.MaritalType)
        .then((response) => {
          if (response.data == "INVALID_MARITAL_TYPE") {
            return "INVALID MARITAL TYPE";
          } else {
            this.$refs["add-maritaltype-modal"].hide();
            this.$store.dispatch("GetMaritalTypesResponse");
            this.MaritalType.MaritalName = "";
          }
        })
        .catch((err) => console.log(err));
    },
    addPhisicalType() {
      this.$http
        .post("PhisicalTypeAdmin", this.PhisicalType)
        .then((response) => {
          if (response.data == "INVALID_PHISICAL_TYPE") {
            return "INVALID PHISICAL TYPE";
          } else {
            this.$refs["add-phisicaltype-modal"].hide();
            this.$store.dispatch("GetPhisicalTypesResponse");
            this.PhisicalType.PhisicalTypeName = "";
          }
        })
        .catch((err) => console.log(err));
    },
    addSickType() {
      this.$http
        .post("SickTypeAdmin", this.SickType)
        .then((response) => {
          if (response.data == "INVALID_SICK_TYPE") {
            return "INVALID SICK TYPE";
          } else {
            this.$refs["add-sicktype-modal"].hide();
            this.$store.dispatch("GetSickTypesResponse");
            this.SickType.SickTypeName = "";
          }
        })
        .catch((err) => console.log(err));
    },
    addWeakType() {
      this.$http
        .post("WeakTypeAdmin", this.WeakType)
        .then((response) => {
          if (response.data == "INVALID_WEAK_TYPE") {
            return "INVALID WEAK TYPE";
          } else {
            this.$refs["add-weaktype-modal"].hide();
            this.$store.dispatch("GetWeakTypesResponse");
            this.WeakType.WeakTypeName = "";
          }
        })
        .catch((err) => console.log(err));
    },
    cancel() {
      this.$router.push("/admin/needy");
    },
    EditNeedy() {
      const needyData = {
        NeedyID: this.$route.params.id,
        Name: this.Name,
        Family: this.Family,
        NCode: this.NCode,
        AddressTypeId: this.AddressTypeID,
        MaritalTypeId: this.MaritalTypeID,
        PhisicalTypeId: this.PhisicalTypeID,
        SickTypeId: this.SickTypeID,
        WeakTypeId: this.WeakTypeID,
        Tel: this.Tel,
        Mobile: this.Mobile,
        Address: this.Address,
      };
      console.log(needyData.Name);
      this.$store.dispatch("EditNeedy", needyData);
    },
  },
  computed: {
    AddressTypes() {
      return this.$store.getters.getAllAddressTypes.addressTypes.filter(
        (addresstype) => addresstype.Status == true
      );
    },
    MaritalTypes() {
      return this.$store.getters.getAllMaritalTypes.maritalTypes.filter(
        (maritaltype) => maritaltype.Status == true
      );
    },
    PhisicalTypes() {
      return this.$store.getters.getAllPhisicalTypes.phisicalTypes.filter(
        (phisicaltype) => phisicaltype.Status == true
      );
    },
    SickTypes() {
      return this.$store.getters.getAllSickTypes.sickTypes.filter(
        (sicktype) => sicktype.Status == true
      );
    },
    WeakTypes() {
      return this.$store.getters.getAllWeakTypes.weakTypes.filter(
        (weaktype) => weaktype.Status == true
      );
    },
    isAddressType() {
      return this.$store.getters.getAllAddressTypes.addressTypes.find(
        (addresstype) => addresstype.AddressTypeId == this.AddressTypeSelected
      );
    },
    isMaritalType() {
      return this.$store.getters.getAllMaritalTypes.maritalTypes.find(
        (maritalstype) => maritalstype.MaritalTypeId == this.MaritalTypeSelected
      );
    },
    isPhisicalType() {
      return this.$store.getters.getAllPhisicalTypes.phisicalTypes.find(
        (phisicalstype) =>
          phisicalstype.PhisicalTypeId == this.PhisicalTypeSelected
      );
    },
    isSickType() {
      return this.$store.getters.getAllSickTypes.sickTypes.find(
        (sicktype) => sicktype.SickTypeId == this.SickTypeSelected
      );
    },
    isWeakType() {
      return this.$store.getters.getAllWeakTypes.weakTypes.find(
        (weaktype) => weaktype.WeakTypeId == this.WeakTypeSelected
      );
    },
    Needy() {
      return this.$store.getters.GetNeedy;
    },
    Name: {
      get() {
        return this.Needy.Name;
      },
      set(value) {
        this.Needy.Name = value;
      },
    },
    Family: {
      get() {
        return this.Needy.Family;
      },
      set(value) {
        this.Needy.Family = value;
      },
    },
    NCode: {
      get() {
        return this.Needy.NCode;
      },
      set(value) {
        this.Needy.NCode = value;
      },
    },
    AddressTypeID: {
      get() {
        return this.Needy.AddressTypeId;
      },
      set(value) {
        this.Needy.AddressTypeId = value;
      },
    },
    AddressTypeSelected: {
      get() {
        return this.Needy.AddressTypeId;
      },
      set(value) {
        this.Needy.AddressTypeId = value;
      },
    },
    MaritalTypeID: {
      get() {
        return this.Needy.MaritalTypeId;
      },
      set(value) {
        this.Needy.MaritalTypeId = value;
      },
    },
    MaritalTypeSelected: {
      get() {
        return this.Needy.MaritalTypeId;
      },
      set(value) {
        this.Needy.MaritalTypeId = value;
      },
    },
    PhisicalTypeID: {
      get() {
        return this.Needy.PhisicalTypeId;
      },
      set(value) {
        this.Needy.PhisicalTypeId = value;
      },
    },
    PhisicalTypeSelected: {
      get() {
        return this.Needy.PhisicalTypeId;
      },
      set(value) {
        this.Needy.PhisicalTypeId = value;
      },
    },
    SickTypeID: {
      get() {
        return this.Needy.SickTypeId;
      },
      set(value) {
        this.Needy.SickTypeId = value;
      },
    },
    SickTypeSelected: {
      get() {
        return this.Needy.SickTypeId;
      },
      set(value) {
        this.Needy.SickTypeId = value;
      },
    },
    WeakTypeID: {
      get() {
        return this.Needy.WeakTypeId;
      },
      set(value) {
        this.Needy.WeakTypeId = value;
      },
    },
    WeakTypeSelected: {
      get() {
        return this.Needy.WeakTypeId;
      },
      set(value) {
        this.Needy.WeakTypeId = value;
      },
    },
    Tel: {
      get() {
        return this.Needy.Tel;
      },
      set(value) {
        this.Needy.Tel = value;
      },
    },
    Mobile: {
      get() {
        return this.Needy.Mobile;
      },
      set(value) {
        this.Needy.Mobile = value;
      },
    },
    Address: {
      get() {
        return this.Needy.Address;
      },
      set(value) {
        this.Needy.Address = value;
      },
    },
  },
  created() {
    this.$store.dispatch("GetAddressTypesResponse");
    this.$store.dispatch("GetMaritalTypesResponse");
    this.$store.dispatch("GetPhisicalTypesResponse");
    this.$store.dispatch("GetSickTypesResponse");
    this.$store.dispatch("GetWeakTypesResponse");
    this.$store.dispatch("GetNeedyById", this.$route.params.id);
    this.$store.dispatch("AuthNeedy", true);
  },
  updated() {
    this.$store.dispatch("AuthNeedy", true);
  },
};
</script>
