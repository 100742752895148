import Vue from "vue";

// import { router } from "../../router/vueRouter";

const state = {
  posts: [],
  postDetail: {},
};

const getters = {
  GetAllPostsPage(state) {
    return state.posts;
  },
  GetPostDetail(state) {
    return state.postDetail;
  },
};

const mutations = {
  setAllPosts(state, posts) {
    state.posts = posts;
  },
  setPostDetail(state, postDetail) {
    state.postDetail = postDetail;
  },
};

const actions = {
  GetAllPostsPage(context, pageInfo) {
    Vue.http
      .get(
        `users/posts?page=${pageInfo.page}&&search=${
          pageInfo.search ? pageInfo.search : ""
        }`
      )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setAllPosts", data);
      });
  },
  GetPostDetail(context, id) {
    Vue.http
      .get("users/posts/postDetail/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setPostDetail", data);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
