<template>
  <div>
    <header class="app-header">
      <div class="row">
        <div class="header-title col-4">
          <img src="../assets/images/logo.png" alt="" class="header-logo" />
          سامانه لبخند مهدوی
        </div>
        <div class="header-menu col-4">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <router-link class="nav-link" to="/">صفحه اصلی</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/aids">
                نیازمندیها
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/blog">اخبار</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/contactUs"
                >ارتباط با ما</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/aboutUs"
                >درباره ما</router-link
              >
            </li>
            <li class="nav-item" id="help-organization">
              <div class="help-us">
                <router-link class="nav-link" to="/helpUs">
                  <span>حمایت از مؤسسه</span>
                </router-link>
              </div>
            </li>
          </ul>
        </div>

        <div class="header-profile col-4">
          <div class="profile">
            <div class="login" v-if="!IsAuth">
              <span
                @click="loginRegister(0)"
                id="login-header-button"
                class="header-btn"
              >
                ورود
              </span>
              <span
                @click="loginRegister(1)"
                class="header-btn"
                id="register-header-button"
              >
                ثبت نام
              </span>
            </div>
            <div class="profile" v-else>
              <i class="zmdi zmdi-account"></i>
              <router-link
                tag="span"
                :to="this.Profile.UserGroupId == 0 ? '/profile' : '/admin'"
              >
                {{ FullName }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>
    <nav class="header-drawer" v-b-toggle.drawer-menu-responsive>
      <div class="row">
        <div class="header-menu-icon col-6">
          <i class="zmdi zmdi-menu"></i>
        </div>
        <div class="header-title col-6">
          <img src="../assets/images/logo.png" alt="" class="header-logo" />
          سامانه لبخند مهدوی
        </div>
      </div>
    </nav>
    <b-sidebar
      id="drawer-menu-responsive"
      ref="drawer-menu-responsive"
      :backdrop-variant="dark"
      backdrop
      right
      shadow
      no-close-on-esc
      no-header-close
    >
      <div class="profile" id="profile-user-link">
        <i
          id="back-side-btn"
          class="zmdi zmdi-arrow-right"
          @click="$refs['drawer-menu-responsive'].hide()"
        ></i>
        <div class="login" v-if="!IsAuth">
          <i class="zmdi zmdi-account"></i>
          <span @click="loginRegister(0)"> ورود </span>/
          <span @click="loginRegister(1)"> ثبت نام </span>
          <div class="register"></div>
        </div>
        <div class="profile" v-else>
          <i class="zmdi zmdi-account"></i>
          <router-link
            tag="span"
            :to="this.Profile.UserGroupId == 0 ? '/profile' : '/admin'"
          >
            {{ FullName }}
          </router-link>
        </div>
      </div>
      <div class="nav-list">
        <router-link to="/" class="list-item">
          <i class="zmdi zmdi-home"></i>
          صفحه اصلی
        </router-link>
        <router-link
          v-if="IsAuth"
          :to="this.Profile.UserGroupId == 0 ? '/profile' : '/admin'"
          class="list-item"
        >
          <i class="zmdi zmdi-account-circle"></i>
          پروفایل
        </router-link>
        <router-link to="/aids" class="list-item">
          <i class="zmdi zmdi-dropbox"></i>
          نیازمندیها
        </router-link>
        <router-link to="/blog" class="list-item">
          <i class="zmdi zmdi-book"></i>
          اخبار
        </router-link>
        <router-link to="/contactUs" class="list-item">
          <i class="zmdi zmdi-phone"></i>
          ارتباط با ما
        </router-link>
        <router-link to="/aboutUs" class="list-item">
          <i class="zmdi zmdi-view-headline"></i>
          درباره ما
        </router-link>
        <router-link to="/helpus" class="list-item">
          <i class="zmdi zmdi-card-giftcard"></i>
          حمایت از موسسه
        </router-link>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dark: "dark",
    };
  },
  computed: {
    IsAuth() {
      return this.$store.getters.GetIsAuthenticated;
    },
    FullName() {
      return this.$store.getters.GetFullName;
    },
    Profile() {
      return this.$store.getters.GetProfile;
    },
  },
  methods: {
    LogOut() {
      this.$store.dispatch("LogOut");
    },
    loginRegister(number) {
      this.$store.dispatch("goToLoginRegister", number);
    },
  },
  created() {
    this.$store.dispatch("GetProfile");
  },
};
</script>
