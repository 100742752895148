<template>
  <div>
    <h3 class="mt-3">افزودن نیازمند جدید</h3>
    <select
      class="form-select mb-3"
      v-model="AidTypeSelected"
      @change="AidID = AidTypeSelected"
    >
      <option value="" selected>لطفا بسته ی حمایتی را انتخاب کنید...</option>
      <option v-for="aid in Aids" :key="aid.AidID" :value="aid.AidID">
        {{ aid.AidName }}
      </option>
    </select>
    <input
      type="number"
      class="form-control mb-3"
      placeholder="مبلغ"
      v-model="CostMoney"
    />
    <date-picker v-model="HelpDate"></date-picker>
    <button class="btn btn-success m-1 float-end" @click="AddHelpRecord()">
      افزودن حمایت جدید
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      AidTypeSelected: "",
      AidID: 0,
      CostMoney: "",
      HelpDate: "",
    };
  },
  methods: {
    AddHelpRecord() {
      const helpRecordData = {
        NeedyId: this.$route.params.id,
        AidId: this.AidID,
        CostMoney: this.CostMoney,
        HelpDate: this.HelpDate,
      };
      this.$store.dispatch("AddHelpRecord", helpRecordData);
    },
    cancel() {
      this.$router.push("/admin/helpRecord/" + this.$route.params.id);
    },
  },
  computed: {
    Aids() {
      return this.$store.getters.GetAllAids.aids;
    },
    Profile() {
      return this.$store.getters.GetProfile;
    },
  },
  created() {
    this.$store.dispatch("GetAllAidsResponse");
    this.$store.dispatch("GetProfile");
    this.$store.dispatch("AuthHelpRecord", true);
  },
  updated() {
    this.$store.dispatch("AuthHelpRecord", true);
  },
};
</script>
