<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">تعداد بازدید امروز</h4>

          <div class="widget-chart-1">
            <div class="widget-chart-box-1">
              <vue-knob-control
                :min="0"
                :max="100"
                primary-color="#29b1ff"
                secondary-color="#b8e5ff"
                text-color="#29b1ff"
                :value="todayVisit"
              ></vue-knob-control>
            </div>

            <div class="widget-detail-1">
              <h2 class="p-t-10 m-b-0">{{ todayVisit }}</h2>
              <p class="text-muted">تعداد</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">تعداد بازدید دیروز</h4>

          <div class="widget-chart-1">
            <div class="widget-chart-box-1">
              <vue-knob-control
                :min="0"
                :max="100"
                primary-color="#29b1ff"
                secondary-color="#b8e5ff"
                text-color="#29b1ff"
                :value="yesterdayVisits"
              ></vue-knob-control>
            </div>

            <div class="widget-detail-1">
              <h2 class="p-t-10 m-b-0">{{ yesterdayVisits }}</h2>
              <p class="text-muted">تعداد</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">تعداد بازدید ماه</h4>

          <div class="widget-chart-1">
            <div class="widget-chart-box-1">
              <vue-knob-control
                :min="0"
                :max="100"
                primary-color="#29b1ff"
                secondary-color="#b8e5ff"
                text-color="#29b1ff"
                :value="monthVisits"
              ></vue-knob-control>
            </div>

            <div class="widget-detail-1">
              <h2 class="p-t-10 m-b-0">{{ monthVisits }}</h2>
              <p class="text-muted">تعداد</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="card-box">
          <h4 class="header-title m-t-0 m-b-30">تعداد بازدید سال</h4>

          <div class="widget-chart-1">
            <div class="widget-chart-box-1">
              <vue-knob-control
                :min="0"
                :max="100"
                primary-color="#29b1ff"
                secondary-color="#b8e5ff"
                text-color="#29b1ff"
                :value="yearVisits"
              ></vue-knob-control>
            </div>

            <div class="widget-detail-1">
              <h2 class="p-t-10 m-b-0">{{ yearVisits }}</h2>
              <p class="text-muted">تعداد</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 card-online-users">
        <p>افراد آنلاین</p>
        <div class="row">
          <h4 class="col-6" ref="onlineUsers">{{ onlineUsers }}</h4>
          <i class="col-6 zmdi zmdi-account"></i>
        </div>
        <p class="text-left">تعداد</p>
      </div>
    </div>
  </div>
</template>

<script>
import VueKnobControl from "vue-knob-control";
export default {
  data() {
    return {
      socket: null,
    };
  },
  components: {
    VueKnobControl,
  },
  computed: {
    todayVisit() {
      return this.$store.getters.Visit.todayVisit;
    },
    yesterdayVisits() {
      return this.$store.getters.Visit.yesterdayVisits;
    },
    monthVisits() {
      return this.$store.getters.Visit.monthVisits;
    },
    yearVisits() {
      return this.$store.getters.Visit.yearVisits;
    },
    onlineUsers() {
      return this.$store.getters.OnlineUsers;
    },
  },
  created() {
    this.$store.dispatch("AuthDashboard", true);
    this.$store.dispatch("Dashboard");
  },
  mounted() {
    this.$store.dispatch("Dashboard");
  },
  updated() {
    this.$store.dispatch("AuthDashboard", true);
  },
};
</script>
