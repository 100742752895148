import Vue from "vue";

const state = {
  aids: [],
  aidDetail: {},
  // aidImageLink: "http://localhost:3000/aids/",
  aidImageLink: "https://api.labkhandmahdavi.ir/aids/",
};

const getters = {
  GetAids(state) {
    return state.aids;
  },
  GetAidDetails(state) {
    return state.aidDetail;
  },
  GetAidImageLink(state) {
    return state.aidImageLink;
  },
};

const mutations = {
  SetAidDetial(state, aidDetail) {
    state.aidDetail = aidDetail;
  },
  SetAids(state, aids) {
    state.aids = aids;
  },
};

const actions = {
  GetAllAids(context, pageInfo) {
    Vue.http
      .get(
        `users/aids?page=${pageInfo.page}&&search=${
          pageInfo.search ? pageInfo.search : ""
        }`
      )
      .then((response) => {
        context.commit("SetAids", response.body);
      });
  },
  GetAidDetail(context, id) {
    Vue.http.get("users/aids/aidDetail/" + id).then((response) => {
      context.commit("SetAidDetial", response.body);
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
