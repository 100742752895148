import Vue from "vue";

const state = {
  maritalTypes: [],
};

const getters = {
  getAllMaritalTypes(state) {
    return state.maritalTypes;
  },
};

const mutations = {
  setMaritalTypes(state, maritalTypes) {
    state.maritalTypes = maritalTypes;
  },
};

const actions = {
  GetMaritalTypesResponse(context) {
    Vue.http
      .get("dashboard/maritalType")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setMaritalTypes", data);
      })
      .catch((err) => console.log(err));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
