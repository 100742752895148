import Aids from "../views/aids/Aids.vue";
import AidDetail from "../views/aidDetails/AidDetail.vue";

export const aidSearchRoute = {
  path: "/aids",
  component: Aids,
};

export const aidDetailRoute = {
  path: "/aid/aidDetail/:id",
  component: AidDetail,
};
