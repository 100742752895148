import Vue from "vue";

import { router } from "../../router/vueRouter";

const state = {
  userGroups: [],
  GotGroup: {},
};

const getters = {
  getAllUserGroups(state) {
    return state.userGroups;
  },
  GetGroup(state) {
    return state.GotGroup;
  },
};

const mutations = {
  setUserGroups(state, userGroups) {
    state.userGroups = userGroups;
  },
  setGotGroup(state, GotGroup) {
    state.GotGroup = GotGroup;
  },
};

const actions = {
  GetUserGroupById(context, id) {
    Vue.http
      .get("dashboard/userGroups/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setGotGroup", data);
      })
      .catch((err) => console.log(err));
  },
  AddUserGroup(context, userGroupData) {
    Vue.http.post("dashboard/userGroups", userGroupData).then((response) => {
      if (response == "INVALID_USER_GROUP") {
        alert("مشخصات وارد شده کامل نمیباشد...");
      } else {
        router.push("/admin/usergroup");
      }
    });
  },
  EditUserGroup(context, userGroupData) {
    Vue.http
      .put("dashboard/userGroups", userGroupData)
      .then((response) => {
        if (response.data == "INVALID_USER_GROUP") {
          alert("مشخصات وارد شده کامل نمیباشد...");
        } else {
          router.push("/admin/usergroup");
        }
      })
      .catch((err) => console.log(err));
  },
  DeleteUserGroup(context, deleteGroupData) {
    if (confirm(`آیا از حذف گروه ${deleteGroupData.groupName} مطمئن هستید؟`)) {
      Vue.http
        .delete("dashboard/userGroups/" + deleteGroupData.id)
        .then((response) => {
          if (response.status == 200) {
            context.dispatch("GetAllUserGroupsResponse");
          }
        })
        .catch((err) => console.log(err));
    }
  },
  GetAllUserGroupsResponse(context) {
    Vue.http
      .get("dashboard/userGroups")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setUserGroups", data);
      })
      .catch((err) => console.log(err));
  },
  addSetRoles(context, lists) {
    Vue.http.post("dashboard/roleGroup", {
      checkedRoles: lists.checkedRoles,
      UserGroupId: lists.UserGroupId,
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
