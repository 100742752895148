const state = {
  isSetMenuOpen: false,
  isNeedyMenuOpen: false,
  isAidMenuOpen: false,
  isBlogMenuOpen: false,
  isManagePageOpen: false,
};

const getters = {
  GetSetMenuOpen(state) {
    return state.isSetMenuOpen;
  },
  GetBlogMenuOpen(state) {
    return state.isBlogMenuOpen;
  },
  GetNeedyMenuOpen(state) {
    return state.isNeedyMenuOpen;
  },
  GetAidMenuOpen(state) {
    return state.isAidMenuOpen;
  },
  GetManagePageMenuOpen(state) {
    return state.isManagePageOpen;
  },
};

const mutations = {
  SetSetMenuOpen(state, isSetMenuOpen) {
    state.isSetMenuOpen = isSetMenuOpen;
  },
  SetBlogMenuOpen(state, isBlogMenuOpen) {
    state.isBlogMenuOpen = isBlogMenuOpen;
  },
  SetNeedyMenuOpen(state, isNeedyMenuOpen) {
    state.isNeedyMenuOpen = isNeedyMenuOpen;
  },
  SetAidMenuOpen(state, isAidMenuOpen) {
    state.isAidMenuOpen = isAidMenuOpen;
  },
  SetManagePageMenuOpen(state, isManagePageOpen) {
    state.isManagePageOpen = isManagePageOpen;
  },
};

const actions = {
  OpenCloseSetMenu(context) {
    if (context.getters.GetSetMenuOpen == true) {
      context.commit("SetSetMenuOpen", false);
    } else {
      context.commit("SetSetMenuOpen", true);
      context.commit("SetBlogMenuOpen", false);
      context.commit("SetNeedyMenuOpen", false);
      context.commit("SetAidMenuOpen", false);
      context.commit("SetManagePageMenuOpen", false);
    }
  },
  OpenCloseBlogMenu(context) {
    if (context.getters.GetBlogMenuOpen == true) {
      context.commit("SetBlogMenuOpen", false);
    } else {
      context.commit("SetBlogMenuOpen", true);
      context.commit("SetSetMenuOpen", false);
      context.commit("SetAidMenuOpen", false);
      context.commit("SetNeedyMenuOpen", false);
      context.commit("SetManagePageMenuOpen", false);
    }
  },
  OpenCloseNeedyMenu(context) {
    if (context.getters.GetNeedyMenuOpen == true) {
      context.commit("SetNeedyMenuOpen", false);
    } else {
      context.commit("SetNeedyMenuOpen", true);
      context.commit("SetSetMenuOpen", false);
      context.commit("SetAidMenuOpen", false);
      context.commit("SetBlogMenuOpen", false);
      context.commit("SetManagePageMenuOpen", false);
    }
  },
  OpenCloseAidMenu(context) {
    if (context.getters.GetAidMenuOpen == true) {
      context.commit("SetAidMenuOpen", false);
    } else {
      context.commit("SetAidMenuOpen", true);
      context.commit("SetSetMenuOpen", false);
      context.commit("SetBlogMenuOpen", false);
      context.commit("SetNeedyMenuOpen", false);
      context.commit("SetManagePageMenuOpen", false);
    }
  },
  OpenCloseManagePagesMenu(context) {
    if (context.getters.GetManagePageMenuOpen == true) {
      context.commit("SetManagePageMenuOpen", false);
    } else {
      context.commit("SetManagePageMenuOpen", true);
      context.commit("SetAidMenuOpen", false);
      context.commit("SetSetMenuOpen", false);
      context.commit("SetBlogMenuOpen", false);
      context.commit("SetNeedyMenuOpen", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
