import Vue from "vue";

const state = {
  visit: {},
  onlineUsers: 0,
};

const getters = {
  Visit(state) {
    return state.visit;
  },
  OnlineUsers(state) {
    return state.onlineUsers;
  },
};

const mutations = {
  setVisitInfo(state, visit) {
    state.visit = visit;
  },
  setOnlineUsers(state, onlineUsers) {
    state.onlineUsers = onlineUsers;
  },
};

const actions = {
  Dashboard(context) {
    Vue.http
      .get("dashboard")
      .then((response) => {
        context.commit("setVisitInfo", {
          todayVisit: response.body.todayVisits,
          yesterdayVisits: response.body.yesterdayVisits,
          monthVisits: response.body.monthVisits,
          yearVisits: response.body.yearVisits,
        });
        context.getters.Socket.on("onlineUsers", (data) => {
          console.log(data);
          context.commit("setOnlineUsers", data);
        });
      })
      .catch((err) => {
        if (err.status == 500) {
          //ERROR CODE: 500
        }
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
