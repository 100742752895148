<template>
  <div>
    <button
      to="/admin/addPostCategory"
      class="btn btn-success"
      @click="showAddModal = true"
    >
      افزودن گروه جدید
    </button>
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>عنوان گروه</th>
        <th>زیر گروه</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr
          v-for="pc in PostCategories.filter((c) => c.Parent == null)"
          :key="pc.CategoryID"
        >
          <td>{{ pc.CategoryTitle }}</td>
          <td>
            <ul>
              <li
                v-for="spc in PostCategories.filter(
                  (c) => c.Parent == pc.CategoryID
                )"
                :key="spc.CategoryID"
              >
                {{ spc.CategoryTitle }}
                <i
                  class="zmdi zmdi-edit m-1 text-warning"
                  @click="ShowGetEditModal(spc.CategoryID)"
                ></i>
              </li>
            </ul>
          </td>
          <td>
            <i
              class="zmdi zmdi-collection-plus m-1 text-primary"
              @click="
                showAddModal = true;
                Parent = pc.CategoryID;
              "
            ></i>
            <i
              class="zmdi zmdi-edit m-1 text-warning"
              @click="ShowGetEditModal(pc.CategoryID)"
            ></i>
          </td>
        </tr>
      </template>
    </data-table>
    <modal :showModal="showAddModal" title="افزودن گروه خبر">
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          v-model="CategoryTitle"
          placeholder="عنوان گروه"
        />
        <button
          class="btn btn-success m-1 float-end"
          @click="AddPostCategory()"
        >
          افزودن گروه
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showAddModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
    <modal :showModal="showEditModal" title="ویرایش گروه خبر">
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          v-model="EditCategotyTitle"
          placeholder="عنوان گروه"
        />
        <button class="btn btn-warning m-1 float-end" @click="EditCategory()">
          ویرایش گروه
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showEditModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
import Modal from "../../../Modal/Modal.vue";
export default {
  data() {
    return {
      showAddModal: false,
      showEditModal: false,
      CategoryID: 0,
      CategoryTitle: "",
      Parent: null,
    };
  },
  components: { DataTable, Modal },
  methods: {
    AddPostCategory() {
      const CategoryDate = {
        CategoryTitle: this.CategoryTitle,
        Parent: this.Parent,
      };
      this.$store.dispatch("AddPostCategory", CategoryDate);
      this.showAddModal = false;
      this.CategoryTitle = "";
      this.Parent = null;
    },
    ShowGetEditModal(id) {
      this.showEditModal = true;
      this.$store.dispatch("GetPostCategoryById", id);
      this.CategoryID = id;
    },
    EditCategory() {
      const CategoryDate = {
        CategoryID: this.CategoryID,
        CategoryTitle: this.EditCategotyTitle,
      };
      this.$store.dispatch("EditPostCategory", CategoryDate);
      this.showEditModal = false;
      this.EditCategotyTitle = "";
      this.Parent = null;
    },
  },
  computed: {
    PostCategories() {
      return this.$store.getters.GetAllPostCategories.postCategories;
    },
    EditPostCategory() {
      return this.$store.getters.GetPostCategory;
    },
    EditCategotyTitle: {
      get() {
        return this.EditPostCategory.CategoryTitle;
      },
      set(value) {
        this.EditPostCategory.CategoryTitle = value;
      },
    },
    EditCategotyParent: {
      get() {
        return this.EditPostCategory.Parent;
      },
      set(value) {
        this.EditPostCategory.Parent = value;
      },
    },
  },
  created() {
    this.$store.dispatch("GetAllPostCategories");
    this.$store.dispatch("AuthPostCategory", true);
    this.$store.dispatch("OpenCloseBlogMenu");
  },
  updated() {
    this.$store.dispatch("AuthPostCategory", true);
  },
};
</script>
