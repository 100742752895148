import Vue from "vue";

const state = {
  weakTypes: [],
};

const getters = {
  getAllWeakTypes(state) {
    return state.weakTypes;
  },
};

const mutations = {
  setWeakTypes(state, weakTypes) {
    state.weakTypes = weakTypes;
  },
};

const actions = {
  GetWeakTypesResponse(context) {
    Vue.http
      .get("dashboard/weakType")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setWeakTypes", data);
      })
      .catch((err) => console.log(err));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
