const state = {
    splashID: 1
};

const getters = {
    getSplashID(state) {
        return state.splashID;
    }
};

const mutations = {
    setSplashID(state,splashID) {
        state.splashID = splashID;
    }
}; 

export default {
    state ,
    getters ,
    mutations ,
}