import Vue from "vue";

const state = {
  roleGroups: [],
  roleUserGroupID: 0,
};

const getters = {
  getAllRoleGroups(state) {
    return state.roleGroups;
  },
  getUserGroupID(state) {
    return state.roleUserGroupID;
  },
};

const mutations = {
  setRoleGroups(state, roleGroups) {
    state.roleGroups = roleGroups;
  },
  setUserGroupID(state, roleUserGroupID) {
    state.roleUserGroupID = roleUserGroupID;
  },
};

const actions = {
  async getAllRoleGroupsResponse(context, id) {
    return new Promise((resolve) => {
      Vue.http
        .get("dashboard/roleGroup/" + id)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => console.log(err));
    });
  },
  getUserGroupIDResponse(context) {
    Vue.http
      .get("UserGroupAdmin")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setUserGroupID", data.UserGroupID);
      })
      .catch((err) => console.log(err));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
