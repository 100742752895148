import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import publics from "./modules/public";
import splash from "./modules/splash";
import authModal from "./modules/authModal";
import dashboard from "./modules/dashboard";
import userAdmin from "./modules/userAdmin";
import userGroupAdmin from "./modules/userGroupAdmin";
import needyAdmin from "./modules/needyAdmin";
import needyChildAdmin from "./modules/needyChildAdmin";
import helpRecordAdmin from "./modules/helpRecordAdmin";
import roleAdmin from "./modules/roleAdmin";
import roleGroupAdmin from "./modules/roleGroupAdmin";
import adressTypeAdmin from "./modules/addressTypeAdmin";
import maritalTypeAdmin from "./modules/maritalTypeAdmin";
import phisicalTypeAdmin from "./modules/phisicalTypeAdmin";
import sickTypeAdmin from "./modules/sickTypeAdmin";
import weakTypeAdmin from "./modules/weakTypeAdmin";
import relationShipTypeAdmin from "./modules/relationShipTypeAdmin";
import objectAdmin from "./modules/objectAdmin";
import unitTypeAdmin from "./modules/unitTypeAdmin";
import aidAdmin from "./modules/aidAdmin";
import aidDetailsAdmin from "./modules/aidDetailsAdmin";
import postCategoryAdmin from "./modules/postCategoryAdmin";
import postAdmin from "./modules/postAdmin";
import user from "./modules/user";
import aid from "./modules/aid";
import post from "./modules/post";
import managePagesAdmin from "./modules/managePagesAdmin";
import authorize from "./modules/authorize";

export const store = new Vuex.Store({
  state: {},
  modules: {
    publics,
    splash,
    authModal,
    dashboard,
    userAdmin,
    userGroupAdmin,
    needyAdmin,
    needyChildAdmin,
    helpRecordAdmin,
    roleAdmin,
    roleGroupAdmin,
    adressTypeAdmin,
    maritalTypeAdmin,
    phisicalTypeAdmin,
    sickTypeAdmin,
    weakTypeAdmin,
    relationShipTypeAdmin,
    objectAdmin,
    unitTypeAdmin,
    aidAdmin,
    aidDetailsAdmin,
    postCategoryAdmin,
    postAdmin,
    user,
    aid,
    post,
    managePagesAdmin,
    authorize,
  },
});
