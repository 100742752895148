const state = {
  showModal: false,
};

const getters = {
  GetShowModal(state) {
    return state.showModal;
  },
};

const mutations = {
  setShowModal(state, showModal) {
    state.showModal = showModal;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
