<template>
  <div>
    <h3>ثبت دسترسی</h3>
    <div class="roles">
      <div
        class="role"
        v-for="role in Roles.filter((role) => role.Parent == null)"
        :key="role.RoleID"
      >
        <div class="form-check">
          <input
            type="checkbox"
            name=""
            id=""
            :value="{
              RoleID: role.RoleID,
              UserGroupID: UserGroupID,
            }"
            class="form-check-input"
            v-model="checkedRoles"
          />
          <label class="form-check-label">{{ role.RoleTitle }}</label>
        </div>
        <div class="row subroles">
          <div
            class="subrole col-6"
            v-for="subRole in Roles.filter(
              (subRole) => subRole.Parent == role.RoleID
            )"
            :key="subRole.RoleID"
          >
            <div class="form-check">
              <input
                type="checkbox"
                name=""
                id=""
                class="form-check-input"
                :value="{
                  RoleID: subRole.RoleID,
                  UserGroupID: UserGroupID,
                }"
                v-model="checkedRoles"
              />
              <label class="form-check-label">{{ subRole.RoleTitle }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-success float-end" @click="addSetRoles()">
      ثبت
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkedRoles: [],
    };
  },
  methods: {
    setRoles() {
      this.RoleGroups.filter(
        (rg) => rg.UserGroupID == this.$route.params.id
      ).forEach((roleGroup) => {
        this.checkedRoles.push({
          UserGroupID: this.$route.params.id,
          RoleID: roleGroup.RoleID,
        });
      });
    },
    addSetRoles() {
      const roleGroups = this.RoleGroups.filter(
        (roleGroup) => roleGroup.UserGroupID == this.$route.params.id
      );
      this.$store.dispatch("addSetRoles", {
        roleGroups: roleGroups,
        checkedRoles: this.checkedRoles,
      });
    },
  },
  computed: {
    Roles() {
      return this.$store.getters.getAllRoles.roles;
    },
    RoleGroups() {
      return this.$store.getters.getAllRoleGroups.roleGroups;
    },
  },
  updated() {
    this.$store.dispatch("setRolesResponse");
    this.$store.dispatch("getAllRoleGroupsResponse");
    this.setRoles();
  },
};
</script>
