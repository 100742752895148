import Vue from "vue";

const state = {
  roles: [],
};

const getters = {
  getAllRoles(state) {
    return state.roles;
  },
};

const mutations = {
  setRoles(state, roles) {
    state.roles = roles;
  },
};

const actions = {
  setRolesResponse(context) {
    Vue.http
      .get("dashboard/roles")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setRoles", data);
      })
      .catch((err) => console.log(err));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
