import Vue from "vue";

const state = {
  relationShipTypes: [],
};

const getters = {
  getAllRelationTypes(state) {
    return state.relationShipTypes;
  },
};

const mutations = {
  setRelationShipTypes(state, relationShipTypes) {
    state.relationShipTypes = relationShipTypes;
  },
};

const actions = {
  GetRelationShipTypesResponse(context) {
    Vue.http
      .get("dashboard/relationShipType")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        context.commit("setRelationShipTypes", data);
      })
      .catch((err) => console.log(err));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
