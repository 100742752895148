import Vue from "vue";

import { router } from "../../router/vueRouter";

const state = {
  needies: [],
  gotNeedy: {},
};

const getters = {
  GetAllNeedies(state) {
    return state.needies;
  },
  GetNeedy(state) {
    return state.gotNeedy;
  },
};

const mutations = {
  setNeedies(state, needies) {
    state.needies = needies;
  },
  setNeedy(state, gotNeedy) {
    state.gotNeedy = gotNeedy;
  },
};

const actions = {
  GetAllNeediesResponse(context) {
    Vue.http
      .get("dashboard/needy")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setNeedies", data);
      })
      .catch((err) => {
        if (err.status == 401) {
          context.commit("setShowModal", true);
        }
      });
  },
  GetNeedyById(context, id) {
    Vue.http
      .get("dashboard/needy/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setNeedy", data);
      });
  },
  AddNeedy(context, needyData) {
    Vue.http
      .post("dashboard/needy", needyData)
      .then(() => {
        router.push("/admin/needy");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  EditNeedy(context, needyData) {
    Vue.http
      .put("dashboard/needy", needyData)
      .then(() => {
        router.push("/admin/needy");
        context.dispatch("GetAllNeediesResponse");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
