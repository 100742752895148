<template>
  <div>
    <router-link to="/admin/addobject" class="btn btn-success m-1"
      >افزودن کالای حمایتی جدید</router-link
    >
    <input
      type="search"
      class="form-control mb-3 mt-1"
      placeholder="جست و جو..."
      v-model="objSearchText"
    />
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>عنوان کالای حمایتی</th>
        <th>واحد</th>
        <th>مبلغ</th>
        <th>فرد ثبت کننده</th>
        <th>تاریخ ثبت</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr v-for="obj in Objects" :key="obj.ObjectID">
          <td>{{ obj.ObjectName }}</td>
          <td>{{ obj.UnitTypeName }}</td>
          <td>{{ obj.CostMoney }}</td>
          <td>{{ obj.FullName }}</td>
          <td>{{ obj.createdAt | moment("jYYYY/jMM/jDD") }}</td>
          <td>
            <router-link :to="'/admin/editobject/' + obj.ObjectID">
              <i class="zmdi zmdi-edit m-1 text-warning"></i>
            </router-link>
            <i
              class="zmdi zmdi-delete m-1 text-danger"
              @click="deleteObject(obj.ObjectID, obj.ObjectName)"
            ></i>
          </td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
export default {
  data() {
    return {
      objSearchText: "",
    };
  },
  components: { DataTable },
  methods: {
    deleteObject(id, name) {
      this.$store.dispatch("DeleteObject", {
        id,
        name,
      });
    },
  },
  computed: {
    Objects() {
      if (this.objSearchText) {
        return this.$store.getters.getAllObjects.objects.filter((obj) => {
          return this.objSearchText
            .toLowerCase()
            .split(" ")
            .every((v) => obj.ObjectName.toLowerCase().includes(v));
        });
      } else {
        return this.$store.getters.getAllObjects.objects;
      }
    },
  },
  created() {
    this.$store.dispatch("GetObjectsResponse");
    this.$store.dispatch("AuthObject", true);
    this.$store.dispatch("OpenCloseAidMenu");
  },
  updated() {
    this.$store.dispatch("AuthObject", true);
  },
};
</script>
