<template>
  <div>
    <h3 class="mt-3">افزودن فرد تحت سرپرستی جدید</h3>
    <input
      type="text"
      class="form-control mb-3"
      placeholder="نام"
      v-model="ChildName"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="نام خانوادگی"
      v-model="ChildFamily"
    />
    <select
      class="form-select mb-3"
      v-model="RelationShipSelected"
      @change="RelationShipTypeID = RelationShipSelected"
    >
      <option value="" selected>لطفا نسبت خانوادگی را انتخاب کنید...</option>
      <option
        v-for="relationShip in RelationShipTypes"
        :key="relationShip.RelationShipTypeId"
        :value="relationShip.RelationShipTypeId"
      >
        {{ relationShip.RelationShipTypeName }}
      </option>
    </select>
    <input
      type="text"
      class="form-control mb-3"
      placeholder="کد ملی"
      v-model="ChildNCode"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="شماره تلفن"
      v-model="Tel"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="شماره موبایل"
      v-model="Mobile"
    />
    <input
      type="text"
      class="form-control mb-3"
      placeholder="آدرس"
      v-model="Address"
    />
    <button class="btn btn-success m-1 float-end" @click="AddNeedyChild()">
      افزودن فرد جدید
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      RelationShipSelected: "",
      ChildName: "",
      ChildFamily: "",
      RelationShipTypeID: 0,
      ChildNCode: "",
      Tel: "",
      Mobile: "",
      Address: "",
    };
  },
  methods: {
    cancel() {
      this.$router.push("/admin/needychild/" + this.$route.params.id);
    },
    AddNeedyChild() {
      const needyChildData = {
        data: {
          ChildName: this.ChildName,
          ChildFamily: this.ChildFamily,
          RelationShipTypeID: this.RelationShipTypeID,
          NeedyId: this.$route.params.id,
          ChildNCode: this.ChildNCode,
          Tel: this.Tel,
          Mobile: this.Mobile,
          Address: this.Address,
        },
        id: this.$route.params.id,
      };
      this.$store.dispatch("AddNeedyChild", needyChildData);
    },
  },
  computed: {
    RelationShipTypes() {
      return this.$store.getters.getAllRelationTypes.relationShipTypes;
    },
    Profile() {
      return this.$store.getters.GetProfile;
    },
  },
  created() {
    this.$store.dispatch("GetRelationShipTypesResponse");
    this.$store.dispatch("GetProfile");
    this.$store.dispatch("AuthNeedyChild", true);
  },
  updated() {
    this.$store.dispatch("AuthNeedyChild", true);
  },
};
</script>
