<template>
  <div>
    <h3 class="mt-3">جزئیات بسته ی حمایتی</h3>
    <router-link
      :to="'/admin/addaiddetail/' + $route.params.id"
      class="btn btn-success m-1"
      >افزودن جزء جدید</router-link
    >
    <router-link to="/admin/aid" class="btn btn-secondary m-1"
      >بازگشت</router-link
    >
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>بسته حمایتی</th>
        <th>کالا حمایتی</th>
        <th>تعداد</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr v-for="detail in AidDetails" :key="detail.AidDetailID">
          <td>{{ detail.AidName }}</td>
          <td>{{ detail.ObjectName }}</td>
          <td>{{ detail.Num }}</td>
          <td>
            <router-link :to="`/admin/editaiddetail/${detail.AidDetailId}`">
              <i class="zmdi zmdi-edit m-1 text-warning"></i>
            </router-link>
            <i
              class="zmdi zmdi-delete m-1 text-danger"
              @click="deleteAidDetail(detail.AidDetailId)"
            ></i>
          </td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
export default {
  components: { DataTable },
  methods: {
    deleteAidDetail(id) {
      this.$store.dispatch("DeleteAidDetail", {
        id,
        aid: this.$route.params.id,
      });
    },
  },
  computed: {
    AidDetails() {
      return this.$store.getters.GetAllAidDetails.aidDetails;
    },
  },
  created() {
    this.$store.dispatch("GetAllAidDetailsResponse", this.$route.params.id);
    this.$store.dispatch("AuthAidDetail", true);
  },
  updated() {
    this.$store.dispatch("AuthAidDetail", true);
  },
};
</script>
