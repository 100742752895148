import Vue from "vue";

import { router } from "../../router/vueRouter";

const state = {
  aidDetails: [],
  gotAidDetail: {},
};

const getters = {
  GetAllAidDetails(state) {
    return state.aidDetails;
  },
  GetAidDetail(state) {
    return state.gotAidDetail;
  },
};

const mutations = {
  setAidDetails(state, aidDetails) {
    state.aidDetails = aidDetails;
  },
  setAidDetail(state, gotAidDetail) {
    state.gotAidDetail = gotAidDetail;
  },
};

const actions = {
  GetAllAidDetailsResponse(context, id) {
    Vue.http
      .get("dashboard/aidDetail/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setAidDetails", data);
      })
      .catch((err) => console.log(err));
  },
  GetAidDetailById(context, id) {
    Vue.http
      .get("dashboard/aidDetail/edit/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setAidDetail", data);
      })
      .catch((err) => console.log(err));
  },
  AddAidDetail(context, detailData) {
    Vue.http
      .post("dashboard/aidDetail", detailData.data)
      .then(() => {
        router.push("/admin/aiddetail/" + detailData.aid);
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  EditAidDetail(context, aidData) {
    Vue.http
      .put("dashboard/aidDetail", aidData)
      .then(() => {
        router.push("/admin/aiddetail/" + aidData.AidId);
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  DeleteAidDetail(context, deleteDetailData) {
    if (confirm("آیا از حذف این جزء مطمئن هستید؟")) {
      Vue.http
        .delete("dashboard/aidDetail/" + deleteDetailData.id)
        .then(() => {
          context.dispatch("GetAllAidDetailsResponse", deleteDetailData.aid);
        })
        .catch((err) => {
          if (err.status == 422) {
            alert(err.body.message);
          }
        });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
