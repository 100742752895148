import Vue from "vue";

const state = {
  postCategories: [],
  postCategory: {},
};

const getters = {
  GetAllPostCategories(state) {
    return state.postCategories;
  },
  GetPostCategory(state) {
    return state.postCategory;
  },
};

const mutations = {
  SetPostCategories(state, postCategories) {
    state.postCategories = postCategories;
  },
  SetPostCategory(state, postCategory) {
    state.postCategory = postCategory;
  },
};

const actions = {
  GetAllPostCategories(context) {
    Vue.http
      .get("dashboard/postCategory")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("SetPostCategories", data);
      });
  },
  GetPostCategoryById(context, id) {
    Vue.http
      .get("dashboard/postCategory/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("SetPostCategory", data);
      });
  },
  AddPostCategory(context, categoryData) {
    Vue.http
      .post("dashboard/postCategory", categoryData)
      .then(() => {
        context.dispatch("GetAllPostCategories");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  EditPostCategory(context, categoryData) {
    Vue.http
      .put("dashboard/postCategory", categoryData)
      .then(() => {
        context.dispatch("GetAllPostCategories");
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
