var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("ثبت دسترسی")]),_c('div',{staticClass:"roles"},_vm._l((_vm.Roles.filter(function (role) { return role.Parent == null; })),function(role){return _c('div',{key:role.RoleID,staticClass:"role"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedRoles),expression:"checkedRoles"}],staticClass:"form-check-input",attrs:{"type":"checkbox","name":"","id":""},domProps:{"value":{
            RoleID: role.RoleID,
            UserGroupID: _vm.UserGroupID,
          },"checked":Array.isArray(_vm.checkedRoles)?_vm._i(_vm.checkedRoles,{
            RoleID: role.RoleID,
            UserGroupID: _vm.UserGroupID,
          })>-1:(_vm.checkedRoles)},on:{"change":function($event){var $$a=_vm.checkedRoles,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v={
            RoleID: role.RoleID,
            UserGroupID: _vm.UserGroupID,
          },$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedRoles=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedRoles=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedRoles=$$c}}}}),_c('label',{staticClass:"form-check-label"},[_vm._v(_vm._s(role.RoleTitle))])]),_c('div',{staticClass:"row subroles"},_vm._l((_vm.Roles.filter(
            function (subRole) { return subRole.Parent == role.RoleID; }
          )),function(subRole){return _c('div',{key:subRole.RoleID,staticClass:"subrole col-6"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedRoles),expression:"checkedRoles"}],staticClass:"form-check-input",attrs:{"type":"checkbox","name":"","id":""},domProps:{"value":{
                RoleID: subRole.RoleID,
                UserGroupID: _vm.UserGroupID,
              },"checked":Array.isArray(_vm.checkedRoles)?_vm._i(_vm.checkedRoles,{
                RoleID: subRole.RoleID,
                UserGroupID: _vm.UserGroupID,
              })>-1:(_vm.checkedRoles)},on:{"change":function($event){var $$a=_vm.checkedRoles,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v={
                RoleID: subRole.RoleID,
                UserGroupID: _vm.UserGroupID,
              },$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedRoles=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedRoles=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedRoles=$$c}}}}),_c('label',{staticClass:"form-check-label"},[_vm._v(_vm._s(subRole.RoleTitle))])])])}),0)])}),0),_c('button',{staticClass:"btn btn-success float-end",on:{"click":function($event){return _vm.addSetRoles()}}},[_vm._v(" ثبت ")])])}
var staticRenderFns = []

export { render, staticRenderFns }