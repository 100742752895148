import Vue from "vue";

import { router } from "../../router/vueRouter";

const state = {
  needyChilds: [],
  gotChild: {},
};

const getters = {
  GetAllNeedyChilds(state) {
    return state.needyChilds;
  },
  GetNeedyChild(state) {
    return state.gotChild;
  },
};

const mutations = {
  setNeedyChilds(state, needychilds) {
    state.needyChilds = needychilds;
  },
  setNeedyChild(state, gotChild) {
    state.gotChild = gotChild;
  },
};

const actions = {
  GetAllNeedyChilds(context, id) {
    Vue.http
      .get("dashboard/needyChild/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setNeedyChilds", data);
      })
      .catch((err) => console.log(err));
  },
  GetNeedyChildById(context, id) {
    Vue.http
      .get("dashboard/needyChild/edit/" + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        context.commit("setNeedyChild", data);
      });
  },
  AddNeedyChild(context, needyChildData) {
    Vue.http
      .post("dashboard/needyChild", needyChildData.data)
      .then(() => {
        router.push("/admin/needyChild/" + needyChildData.id);
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  EditNeedyChild(context, needyChildData) {
    Vue.http
      .put("dashboard/needyChild", needyChildData.data)
      .then(() => {
        router.push("/admin/needychild/" + needyChildData.nid);
      })
      .catch((err) => {
        if (err.status == 422) {
          alert(err.body.message);
        }
      });
  },
  DeleteNeedyChild(context, deleteParams) {
    if (confirm("آیا از حذف این فرد مطمئن هستید؟")) {
      Vue.http
        .delete("dashboard/needyChild/" + deleteParams.ChildId)
        .then(() => {
          context.dispatch("GetAllNeedyChilds", deleteParams.NeedyId);
        })
        .catch((err) => {
          if (err.status == 422) {
            alert(err.body.message);
          }
        });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
