<template>
  <div class="p-0 m-0">
    <app-header></app-header>
    <main class="container">
      <section class="landing-background">
        <div class="landing-titles">
          <h2>مؤسسه لبخند، کمک رسانی به شکل ساده</h2>
          <h3>نیازهای استان را ببینید و هرچقدر که مایل هستید کمک کنید...</h3>
        </div>
        <router-link to="/aids">جست و جو و مشاهده نیاز ها</router-link>
      </section>

      <section class="counts">
        <div class="counts-box">
          <div class="card-box container">
            <div class="row">
              <div class="col-6">
                <h2>1</h2>
                <h3>تعداد نیازمندان</h3>
              </div>

              <div class="col-6">
                <h2>1</h2>
                <h3>تعداد کمک ها</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="fast-pay">
        <div class="card-box container">
          <img
            src="../../assets/images/fast-pay.svg"
            width="300"
            height="300"
            class="w-100"
            alt=""
          />
          <h3>پرداخت سریع</h3>
          <h4>
            از این قسمت میتوانید مبالغ مورد نظر را پرداخت کنید. ما این مبالغ
            <br />را برای نیاز های مهم اختصاص میدهیم و برای شما گزارش خواهیم
            فرستاد.
          </h4>
          <div class="pay-input">
            <div class="row">
              <div class="pay-btn-number col-1">
                <span @click="increase">+</span>
                <span @click="decrease">-</span>
              </div>
              <div class="col-9">
                <div class="input-pay form-control h-100">
                  <div class="row h-100">
                    <div class="col-11 h-100">
                      <input
                        type="number"
                        id="fast-pay-number"
                        placeholder="0"
                        value="10000"
                        v-model="payNumber"
                        @input="payNumber = payNumber.toLocaleString()"
                      />
                    </div>
                    <div class="col-1 h-100 currency">
                      <span>ریال</span>
                    </div>
                  </div>
                </div>
              </div>
              <button class="col-2">پرداخت</button>
            </div>
          </div>
        </div>
      </section>
    </main>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from "../Header.vue";
import AppFooter from "../Footer.vue";
export default {
  data() {
    return {
      payNumber: 10000,
    };
  },
  components: {
    AppHeader,
    AppFooter,
  },
  computed: {
    Token() {
      return this.$store.getters.GetToken;
    },
  },
  methods: {
    increase() {
      this.payNumber = parseInt(this.payNumber);
      this.payNumber += 10000;
    },
    decrease() {
      this.payNumber = parseInt(this.payNumber);
      this.payNumber -= 10000;
    },
  },
  created() {
    this.$store.dispatch("SetFullName");
  },
};
</script>

<style scoped>
@import url("home.css");
</style>
