import { NotFoundRoute, NotFoundRedirect } from "./404";
import { homeRoute } from "./home";
import { LoginAndRegisterRoute } from "./loginAndRegister";
import { adminRoute } from "./admin";
import { accountRoute, changeInfoRoute, changePasswordRoute } from "./account";
import { aidSearchRoute, aidDetailRoute } from "./aids";
import { blogRoute, blogDetailRoute } from "./blog";
import { aboutUsRoute } from "./about";
import { contactUsRoute } from "./contact";
import { HelpUsRoute } from "./help";

export const routes = [
  NotFoundRoute,
  NotFoundRedirect,
  homeRoute,
  LoginAndRegisterRoute,
  adminRoute,
  accountRoute,
  changeInfoRoute,
  changePasswordRoute,
  aidSearchRoute,
  aidDetailRoute,
  blogRoute,
  blogDetailRoute,
  aboutUsRoute,
  contactUsRoute,
  HelpUsRoute,
];
