<template>
  <div>
    <h3 class="mt-3">افزودن گروه جدید</h3>
    <input
      type="text"
      class="form-control"
      placeholder="نام گروه"
      v-model="UserGroupName"
    />
    <button class="btn btn-success m-1 float-end" @click="addUserGroup()">
      افزودن گروه جدید
    </button>
    <button class="btn btn-secondary m-1 float-end" @click="cancel()">
      انصراف
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      UserGroupName: "",
      CheckedRoles: [],
    };
  },
  methods: {
    addUserGroup() {
      const userGroupData = {
        UserGroupName: this.UserGroupName,
      };
      this.$store.dispatch("AddUserGroup", userGroupData);
    },
    cancel() {
      this.$router.push("/admin/usergroup");
    },
  },
  computed: {
    Roles() {
      return this.$store.getters.getAllRoles;
    },
  },
  created() {
    this.$store.dispatch("setRolesResponse");
    this.$store.dispatch("AuthUserDef", true);
  },
  updated() {
    this.$store.dispatch("setRolesResponse");
    this.$store.dispatch("AuthUserDef", true);
  },
};
</script>
