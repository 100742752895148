<template>
  <div>
    <button class="mt-3 btn btn-success" @click="showAddModal = true">
      افزودن نسبت خانوادگی جدید
    </button>
    <data-table class="mt-2">
      <template v-slot:tbl-thead>
        <th>نام نسبت</th>
        <th></th>
      </template>
      <template v-slot:tbl-tbody>
        <tr
          v-for="relationShipType in RelationShipTypes"
          :key="relationShipType.RelationShipTypeId"
        >
          <td>{{ relationShipType.RelationShipTypeName }}</td>
          <td>
            <i
              class="zmdi zmdi-edit text-warning"
              @click="showGetEditModal(relationShipType.RelationShipTypeId)"
            ></i>
          </td>
        </tr>
      </template>
    </data-table>
    <modal
      id="add-relationshiptype-modal"
      title="افزودن نسبت خانودگی جدید"
      :showModal="showAddModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام نسبت"
          v-model="RelationShipType.RelationShipTypeName"
        />
        <button
          class="btn btn-success m-1 float-end"
          @click="addRelationShipType()"
        >
          افزودن نسبت جدید
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showAddModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
    <modal
      id="edit-phisicaltype-modal"
      title="ویرایش نسبت خانوادگی"
      :showModal="showEditModal"
    >
      <template v-slot:modalBody>
        <input
          type="text"
          class="form-control"
          placeholder="نام نسبت"
          v-model="EditRelationShipType.RelationShipTypeName"
        />
        <button
          class="btn btn-warning m-1 float-end"
          @click="editPhisicalType()"
        >
          ویرایش نسبت
        </button>
        <button
          class="btn btn-secondary m-1 float-end"
          @click="showEditModal = false"
        >
          انصراف
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import DataTable from "../../../DataTable/DataTable.vue";
import Modal from "../../../Modal/Modal.vue";
export default {
  components: { DataTable, Modal },
  data() {
    return {
      showAddModal: false,
      showEditModal: false,
      RelationShipType: {
        RelationShipTypeName: "",
      },
      EditRelationShipType: {
        RelationShipTypeId: 0,
        RelationShipTypeName: "",
      },
    };
  },
  methods: {
    addRelationShipType() {
      this.$http
        .post("dashboard/relationShipType", this.RelationShipType)
        .then(() => {
          this.showAddModal = false;
          this.$store.dispatch("GetRelationShipTypesResponse");
          this.RelationShipType.RelationShipTypeName = "";
        })
        .catch((err) => console.log(err));
    },
    showGetEditModal(id) {
      this.showEditModal = true;
      this.EditRelationShipType.RelationShipTypeId = id;
      this.$http
        .get(
          "dashboard/relationShipType/" +
            this.EditRelationShipType.RelationShipTypeId
        )
        .then((response) => {
          this.EditRelationShipType.RelationShipTypeName =
            response.data.RelationShipTypeName;
        })
        .catch((err) => console.log(err));
    },
    editPhisicalType() {
      this.$http
        .put("dashboard/relationShipType/", this.EditRelationShipType)
        .then(() => {
          this.showEditModal = false;
          this.$store.dispatch("GetRelationShipTypesResponse");
          this.EditRelationShipType.RelationShipTypeName = "";
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    RelationShipTypes() {
      return this.$store.getters.getAllRelationTypes.relationShipTypes;
    },
  },
  created() {
    this.$store.dispatch("GetRelationShipTypesResponse");
    this.$store.dispatch("AuthRelationShipType", true);
    this.$store.dispatch("OpenCloseNeedyMenu");
  },
  updated() {
    this.$store.dispatch("AuthRelationShipType", true);
  },
};
</script>
